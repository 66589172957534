/* eslint-disable arrow-body-style */

import { requestCancelable } from '@/components/common/services/request';
import { UpsellingRoom, UpsellingRoomResponse } from '@/interfaces/search-form';
import { useStore } from '@/components/common/store';
import { ApiDataTypes } from '@/components/common/types';

const url = '/v1/best-offer-for-room-type';
const store = useStore();
const apiData = (override?: any, exclude?: string[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

const isTypeOfferUpsellingRoom = (isOfferUpsellingBoard: UpsellingRoomResponse | []): isOfferUpsellingBoard is UpsellingRoomResponse => {
	return typeof (isOfferUpsellingBoard as any).Status === 'string';
};

export const getOfferUpsellingRoom = async() => {
	const hotelOnly = store.state.searchMask.onlyHotel;
	const exclude = hotelOnly ? ['DepartureAirport', 'DirectFlight'] : [];
	// Removing params which are at the moment specific to the hotellist request
	exclude.push('Regions', 'Cities', 'MaxPrice', 'SortOrder', 'HotelCategory', 'MinMeanRecommendationRate', 'RatingAttributes', 'HotelAttributes', 'Countries',);
	const bodyParams = {
		travelType: hotelOnly ? 'Accommodation' : 'Package',
	};
	const body = apiData(bodyParams, exclude);

	const response = await requestCancelable<UpsellingRoomResponse>({
		url,
		data: body,
	}, 'best-offer-for-room-type');

	let result: UpsellingRoom[] = [];

	if (!response || (isTypeOfferUpsellingRoom(response) && response.Status !== 'OK')) {
		throw new Error('Best Offer For Room Type Response Invalid: ' + JSON.stringify(response));
	} else if (response && isTypeOfferUpsellingRoom(response)) {
		result = response.Offers;
	}
	return result;
};
