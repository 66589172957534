/* eslint-disable arrow-body-style */
import { requestCancelable } from '@/components/common/services/request';
import { UpsellingBoard, UpsellingBoardResponse } from '@/interfaces/search-form';
import { useStore } from '@/components/common/store';
import { ApiDataTypes } from '@/components/common/types';

const url = '/v1/best-offer-for-board-type';
const store = useStore();
const apiData = (override?: any, exclude?: string[]): ApiDataTypes => store.getters['searchMask/apiData'](override, exclude);

const isTypeOfferUpsellingBoard = (isOfferUpsellingBoard: UpsellingBoardResponse | []): isOfferUpsellingBoard is UpsellingBoardResponse => {
	return typeof (isOfferUpsellingBoard as any).Status === 'string';
};

export const getOfferUpsellingBoard: () => Promise<UpsellingBoard[]> = async() => {
	const hotelOnly = store.state.searchMask.onlyHotel;
	const exclude = hotelOnly ? ['DepartureAirport', 'DirectFlight'] : [];
	// Removing params which are at the moment specific to the hotellist request
	exclude.push('Regions', 'Cities', 'MaxPrice', 'SortOrder', 'HotelCategory', 'MinMeanRecommendationRate', 'RatingAttributes', 'HotelAttributes', 'Countries',);
	const bodyParams = {
		travelType: hotelOnly ? 'Accommodation' : 'Package',
	};
	const body = apiData(bodyParams, exclude);
	const response = await requestCancelable<UpsellingBoardResponse | []>({
		url,
		data: body,
	}, 'best-offer-for-board-type');

	let result: UpsellingBoard[] = [];

	if (response && isTypeOfferUpsellingBoard(response) && response.Status === 'OK') {
		result = response.Offers;
	}
	return result;
};
