<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
	>
		<path
			id="phone"
			d="M20,18.081a4.68,4.68,0,0,1-.142,1,5.671,5.671,0,0,1-.3.973,3.808,3.808,0,0,1-1.733,1.506,5.507,5.507,0,0,1-2.642.725,5.447,5.447,0,0,1-.746-.05,6.222,6.222,0,0,1-.817-.177q-.454-.127-.675-.206t-.788-.291q-.568-.212-.7-.255a12.869,12.869,0,0,1-2.486-1.179A21.073,21.073,0,0,1,5.22,17.066a21.073,21.073,0,0,1-3.061-3.757A12.869,12.869,0,0,1,.98,10.824q-.043-.128-.255-.7T.434,9.339q-.079-.22-.206-.675A6.223,6.223,0,0,1,.05,7.848,5.447,5.447,0,0,1,0,7.1,5.5,5.5,0,0,1,.725,4.46,3.808,3.808,0,0,1,2.23,2.727a5.671,5.671,0,0,1,.973-.3,4.68,4.68,0,0,1,1-.142.79.79,0,0,1,.3.043q.255.085.752,1.079.156.27.426.767t.5.9q.227.4.441.76.043.056.249.355a3.962,3.962,0,0,1,.305.5.927.927,0,0,1,.1.4,1.18,1.18,0,0,1-.4.71,6.9,6.9,0,0,1-.881.781,8.538,8.538,0,0,0-.881.752A1.082,1.082,0,0,0,4.7,10a.962.962,0,0,0,.071.32,2.986,2.986,0,0,0,.121.291q.05.1.2.341t.163.27a14.91,14.91,0,0,0,2.471,3.338,14.91,14.91,0,0,0,3.338,2.471q.029.014.27.163t.341.2a3.1,3.1,0,0,0,.291.121.957.957,0,0,0,.32.071,1.082,1.082,0,0,0,.653-.4,8.538,8.538,0,0,0,.752-.881,6.9,6.9,0,0,1,.781-.881,1.18,1.18,0,0,1,.71-.4.927.927,0,0,1,.4.1,3.962,3.962,0,0,1,.5.305q.3.206.355.249.355.213.76.441t.9.5q.5.27.767.426.994.5,1.079.752a.79.79,0,0,1,.043.3Z"
			transform="translate(0 -2.286)"
		/>
	</svg>
</template>
