<template>
	<button
		type="button"
		class="checkout-summary-button"
		:class="{ 'checkout-summary-button--disabled' : disabled }"
		:disabled="disabled"
		@click="onClickHandler"
	>
		<span class="checkout-summary-button__label"> {{ label }} </span>
		<div
			v-if="!disabled"
			class="checkout-summary-button__chevron-container"
		>
			<BaseIcon
				name="chevron"
				class="checkout-summary-button__chevron-icon"
			/>
		</div>
	</button>
</template>

<script lang="ts" setup>

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

const props = withDefaults(defineProps<{
	label: string,
	disabled?: boolean,
}>(), {
	disabled: false,
});

const emit = defineEmits(['OfferDetailsButtonBotton:click']);

const onClickHandler = (): void => {
	if (!props.disabled) {
		emit('OfferDetailsButtonBotton:click');
	}
};
</script>

<style lang="scss" scoped>
	.checkout-summary-button {
		display: grid;
		padding: 0;
		color: $color-primary;
		cursor: pointer;
		border: 0.2rem solid $color-primary-l4;
		border-radius: $border-radius-x-large;
		width: 100%;
		background: $color-page-background;
		max-width: 43rem;
		margin: auto;

		&__label {
			font-size: $font-small;
			font-weight: $bold-default;
			font-style: italic;
			grid-column-start: 1;
			grid-row-start: 1;
			justify-self: center;
			color: $color-checkout-font;
			line-height: 1.9;
		}

		&__chevron-container {
			grid-column-start: 1;
			grid-row-start: 1;
			justify-self: right;
			margin-right: 1.5rem;
			margin-top: 0.7rem;
		}

		&__chevron-icon {
			height: 0.8rem;
			fill: $color-primary;
			transform: rotate(90deg);
			transform-origin: bottom;
			display: block;
		}

		&--disabled {
			cursor: not-allowed;
			border-color: $color-stars-background;

			span {
				color: #707070;
			}
		}
	}
</style>
