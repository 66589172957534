<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		width="91.818"
		height="91.818"
		viewBox="0 0 91.818 91.818"
	>
		<defs>
			<filter
				id="Ellipse_474"
				x="0"
				y="0"
				width="91.818"
				height="91.818"
				filterUnits="userSpaceOnUse"
			>
				<feOffset
					dx="-1"
					dy="1"
					input="SourceAlpha"
				/>
				<feGaussianBlur result="blur" />
				<feFlood
					flood-color="#126e78"
					flood-opacity="0.306"
				/>
				<feComposite
					operator="in"
					in2="blur"
				/>
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="unser"
				x="25.891"
				y="15.53"
				width="43.438"
				height="28.871"
				filterUnits="userSpaceOnUse"
			>
				<feOffset
					dx="-1"
					dy="1"
					input="SourceAlpha"
				/>
				<feGaussianBlur result="blur-2" />
				<feFlood
					flood-color="#126e78"
					flood-opacity="0.463"
				/>
				<feComposite
					operator="in"
					in2="blur-2"
				/>
				<feComposite in="SourceGraphic" />
			</filter>
			<filter
				id="TIPP"
				x="9.247"
				y="25.12"
				width="68.169"
				height="51.416"
				filterUnits="userSpaceOnUse"
			>
				<feOffset
					dx="-1"
					dy="1"
					input="SourceAlpha"
				/>
				<feGaussianBlur result="blur-3" />
				<feFlood
					flood-color="#126e78"
					flood-opacity="0.463"
				/>
				<feComposite
					operator="in"
					in2="blur-3"
				/>
				<feComposite in="SourceGraphic" />
			</filter>
		</defs>
		<g
			id="Group_401"
			data-name="Group 401"
			transform="translate(33.836 -0.234) rotate(30)"
		>
			<g
				id="Group_401-2"
				data-name="Group 401"
			>
				<g
					transform="matrix(0.87, -0.5, 0.5, 0.87, -29.19, 17.12)"
					filter="url(#Ellipse_474)"
				>
					<circle
						id="Ellipse_474-2"
						class="ellipse"
						data-name="Ellipse 474"
						cx="33.242"
						cy="33.242"
						r="33.242"
						transform="translate(34.24 0) rotate(30)"
						fill="#d9e15f"
					/>
				</g>
				<g
					transform="matrix(0.87, -0.5, 0.5, 0.87, -29.19, 17.12)"
					filter="url(#unser)"
				>
					<text
						id="unser-2"
						data-name="unser"
						transform="matrix(0.97, 0.24, -0.24, 0.97, 27.86, 30.08)"
						fill="#fff"
						font-size="14"
						font-family="OpenSans-Semibold, Open Sans"
						font-weight="600"
					><tspan
						x="0"
						y="0"
					>unser</tspan></text>
				</g>
				<g
					transform="matrix(0.87, -0.5, 0.5, 0.87, -29.19, 17.12)"
					filter="url(#TIPP)"
				>
					<text
						id="TIPP-2"
						data-name="TIPP"
						transform="matrix(0.97, 0.24, -0.24, 0.97, 12.18, 53.26)"
						fill="#fff"
						font-size="27"
						font-family="OpenSans-Extrabold, Open Sans"
						font-weight="800"
					><tspan
						x="0"
						y="0"
					>TIPP</tspan></text>
				</g>
			</g>
		</g>
	</svg>
</template>
