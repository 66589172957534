/* eslint-disable no-param-reassign */
import OldModal from '@lmt-rpb/Modal/Modal.vue';
import { EventBus } from '@global-js/event-bus';
import NewModal from './modal';

type Modal = NewModal | InstanceType<typeof OldModal>

export default class ModalManager {
	public static modalOpenHistory: Modal[] = [];

	static {
		window.addEventListener('popstate', () => {
			this.closeLastModal();
		});
	}

	static closeLastModal() {
		const lastPushedModal = this.modalOpenHistory[this.modalOpenHistory.length - 1];
		this.closeModal(lastPushedModal);
		EventBus.$emit('lastModalClosed');
		EventBus.$off('lastModalClosed');
	}

	static closeModal(modal: Modal) {
		if (modal instanceof NewModal && modal.modalRef.open) {
			modal.modalRef.close(); // new Modal
		} else if (!(modal instanceof NewModal) && modal) {
			modal.model = false; // old Modal
		}
		this.modalOpenHistory.pop();
		if (this.modalOpenHistory.length === 0) {
			document.body.style.overflow = 'auto';
		}
	}

	static closeModalAndChildren(modal: Modal) {
		if (this.modalOpenHistory.includes(modal)) {
			window.history.back();
			EventBus.$on('lastModalClosed', () => { this.closeModalAndChildren(modal); });
		}
	}
}
