<template>
	<svg
		id="Component_178"
		data-name="Component 178"
		xmlns="http://www.w3.org/2000/svg"
		width="13.471"
		height="13.033"
		viewBox="0 0 13.471 13.033"
	>
		<path
			id="exclamation-circle"
			d="M6.735,2a6.63,6.63,0,0,1,6.735,6.516,6.63,6.63,0,0,1-6.735,6.516A6.63,6.63,0,0,1,0,8.516,6.63,6.63,0,0,1,6.735,2ZM7.858,12.58V10.968a.278.278,0,0,0-.272-.28H5.9a.288.288,0,0,0-.29.28V12.58a.288.288,0,0,0,.29.28H7.586A.278.278,0,0,0,7.858,12.58ZM7.841,9.662,8,4.393a.169.169,0,0,0-.088-.153.345.345,0,0,0-.21-.068H5.771a.345.345,0,0,0-.21.068.17.17,0,0,0-.088.153l.149,5.269c0,.119.131.212.3.212H7.543a.276.276,0,0,0,.3-.212Z"
			transform="translate(0 -2)"
		/>
	</svg>
</template>
