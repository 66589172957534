<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="26.502"
		viewBox="0 0 20 26.502"
	>
		<path
			id="marker"
            class="icon-location"
			d="M9,2.25c4.967,0,9,3.764,9,8.4a7.5,7.5,0,0,1-.877,3.6A58.815,58.815,0,0,1,9,26.25a58.815,58.815,0,0,1-8.123-12A7.5,7.5,0,0,1,0,10.65c0-4.637,4.033-8.4,9-8.4ZM9,14.8A4.3,4.3,0,0,0,13.44,10.65,4.3,4.3,0,0,0,9,6.5,4.3,4.3,0,0,0,4.558,10.65,4.3,4.3,0,0,0,9,14.8Z"
			transform="translate(1.002 -1.25)"
			fill="none"
			stroke="#707070"
			stroke-width="2"
		/>
	</svg>
</template>
