<template>
	<div class="checkout-sepa">
		<div class="checkout-sepa__name-info">
			<CheckoutTextField
				v-model.trim="firstName"
				label="Vorname"
				field-name="first-name"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. Max"
				error-message="Vorname fehlt"
				:validation-schema="validateForm ? stringRequiredSchema : noSchema"
				:step-nr="stepNr"
			/>
			<CheckoutTextField
				v-model.trim="lastName"
				label="Nachname"
				field-name="last-name"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. Mustermann"
				error-message="Nachname fehlt"
				:validation-schema="validateForm ? stringRequiredSchema : noSchema"
				:step-nr="stepNr"
			/>
		</div>
		<div class="checkout-sepa__payment-info">
			<CheckoutTextField
				v-model.trim="iban"
				class="checkout-insurance__text-field--uppercase"
				label="IBAN"
				field-name="iban"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. AT02 2011 1000 0342 9660"
				:error-message="errorMessage('IBAN')"
				:validation-schema="validateForm ? ibanRequiredSchema : noSchema"
				:step-nr="stepNr"
			/>
			<CheckoutTextField
				v-model.trim="bic"
				class="checkout-insurance__text-field--uppercase"
				label="BIC"
				field-name="bic"
				:required-field="true"
				:unique-key="uniqueKey"
				placeholder="z.B. GIBAATWW"
				:error-message="errorMessage('BIC')"
				:validation-schema="validateForm ? bicRequiredSchema : noSchema"
				:step-nr="stepNr"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useStore } from '@/components/common/store';
import {
	stringRequiredSchema, ibanRequiredSchema, bicRequiredSchema, noSchema,
} from '@/js/utils/validation-schemas';
import { CheckedInsuranceIndex, CheckoutValidationState } from '@/components/common/store/checkout-validation-state';
import { z } from 'zod';
import CheckoutTextField from '../CheckoutTextField/CheckoutTextField.vue';

const store = useStore();

const props = withDefaults(defineProps<{
	uniqueKey?: string,
	validateForm?: boolean,
	stepNr: number,
}>(), {
	uniqueKey: '',
	validateForm: true,
});

const updatePayment = (value: Record<string, string>): void => {
	store.dispatch('checkout/updatePayment', value);
};

const iban = computed({
	get() {
		return store.state.checkout.Payment.IBAN || '';
	},
	set(value) {
		updatePayment({ IBAN: value.toUpperCase() }); // TODO remove after implement 10988 Ticket
	}
});

const bic = computed({
	get() {
		return store.state.checkout.Payment.BIC;
	},
	set(value) {
		updatePayment({ BIC: value.toUpperCase() });
	}
});

const errorMessage = (type: 'IBAN' | 'BIC') => {
	const isIban = type === 'IBAN';
	const inputValue = isIban ? iban.value : bic.value;

	if (inputValue.length === 0) {
		return `${type} fehlt`;
	}

	return `${type} ungültig`;
};

const firstName = computed({
	get() {
		return store.state.checkout.Payment.FirstName;
	},
	set(value: string) {
		updatePayment({ FirstName: value });
	}
});

const lastName = computed({
	get() {
		return store.state.checkout.Payment.LastName;
	},

	set(value: string) {
		updatePayment({ LastName: value });
	}
});

const setValidState = (inputValue: string, fieldName: string, validationSchema: z.ZodString | z.ZodPipeline) => {
	const validationResult = validationSchema.safeParse(inputValue);
	CheckoutValidationState.value[`${fieldName}-${props.uniqueKey}`].valid = !!inputValue && validationResult.success;
};

watch(() => CheckedInsuranceIndex.value, () => {
	setValidState(firstName.value, 'first-name', stringRequiredSchema);
	setValidState(lastName.value, 'last-name', stringRequiredSchema);
	setValidState(iban.value, 'iban', ibanRequiredSchema);
	setValidState(bic.value, 'bic', bicRequiredSchema);
});
</script>

<style lang="scss" scoped>
.checkout-sepa {
	@media (min-width: $breakpoint-verysmall) {
		&__name-info {
			display: flex;
			gap: 0.8rem;
		}

		&__payment-info {
			display: flex;
			gap: 0.8rem;
		}
	}

	@media (min-width: $breakpoint-small) {
		&__name-info {
			gap: 1.6rem;
		}

		&__payment-info {
			gap: 1.6rem;
		}
	}
}

.checkout-insurance {
	&__text-field--uppercase {
		:deep(input) {
			text-transform: uppercase;
		}
	}
}
</style>
