<template>
	<div
		class="offer-details-modal-button"
		:class="{'offer-details-modal-button--short-modal': shortModal}"
	>
		<OfferDetailsButton
			:label="label"
			:disabled="disabled"
			@OfferDetailsButtonBotton:click="showModal"
		/>
		<OfferDetailsModal
			ref="modal"
			class="offer-details-modal-button__modal"
			:title="label"
			@OfferDetailsModal:closeModal="closeModal"
		>
			<slot
				v-if="modalOpen"
			></slot>
		</OfferDetailsModal>
	</div>
</template>

<script lang="ts" setup>
import OfferDetailsButton from '@lmt-rpb/OfferDetailsButton/OfferDetailsButton.vue';
import OfferDetailsModal from '@lmt-rpb/OfferDetailsModal/OfferDetailsModal.vue';
import { ref } from 'vue';

interface Props {
	shortModal?: boolean
	label: string
	disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	shortModal: false,
	default: false,
	disabled: false,
});

const modal = ref<InstanceType<typeof OfferDetailsModal>>();

const emit = defineEmits(['OfferDetailsModalButton:clickButton']);

// necessary for carousel to correctly init image sizes
// carousel doesn't calculate the width if they are not rendered
const modalOpen = ref(false);

const closeModal = () => {
	modalOpen.value = false;
};

const showModal = (): void => {
	modal.value?.open();
	modalOpen.value = true;
	emit('OfferDetailsModalButton:clickButton');
};

</script>

<style lang="scss" scoped>
@media screen and (min-width: $breakpoint-small) {
	.offer-details-modal-button--short-modal {
		.offer-details-modal-button__modal {
			max-width: 92.6rem;
			min-height: fit-content;
			max-height: 60rem;
			margin: auto;
		}

		:deep(.offer-details-modal__close-button) {
			display: none;
		}

		:deep(.offer-details-modal__close-button--flights-tablet) {
			display: flex;
			position: sticky;
			left: 85%;
			bottom: 7%;
		}
	}
}
</style>
