<template>
	<div
		ref="root"
		class="hotel-image-slider"
	>
		<Swiper
			:modules="modules"
			:slides-per-view="1"
			navigation
			class="hotel-image-slider__container-image"
		>
			<SwiperSlide
				v-for="(item, index) in images"
				:key="index"
				:alt="alt"
			>
				<ImageWithPlaceholder
					v-if="index === 0 && itemIndex === 0"
					class="hotel-image-slider__image"
					:src="item + '/m'"
					width="282"
					height="188"
				/>
				<ImageWithPlaceholder
					v-else
					class="hotel-image-slider__image rpb_lazy"
					:src="item + '/m'"
					width="282"
					height="188"
					loading="lazy"
				/>
			</SwiperSlide>
		</Swiper>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getFluxImageUrl } from '@utils/utils';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ImageWithPlaceholder from '@lmt-rpb/ImageWithPlaceholder/ImageWithPlaceholder.vue';

const modules = [Navigation];

interface Props {
	iffCode: number,
	alt: string,
	numberOfImages: number;
	itemIndex?: number
}

const props = withDefaults(defineProps<Props>(), { itemIndex: -1 });

const imageUrl = getFluxImageUrl() || 'https://images.flux.reisen';

const images = computed(() => {
	const imageArray = [];

	const maxAmountofPictures = props.numberOfImages;

	for (let i = 0; i < maxAmountofPictures; i++) {
		imageArray.push(`${imageUrl}/hotel/${props.iffCode}/${i + 1}`);
	}
	return imageArray;
});

</script>

<style lang="scss" scoped>
.hotel-image-slider {
	&__container-image {
		border-top-left-radius: $border-radius-medium;
		border-top-right-radius: $border-radius-medium;
		width: 28.2rem;
		background: white;
		height: 18.8rem;
	}

	&__image {
		font-size: 0;
		overflow: hidden;
		position: relative;
		width: 28.2rem;
	}
}

// swiper own properties
:deep(.swiper-button-prev) {
	align-items: center;
	background: hsl(0deg 0% 100% / 50%);
	border: none;
	border-radius: 50%;
	display: flex;
	height: 4rem;
	padding-right: 0.5rem;
	width: 4rem;
	z-index: 5;
	font-size: 2rem;
	color: $color-primary;

	&::after {
		font-size: 3rem;
	}
}

:deep(.swiper-button-next) {
	color: $color-primary;
	align-items: center;
	background: hsl(0deg 0% 100% / 50%);
	border: none;
	border-radius: 50%;
	display: flex;
	height: 4rem;
	padding-left: 0.5rem;
	width: 4rem;
	z-index: 5;

	&::after {
		font-size: 3rem;
	}
}

:deep(.swiper-button-disabled) {
	opacity: 0;
}
</style>
