<template>
	<button
		type="button"
		class="calendar-pager"
		:disabled="disabled"
		:aria-disabled="disabled"
		:class="pagerClass"
		tabindex="-1"
		@click="slide"
	>
		<BaseIcon
			:name="direction === 'left' ? 'chevronLeft' : 'chevronRight'"
			class="calendar-pager__icon"
		/>
	</button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props{
	direction: string,
	disabled: boolean
}

const props = withDefaults(defineProps<Props>(), {
	direction: 'left' || 'right',
	disabled: false,
});

const emit = defineEmits(['slide']);

const axis = computed((): number => (props.direction === 'left' ? -1 : 1));

const pagerClass = computed((): string => (props.direction === 'left' ? 'calendar-pager--revert' : ''));

const slide = () => {
	emit('slide', axis.value);
};

</script>

<style lang="scss" scoped>
.calendar-pager {
	padding: 1rem 0.3rem 1rem 2rem;
	border: none;
	outline: none;
	background: $color-white;
	cursor: pointer;
	fill: $color-primary;

	.calendar-pager__icon {
		width: 1.5rem;
		height: 3rem;
	}

	&:disabled .calendar-pager__icon {
		fill: $color-medium-gray;
	}

	&.calendar-pager--revert {
		padding: 1rem 2rem 1rem 0.3rem;
	}

	&:hover,
	&:focus .calendar-pager__icon {
		fill: $color-primary;
	}

	&.best-price-calendar__pager {
		padding: unset;
		background: $color-primary;
		height: 3.2rem;
		width: 4.5rem;
		border-radius: 0 $border-radius-medium $border-radius-medium 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		fill: $color-white;

		&.calendar-pager--revert {
			border-radius: $border-radius-medium 0 0 $border-radius-medium;
		}

		.calendar-pager__icon {
			width: unset;
			height: unset;
		}

		&:hover,
		&:focus .calendar-pager__icon {
			fill: $color-white;
		}

		&:disabled .calendar-pager__icon {
			fill: white;
		}

		&:disabled {
			transition: background-color 0.2s ease-in-out 0.2s;
			background: $color-text-secondary;
		}
	}
}

</style>
