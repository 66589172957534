<template>
	<div class="catalogue-info">
		<template v-if="catalogueInfo.OfferCodeMatches && !catalogueInfo.CatalogValid">
			<div class="catalogue-info__warning-wrap">
				<div class="catalogue-info__warning is-outdated">
					<BaseIcons
						name="directFlight"
						class="catalogue-info__warning-icon"
					/>
					<span class="catalogue-info__warning-main-text">Leider liegt derzeit keine zum Angebot passende Hotelbeschreibung des gewählten Veranstalters in der gewählten Saison vor.</span>
					<div class="catalogue-info__warning-info-text">
						Alternativ zeigen wir Ihnen die Beschreibung des Veranstalters zur vorherigen Saison, diese kann unter Umständen von der gewählten Saison abweichen.
					</div>
				</div>
			</div>
		</template>

		<div
			v-if="!catalogueInfo.OfferCodeMatches"
			class="catalogue-info__warning-wrap catalogue-info__warning-wrap--flex"
			:class="{'no-operator': !tourOperators.length}"
		>
			<div class="catalogue-info__warning is-not-valid">
				<BaseIcons
					name="directFlight"
					class="catalogue-info__warning-icon"
				/>
				<span class="catalogue-info__warning-main-text">Leider liegt derzeit keine zum Angebot passende Hotelbeschreibung des gewählten Veranstalters vor.</span>
				<div
					v-if="tourOperators.length"
					class="catalogue-info__warning-info-text"
				>
					Die angezeigte Beschreibung von einem alternativen Veranstalter dient dazu Ihnen einen Eindruck von der Ausstattung der Hotelanlage zu geben.
				</div>
			</div>

			<SelectField
				v-if="tourOperators.length"
				v-model="selectedOperator"
				:items="tourOperators"
				:show-footer="true"
				:button-wording="getLocaleString('close')"
				label="Infos von anderen Veranstaltern anzeigen *"
				value-prop="code"
				label-prop="name"
				class="catalogue-info__operator-select"
			/>
		</div>

		<template v-if="catalogueInfo.TourOperatorName">
			<CatalogueInfoContent
				:hotel-info="catalogueInfo"
				:client="client"
			>
				<p class="catalogue-info__body-hint">
					Bitte beachten Sie, dass nur die beim von Ihnen ausgewählten Angebot verlinkte Hotelbeschreibung Gültigkeit hat! Es ist möglich, dass in Einzelfällen nicht alle Veranstalter Hotelbeschreibungen ausweisen oder es entscheidende Unterschiede in den beschriebenen Leistungen gibt.
				</p>
			</CatalogueInfoContent>
		</template>
	</div>
</template>

<script lang="ts" setup>
import { HotelCatalogueType } from '@interfaces/hotel-catalogue-types';
import { getLocaleString } from '@utils/utils';
import BaseIcons from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import {
	computed, ref, watch,
} from 'vue';
import CatalogueInfoContent from '@lmt-rpb/CatalogueInfoContent/CatalogueInfoContent.vue';

interface Props {
	catalogueInfo: HotelCatalogueType
	client?: string
}

const props = withDefaults(defineProps<Props>(), {
	client: 'at'
});

const emit = defineEmits(['operator-changed']);
const selectedOperator = ref(props.catalogueInfo.TourOperatorCode || null);

const tourOperators = computed((): Record<string, string>[] => {
	if (props.catalogueInfo.TourOperators) {
		return Object.entries(props.catalogueInfo.TourOperators).map(([code, name]) => ({ code, name }));
	}
	return [];
});

watch(() => selectedOperator.value, (operator: string | null) => {
	emit('operator-changed', operator);
});

</script>

<style lang="scss" scoped>
.catalogue-info {
	.catalogue-info__warning {
		padding-left: 2rem;
		border-left: 0.2rem solid $color-warning;
	}

	.catalogue-info__warning-wrap {
		padding: 2rem 0.8rem;

		&--flex {
			@include media-query-up($breakpoint-small) {
				flex-direction: row;
				align-items: center;
			}

			display: flex;
			flex-direction: column;

			&:not(.no-operator) .catalogue-info__warning {
				@include media-query-up($breakpoint-small) {
					width: 65%;
					margin-right: 3rem;
				}
			}
		}
	}

	.catalogue-info__warning-icon {
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		fill: $color-text;
		vertical-align: middle;
	}

	.catalogue-info__warning-main-text {
		font-weight: $bold-default;
		vertical-align: middle;
	}

	.catalogue-info__operator-select {
		@include media-query-up($breakpoint-small) {
			flex: 1 1 auto;
		}

		position: relative;
		margin: 1.5rem 0;

		:deep(.form-field__label-text) {
			font-size: 1.1rem;
		}

		:deep(.form-field__input) {
			font-size: 2.2rem;
			color: $color-primary;
		}

		:deep(.dropdown__box) {
			left: 0;
			width: 100%;
			transform: none;
		}

		:deep(.dropdown__box),
		:deep(.dropdown__inner) {
			border-top-right-radius: 0;
		}
	}

	.catalogue-info__body {
		@include media-query-up ($breakpoint-small) {
			margin-top: 0.5rem;
		}

		flex: 2 1 auto;
		padding: 0 2rem 2rem 0;

		strong,
		b {
			color: $color-text;
		}
	}

	.catalogue-info__body-hint {
		margin-top: 2rem;
		font-weight: $bold-default;
		font-size: 1.1rem;
	}
}
</style>
