<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="27"
		height="27"
		viewBox="0 0 27 27"
	>
		<path
			id="question-circle-o"
			d="M15.469,20.844v2.813a.556.556,0,0,1-.562.563H12.094a.556.556,0,0,1-.562-.562V20.844a.556.556,0,0,1,.563-.562h2.813A.556.556,0,0,1,15.469,20.844Zm4.5-8.719c0,2.5-1.74,3.481-3.024,4.2-.913.528-1.477.862-1.477,1.424v.563a.556.556,0,0,1-.562.563H12.093a.556.556,0,0,1-.562-.562V17.118c0-2.162,1.547-2.847,2.795-3.41,1.055-.493,1.705-.826,1.705-1.617,0-1.019-1.284-1.775-2.443-1.775a3.111,3.111,0,0,0-1.67.475,6.95,6.95,0,0,0-1.406,1.459.533.533,0,0,1-.44.21.539.539,0,0,1-.334-.106l-1.9-1.441a.571.571,0,0,1-.123-.756,6.817,6.817,0,0,1,6.135-3.375h0c2.882,0,6.117,2.285,6.117,5.344ZM13.5,4.25A11.25,11.25,0,1,0,24.75,15.5,11.26,11.26,0,0,0,13.5,4.25ZM27,15.5A13.5,13.5,0,1,1,13.5,2h0A13.5,13.5,0,0,1,27,15.5Z"
			transform="translate(0 -2)"
			fill="#16828f"
		/>
	</svg>
</template>
