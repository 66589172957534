<template>
	<svg
		id="Ebene_1"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 600 281"
		style="enable-background:new 0 0 600 281;"
		xml:space="preserve"
		preserveAspectRatio="none"
	>
		<g class="st0">
			<g transform="matrix(1, 0, 0, 1, 0, 0)">
				<path
					id="Subtraction_2-3"
					class="st1"
					d="M29.4,281H5c-2.8,0-5-2.2-5-5V0h74C63.7,12,54.4,24.9,46.2,38.5
			c-8.1,13.6-15.1,27.9-20.7,42.7C13.9,111.6,8,143.8,8,176.3c0,18.2,1.8,36.3,5.5,54.1C17.1,247.7,22.5,264.7,29.4,281L29.4,281z
			 M595,281h-8.4c5.5-12.9,10-26.2,13.4-39.8V276C600,278.8,597.8,281,595,281z M600,111.4c-5.2-20.5-12.8-40.4-22.6-59.2
			C567.6,33.5,555.7,16,542,0h58V111.4L600,111.4z"
				/>
			</g>
			<g transform="matrix(1, 0, 0, 1, 0, 0)">
				<path
					id="Subtraction_2-4"
					class="st1"
					d="M29.4,281H0V0h74C63.7,12,54.4,24.9,46.2,38.5c-8.1,13.6-15.1,27.9-20.7,42.7
			C13.9,111.6,8,143.8,8,176.3c0,18.2,1.8,36.3,5.5,54.1C17.1,247.7,22.5,264.7,29.4,281L29.4,281z M600,281h-13.4
			c5.5-12.9,10-26.2,13.4-39.8V281z M600,111.4c-5.2-20.5-12.8-40.4-22.6-59.2C567.6,33.5,555.7,16,542,0h58V111.4L600,111.4z"
				/>
			</g>
		</g>
	</svg>
</template>
