<template>
	<div
		class="theme-minimal"
		@click.prevent="$emit('ThemeMinimal:Edit')"
	>
		<div
			class="theme-minimal__form"
			autocomplete="true"
		>
			<div class="theme-minimal__values">
				<span class="theme-minimal__destination">{{ destination }}</span>
				<div>
					<span class="theme-minimal__rest">{{ searchMaskData }}</span>
				</div>
			</div>
			<BaseIcon
				class="theme-minimal__svg"
				name="pencil"
			></BaseIcon>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { NBSP } from '@global-js/constants';
import { pluralize, getTravelDuration, formatDateInterval } from '@utils/utils';
import { SearchFormDataType } from '@interfaces/search-form';
import { useStore } from '@/components/common/store';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

const store = useStore();

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const destination = computed((): undefined | null | string => formData.value.destination?.label || '');

const offerDuration = computed((): string => {
	const { from, to } = formData.value.offerDuration;
	return formatDateInterval(from, to);
});

const travelers = computed((): string => {
	const { adult, children } = formData.value.travelers;
	const adultLabel = (!isMinTablet.value && `${adult}${NBSP}Erw.`) || pluralize(adult, 'Erwachsener', 'Erwachsene');
	let travelersLabel = `${adultLabel}`;
	if (children.length) {
		travelersLabel += ', ' + pluralize(children.length, 'Kind', 'Kinder');
		travelersLabel += `${NBSP}(${children.map((c) => ((c === 1 ? '<2' : c) + NBSP + 'J.')).join(',' + NBSP)})`;
	}
	return travelersLabel;
});

const getSerchMaskData = () => {
	let result = '';
	if (offerDuration.value) {
		result = offerDuration.value + ' | ';
	}
	if (formData.value.travelDuration) {
		result += getTravelDuration(formData.value.travelDuration) + ' | ';
	}
	result += travelers.value;
	return result;
};

const searchMaskData = ref(getSerchMaskData());

watch(isMinTablet, () => {
	searchMaskData.value = getSerchMaskData();
});

watch(formData.value, () => {
	searchMaskData.value = getSerchMaskData();
});

</script>

<style lang="scss" scoped>
.theme-minimal {
	@include media-query-up($breakpoint-large) {
		justify-content: center;
		font-size: $font-very-small;
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
	}

	display: flex;
	justify-content: flex-start;
	height: 7.2rem;
	width: 100%;
	padding: 0 2.66%;
	font-size: 1.2rem;
	cursor: pointer;
	background-color: $header-bar-bg;

	.theme-minimal__form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: $breakpoint-container;
		border: 0.1rem solid $color-border;
		border-radius: $border-radius-theme-minimal;
		padding: 1rem 1.6rem;
		background-color: $color-white;
		margin: 0.8rem 0;
	}

	.theme-minimal__values {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.theme-minimal__svg {
		fill: $color-primary;
	}

	.theme-minimal__rest {
		padding-right: 1.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;

		@media (min-width: $breakpoint-small) {
			font-size: $font-very-small;
		}
	}

	.theme-minimal__destination {
		@include media-query-up($breakpoint-large) {
			display: inline-block;
			max-width: 60rem;
		}

		white-space: nowrap;
		margin: 0 2rem 0.25rem 0;
		font-family: $font-family-special;
		font-weight: $bold-default;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $color-text;

		@media (min-width: $breakpoint-small) {
			font-size: $font-very-small;
		}
	}

	.theme-minimal__travelduration {
		@include visible-from($breakpoint-large, inline-block);
	}
}
</style>
