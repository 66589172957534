<template>
	<label
		:for="option"
		class="credit-card-radio-button"
		:class="{'selected': isSelected }"
	>
		<input
			:id="option"
			type="radio"
			class="credit-card-radio-button__input"
			:value="option"
			name="credit-card-radio-input"
			@change="onChange"
		/>
		<span
			class="credit-card-radio-button__label"
			:class="{'selected': isSelected }"
		>{{ option }}</span>
		<div
			class="credit-card-radio-button__logo"
			:class="{'selected': isSelected }"
		>
			<img
				:src="logoUrl"
				:alt="option"
			/>
		</div>
	</label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getBaseUrl } from '@utils/utils';

interface Props {
	option: string,
	selected?: string,
}
const props = defineProps<Props>();

const emit = defineEmits(['CreditRadio:Change']);

const onChange = (event: Event) => {
	emit('CreditRadio:Change', (event?.target as HTMLInputElement).value);
};

const isSelected = computed<boolean>(() => props.selected === props.option);

const logoName = computed<string>(() => {
	if (props.option === 'American Express') {
		return 'amex';
	}
	if (props.option === 'Mastercard') {
		return 'mc';
	}
	if (props.option === 'Visa') {
		return 'visa';
	}
	return '';
});

const logoUrl = computed<string>(() => `${getBaseUrl()}/fileadmin/2/restplatzboerse/all/img/payment/${logoName.value}.svg`);

</script>

<style lang="scss" scoped>
.credit-card-radio-button {
	position: relative;
	border: 0.1rem solid $color-search-mask-border;
	border-radius: $border-radius-x-small;
	min-width: 17.6rem;
	min-height: 9rem;
	height: 9rem;
	cursor: pointer;
	padding: 0.8rem;
	background: $color-page-background;
	margin-bottom: 0;
	box-shadow: inset #85E1EC66 0.2rem 0 0.2rem;
	box-shadow: #0000000A 0.1rem 0.2rem 0.2rem;

	&.selected {
		background: $color-checkout-button;
		color: $color-page-background;
		border: 0.2rem solid  $color-primary;
		box-shadow: inset #00000029 0 0.3rem 0.6rem;
	}

	&__label {
		cursor: pointer;
		font-size: $font-very-small;
		color: $color-checkout-button;
		margin-left: 0.4rem;

		&.selected {
			color: $color-page-background;
		}
	}

	&__input {
		cursor: pointer;
		opacity: 0;
	}

	&__input + .credit-card-radio-button__label::before {
		content: '';
		position: absolute;
		top: 1.1rem;
		left: 0.7rem;
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 2rem;
		margin-right: 0.8rem;
		border: 0.1rem solid $color-checkout-button;
		flex-shrink: 0;
	}

	&.selected &__label::before {
		border: 0.4rem solid $color-checkout-button;
		box-shadow: 0 0 0 0.1rem white;
		background: $color-white;
	}

	&.selected &__input {
		&:focus,
		&:hover,
		&:active {
			& + .credit-card-radio-button__label::before {
				border: 0.3rem solid $color-checkout-button;
				box-shadow: 0 0 0 0.1rem white;
			}
		}
	}

	&__input:focus,
	&__input:hover,
	&__input:active {
		& + .credit-card-radio-button__label::before {
			box-shadow: 0 0 0 0.4rem $color-checkout-hover-radio;
		}
	}

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0.6rem;
		padding: 1rem;
		border-radius: $border-radius-x-small;
		background: $color-page-background;
		opacity: 0.5;
		height: 5rem;
		margin: 0.2rem;

		&.selected {
			opacity: 1;
		}
	}

	@media (min-width: $breakpoint-small) {
		width: 19rem;
	}
}
</style>
