<template>
	<!-- Generated by IcoMoon.io -->
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 46 32"
	>
		<path
			fill="#658FFF"
			d="M17.6 7.6c1.2-1.4 2.6-3 5.4-3s4.2 1.6 5.4 3c1.2 1.4 2 2.4 3.8 2.4s2.8-1 3.8-2.4c1.2-1.4 2.6-3 5.4-3v2c-1.8 0-2.8 1-3.8 2.4-1.2 1.4-2.6 3-5.4 3s-4.2-1.6-5.4-3c-1.2-1.4-2-2.4-3.8-2.4s-2.8 1-3.8 2.4c-1.4 1.4-2.8 3-5.4 3s-4.2-1.6-5.4-3c-1.2-1.4-2-2.4-3.8-2.4v-2c2.8 0 4.2 1.6 5.4 3 1 1.4 1.8 2.4 3.8 2.4s2.6-1 3.8-2.4z"
		/>
		<path
			fill="#658FFF"
			d="M23 14.2c-1.8 0-2.8 1-3.8 2.4-1.4 1.4-2.8 3.2-5.4 3.2s-4.2-1.8-5.4-3.2c-1.2-1.4-2-2.4-3.8-2.4v-2c2.8 0 4.2 1.6 5.4 3s2 2.4 3.8 2.4 2.8-1 3.8-2.4c1.2-1.4 2.6-3 5.4-3s4.2 1.6 5.4 3c1.2 1.4 2 2.4 3.8 2.4s2.8-1 3.8-2.4c1.2-1.4 2.6-3 5.4-3v2c-1.8 0-2.8 1-3.8 2.4-1.2 1.4-2.6 3-5.4 3s-4-1.6-5.4-3c-1-1.2-2-2.4-3.8-2.4z"
		/>
		<path
			fill="#658FFF"
			d="M23 22c-1.8 0-2.8 1-3.8 2.4-1.2 1.4-2.6 3-5.4 3s-4.2-1.6-5.4-3c-1.2-1.4-2-2.4-3.8-2.4v-2c2.8 0 4.2 1.6 5.4 3s2 2.4 3.8 2.4 2.8-1 3.8-2.4c1.2-1.4 2.6-3 5.4-3s4.2 1.6 5.4 3c1.2 1.4 2 2.4 3.8 2.4s2.8-1 3.8-2.4c1.2-1.4 2.6-3 5.4-3v2c-1.8 0-2.8 1-3.8 2.4-1.2 1.4-2.6 3-5.4 3s-4.2-1.6-5.4-3c-1-1.4-2-2.4-3.8-2.4z"
		/>
	</svg>
</template>
