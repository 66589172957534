import { App } from 'vue';

/*
* Vue3 doesn't replace the mount point like Vue2 did.
* Instead Vue3 adds the vue app as a child.
* This function mimics the Vue2 behaviour,
* so we don't have to clean up the extra container for now.
*
* WARNING: not yet tested with multiroot components.
*
* Further information:
* https://v3-migration.vuejs.org/breaking-changes/mount-changes.html
*/
export const mountReplace = (app: App, target: Element) => {
	const fragment = document.createDocumentFragment();

	// Ignore TS warning by casting to Element.
	app.mount(fragment as unknown as Element);

	target.parentNode?.replaceChild(fragment, target);
};
