<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="28.5"
		height="26.21"
		viewBox="0 0 28.5 26.21"
	>
		<g
			data-name="Gruppe 2932"
			transform="translate(-18.792 -496.061)"
		>
			<path
                class="icon-home"
				data-name="Pfad 4578"
				d="M71.782,71.007a1.456,1.456,0,0,0-1.978,0l-7.891,7.716a.394.394,0,0,0-.12.283l.12,13.314a1.215,1.215,0,0,0,1.227,1.2H78.686a1.215,1.215,0,0,0,1.227-1.2l-.12-13.314a.4.4,0,0,0-.12-.283Z"
				transform="translate(-37.646 427.501)"
				fill="none"
				stroke="#707070"
				stroke-width="2.5"
			/>
			<path
                class="icon-home"
				data-name="Vereinigungsmenge 29"
				d="M13,1,26,13Zm0,0L0,13Z"
				transform="translate(20.042 496.311)"
				fill="none"
				stroke="#707070"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2.5"
			/>
		</g>
	</svg>
</template>
