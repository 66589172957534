<template>
	<div class="filter-mobile">
		<div
			v-if="!isDesktop && pageType === 'hotelPage'"
			id="filter-mobile-filters"
			class="filter-mobile__filter-header"
		>
			<BaseIcon
				name="filter"
				class="filter-mobile__filter-icon"
			/>
			<h2 class="filter-mobile__filter-headline">
				Filter
				<span
					v-show="filterAmount"
					class="filter-mobile__filter-num"
				>
					({{ filterAmount }} aktiv)
				</span>
			</h2>
		</div>
		<FilterTagList v-if="!isDesktop && pageType === 'hotelPage'" />
		<template v-if="!isDesktop && (pageType === 'hotelList' || pageType === 'regionList')">
			<div
				id="filter-mobile-filters"
				class="filter-mobile__filter-header"
			>
				<BaseIcon
					name="filter"
					class="filter-mobile__filter-icon"
				/>
				<h2 class="filter-mobile__filter-headline">
					Filter
					<span
						v-show="filterAmount"
						class="filter-mobile__filter-num"
					>
						({{ filterAmount }} aktiv)
					</span>
				</h2>
			</div>
			<FilterTagList v-if="!isDesktop" />
			<div class="filter-mobile__additional-group">
				<h2
					v-if="showDestinationFilter"
					class="filter-mobile__subheader--hotellist filter-mobile__header--before-input filter-mobile__header--destination"
				>
					{{ locationType === 'COUNTRY' || locationType === 'REGIONGROUP' ? 'Region' : 'Ort' }}
				</h2>
				<DestinationFilter
					v-if="showDestinationFilter && !destinationFilterLoading"
					class="filter-mobile__destination-filter"
					:destination-label="destinationLabel"
					:location-type="locationType"
					:disabled="!destinationTypes || destinationTypes.length == 0"
				/>
				<SkeletonLoader
					v-if="showDestinationFilter && destinationFilterLoading"
					class="filter-mobile__skeleton"
					:loader-width="100"
					:loader-height="7"
					:border-radius="'1rem'"
					:width-unit="'%'"
				/>
			</div>
			<div
				v-if="!isDesktop && !hotelOnly"
				ref="transferGroup"
				class="filter-mobile__transfer-group"
			>
				<h2 class="filter-mobile__subheader--hotellist">
					Flug und Transport
				</h2>
				<DirectFlight class="filter-mobile__direct-flight-field" />
				<TransferField class="filter-mobile__transfer-field" />
			</div>
			<div class="filter-mobile__stay-group">
				<h2 class="filter-mobile__subheader--hotellist">
					Verpflegung und Zimmer
				</h2>
				<BoardField class="filter-mobile__board-types-field" />
				<RoomTypeField class="filter-mobile__room-type-field" />
				<SeaView class="filter-mobile__sea-view-field" />
			</div>
			<div class="filter-mobile__additional-group">
				<h2 class="filter-mobile__subheader--hotellist filter-mobile__header--before-input filter-mobile__header--hotel-category">
					Hotelkategorie ab
				</h2>
				<HotelCategory
					:value="formData.hotelCategory"
					:show-suns="showSuns"
					class="filter-mobile__hotel-category-filter"
				/>
				<h2 class="filter-mobile__subheader--hotellist filter-mobile__header--before-input">
					Weiterempfehlungsrate ab
				</h2>
				<RecommendationRate
					:value="formData.minMeanRecommendationRate"
					class="filter-mobile__recommendation-filter"
				/>
				<div class="filter-mobile__subheader--hotellist filter-mobile__header--before-input">
					<PriceSlider
						:default-value="formData.maxPrice"
						:currency="currencyType"
						class="filter-mobile__price-filter"
					/>
				</div>
				<Collapse
					collapse-headline="Beliebteste Filter"
					class="filter-mobile__collapse filter-mobile__collapse filter-mobile__most-popular"
					:open="true"
				>
					<template #header>
						<h2 class="filter-mobile__subheader--hotellist filter-mobile__header--before-input">
							Beliebteste Filter
						</h2>
					</template>
					<MostPopularFilters :filters="formData.mostPopularFilters" />
				</Collapse>
				<Collapse
					class="filter-mobile__collapse filter-mobile__collapse"
					:open="openAdditionalCheckboxFilters"
				>
					<template #header>
						<h2 class="filter-mobile__subheader--hotellist filter-mobile__header--before-input">
							Mehr Filter
						</h2>
					</template>
					<AdditionalCheckboxFilters :filters="formData.additionalCheckboxFilters" />
				</Collapse>
				<OperatorField
					v-if="!tourOperatorLoading"
					class="filter-mobile__operator-filter"
					:disabled="!operatorTypes || operatorTypes.length == 0"
				/>
				<SkeletonLoader
					v-if="operatorTypes.length > 0 && tourOperatorLoading"
					class="filter-mobile__skeleton"
					:loader-width="100"
					:loader-height="7"
					:border-radius="'1rem'"
					:width-unit="'%'"
				/>
			</div>
		</template>
		<template v-if="isHotelPageRef">
			<div
				v-if="!isDesktop && !hotelOnly"
				ref="transferGroup"
				class="filter-mobile__transfer-group filter-mobile__room-and-board"
			>
				<h2 class="filter-mobile__subheader">
					Flug und Transport
				</h2>
				<DirectFlight class="filter-mobile__direct-flight-field" />
				<TransferField class="filter-mobile__transfer-field" />
			</div>

			<div
				v-if="!isDesktop"
				class="filter-mobile__stay-group"
			>
				<h2 class="filter-mobile__subheader">
					Verpflegung und Zimmer
				</h2>
				<BoardField
					:is-hotel-page="true"
					class="filter-mobile__board-types-field"
				/>
				<RoomTypeField
					:is-hotel-page="true"
					class="filter-mobile__room-type-field"
				/>
				<SeaView class="filter-mobile__sea-view-field" />
			</div>
			<OperatorField
				v-if="!tourOperatorLoading && !isDesktop"
				class="filter-mobile__operator"
				:disabled="!operatorTypes || operatorTypes.length == 0"
			/>
			<SkeletonLoader
				v-if="tourOperatorLoading && !isDesktop"
				class="filter-mobile__operator-skeleton"
				:loader-width="31.8"
				:loader-height="7"
				:border-radius="'1rem'"
			/>
		</template>
	</div>
</template>

<script lang="ts" setup>
import {
	ref, computed, onMounted, onUpdated, onBeforeUnmount,
} from 'vue';
import { useStore } from '@/components/common/store';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import FilterTagList from '@lmt-rpb/FilterTagList/FilterTagList.vue';
import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import DirectFlight from '@lmt-rpb/DirectFlight/DirectFlight.vue';
import TransferField from '@lmt-rpb/TransferField/TransferField.vue';
import BoardField from '@lmt-rpb/BoardField/BoardField.vue';
import RoomTypeField from '@lmt-rpb/RoomTypeField/RoomTypeField.vue';
import SeaView from '@lmt-rpb/SeaView/SeaView.vue';
import HotelCategory from '@lmt-rpb/HotelCategory/HotelCategory.vue';
import RecommendationRate from '@lmt-rpb/RecommendationRate/RecommendationRate.vue';
import PriceSlider from '@lmt-rpb/PriceSlider/PriceSlider.vue';
import Collapse from '@lmt-rpb/Collapse/Collapse.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFilters/MostPopularFilters.vue';
import AdditionalCheckboxFilters from '@lmt-rpb/AdditionalCheckboxFilters/AdditionalCheckboxFilters.vue';
import OperatorField from '@lmt-rpb/OperatorField/OperatorField.vue';
import { getFilterCount } from '@/js/utils/search-filter-utils';
import { EventBus } from '@global-js/event-bus';
import DepartureField from '@lmt-rpb/DepartureField/DepartureField.vue';

const store = useStore();

const transferGroupHeight = ref(0);
const props = defineProps({
	isHotelPage: { type: Boolean },
	hotelOnly: { type: Boolean },
});
const isDesktop = computed((): boolean => store.state.config.isDesktop);
const formData = computed(() => store.state.searchMask);
const progress = ref<number>(formData.value.onlyHotel ? 100 : 0);
const pageType = computed((): string => store.state.config.pageType);
const showDestinationFilter = computed((): boolean => {
	if (pageType.value === 'regionList') {
		return false;
	}
	return store.state.config.locationType !== 'CITY';
});
const isDefaultHotelOnly = computed((): boolean => store.state.config.hotelOnly);
const departureField = ref<InstanceType<typeof DepartureField> | null>(null);
const departureFieldHeight = ref<number>(0);
const transferGroup = ref<HTMLElement | null>(null);
const filterAmount = computed((): number => getFilterCount(formData.value));
const locationType = computed((): 'COUNTRY' | 'TOPREGION' | 'CITY' | string => store.state.config.locationType);
const destinationTypes = computed(() => store.state.types.destination);
const destinationLabel = computed((): string => (store.state.config.destinationName ? store.state.config.destinationName : ''));
const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);
const showSuns = computed((): boolean => store.state.config.client === 'com');
const currencyType = computed((): string => (store.state.config.client === 'ch' ? 'CHF' : 'EUR'));
const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);
const operatorTypes = computed(() => store.state.types.operator);
const openAdditionalCheckboxFilters = ref(Boolean(formData.value.additionalCheckboxFilters?.ratingAttributes?.length || formData.value.additionalCheckboxFilters?.hotelAttributes?.length));
const isHotelPageRef = ref(props.isHotelPage);

const updateSizes = (): void => {
	if (departureField.value?.$el?.scrollHeight) {
		departureFieldHeight.value = departureField.value?.$el?.scrollHeight;
	}
	if (transferGroup.value) {
		transferGroupHeight.value = transferGroup.value.scrollHeight;
	}
};

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onUpdated(() => {
	const departureFieldScrollHeight = departureField.value?.$el?.scrollHeight;

	transferGroupHeight.value = 230; // default value for max-height

	if (departureFieldScrollHeight && (departureFieldScrollHeight !== departureFieldHeight.value)) {
		departureFieldHeight.value = departureFieldScrollHeight;
	}
});

onMounted(() => {
	if (!props.isHotelPage) {
		isHotelPageRef.value = document.body.classList.contains('page_hotel');
	}

	EventBus.$on('window:resize', updateSizes);

	if (isDefaultHotelOnly.value) {
		setAccommodationType(true);
	}

	updateSizes();
});

onBeforeUnmount(() => {
	EventBus.$off('window:resize', updateSizes);
});
</script>

<style lang="scss" scoped>
.filter-mobile {
	max-width: 144rem;
	width: 100%;
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;

	// form fields
	&__travel-group,
	&__transfer-group,
	&__stay-group,
	&__additional-group,
	&__operator {
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
	}

	&__stay-group {
		margin-bottom: 2.5rem;
	}

	&__subheader {
		padding: 2.5rem 0 1.5rem;
		align-self: flex-start;
		font-family: $font-family;
		font-weight: 600;
		color: $color-text-secondary;

		&--hotellist {
			@extend .filter-mobile__subheader;

			font-size: 2rem;
		}
	}

	&__board-types-field,
	&__room-type-field {
		margin-bottom: 0.5rem;
		width: 100%;
	}

	&__field {
		width: 100%;
		margin-bottom: 0.5rem;
	}

	&__transfer-group {
		@extend .filter-mobile__field;

		&.filter-mobile__transfer-group--is-dragging {
			overflow: hidden;
		}

		&:not(.filter-mobile__transfer-group--is-dragging) {
			transition: opacity 0.25s, max-height 0.25s, margin-bottom 0.25s, visibility 0.25s;
			max-height: 23rem;
		}
	}

	:deep(.collapse__icon) {
		width: 3rem;
		height: 2.3rem;
		fill: $color-primary;
		margin-top: 2rem;
	}

	&__room-and-board {
		margin-bottom: 0;
	}

	&__filter-icon {
		width: 2.3rem;
		height: 2.3rem;
		fill: $color-primary;
	}

	&__filter-header {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 1.4rem;
		align-items: center;
		margin: 3.6rem 0 2rem;
		padding: 0.8rem 1.5rem;
	}

	&__filter-num {
		font-size: 1.8rem;
		font-family: $font-family;
		font-weight: 300;
	}

	&__filter-headline {
		font-family: $font-family-special;
		color: $color-primary;
		font-size: 2.4rem;
		font-weight: 700;
		text-align: left;
		padding: 0;
	}

	&__tabs {
		display: flex;
		z-index: 10;
		list-style-type: none;
	}

	&__tab {
		padding: 0.5rem 2rem;
		border-width: 0.2rem 0.2rem 0 0.2rem;
		border-top-left-radius: $border-radius-small;
		border-top-right-radius: $border-radius-small;
		fill: $search-inactive-tab;
		color: $search-inactive-tab;
		font-size: 1.6rem;
		position: relative;
		bottom: -0.5rem;
		cursor: pointer;
		z-index: 30;

		&.is-active {
			fill: $color-primary-l1;
			border: 1px solid;
			color: $color-primary-l1;
			border-color: $color-lime-light;
			background: $color-light-gray;
			z-index: 40;
			font-weight: bold;
			bottom: 0;
			box-shadow: 0.2rem 0 0.3rem rgba(0, 0, 0, 0.15);

			&::after {
				position: absolute;
				content: '';
				left: 0;
				right: 0;
				bottom: -0.5rem;
				height: 1rem;
				background: $color-white;
				z-index: 50;
			}
		}
	}

	&__tab-icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: inherit;
		vertical-align: middle;

		&.package-icon {
			height: 3rem;
			width: 6rem;
		}

		&.is-small {
			width: 1rem;
			height: 1rem;
		}
	}

	&__operator-filter {
		margin-top: 2.5rem;
	}

	&__direct-flight-field {
		margin-bottom: 2.5rem;
		min-height: 4.1rem;
	}

	&__transfer-field {
		margin-bottom: 1rem;
	}

	&__sea-view-field {
		margin-top: 0.8rem;
		margin-bottom: 1rem;
	}

	&__header--before-input {
		text-align: left;
		color: $color-text-l24;
		font-size: 2rem;
		font-weight: 600;
		width: 100%;
		cursor: pointer;
		border-top: 0.1rem solid $color-primary-l4;
		margin-top: 2.4rem;
		padding: 1.8rem 0;
	}

	&__most-popular {
		border-top: 0.1rem solid $color-primary-l4;
		margin-top: 3rem;
	}

	:deep(.collapse__header) .filter-mobile__header--before-input {
		border-top: none;
		margin: 0;
		text-align: left;
		padding: 0 0 1.8rem 0;
		width: 100%;
		cursor: pointer;
		margin-top: 1.8rem;
	}

	&__collapse {
		position: relative;
		border-bottom: 0.1rem solid $color-primary-l4;
	}

	&__header--destination {
		border-top: none;
		padding-top: 0;
		margin-top: 1.6rem;
		padding-bottom: 1.6rem;
	}

	&__header--hotel-category {
		margin-top: 0;
	}

	&__header--operator {
		border-top: 0;
		margin-top: 0;
	}

	&__heading-small {
		font-size: $font-small;
		font-weight: 500;
	}

	&__footer {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 2.4rem 0;
		z-index: 20;
	}

	&__submit-button {
		width: 100%;
		max-width: 30rem;
		padding: 1rem 2rem;
	}

	&__cancel-button {
		margin-right: 2rem;
		padding: 0 1.5rem;
	}

	@media (min-width: $breakpoint-extralarge) {
		display: none;
	}
}

</style>
