<template>
	<img
		ref="imageRef"
		v-bind="$attrs"
		:loading
		:data-src="src"
		:src="src"
		:width
		:height
		:alt
		:class="{'not-loaded': !loaded}"
		@load="onLoad"
	/>
	<img
		v-if="!loaded"
		src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
		:class="classList"
		:width
		:height
		:alt
	/>
</template>

<script setup lang="ts">
import {
	computed, Ref, ref, useAttrs,
} from 'vue';

type Props = {
	src: string
	width?: number | string
	height?: number | string
	alt?: string
	loading?: 'lazy' | 'eager'
}

withDefaults(defineProps<Props>(), {
	width: 282, height: 188, alt: undefined, loading: undefined
});

const attrs = useAttrs();

const classList = computed(() => attrs.class);

const imageRef: Ref<HTMLImageElement | null> = ref(null);

const loaded = ref(false);
const onLoad = () => {
	loaded.value = true;
};

</script>

<style lang="scss" scoped>
.not-loaded {
	opacity: 0;
	position: absolute;
	inset: 0;
}

</style>
