import { ANY } from '@global-js/constants';

export default [
	{ id: 1, label: 'mit Transfer', value: 'TransferIncluded' },
	{ id: 2, label: 'ohne Transfer', value: 'TransferNotIncluded' },
];

export const transferTypesNew = [
	{ id: 0, label: 'Beliebig', value: ANY },
	{ id: 1, label: 'mit Transfer', value: 'TransferIncluded' },
	{ id: 2, label: 'ohne Transfer', value: 'TransferNotIncluded' },
];
