<template>
	<checkbox
		v-model="seaView"
		class="sea-view"
		label="Zimmer mit Meerblick"
	/>
</template>

<script lang="ts" setup>

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { viewTypeValues } from '@/js/data/view-types';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';

const store = useStore();

// We can get/set the state like this because it's a checkbox field and
// only 'FullOcean' is possible at the moment
const seaView = computed({
	get() {
		return store.state.searchMask.roomViews.length > 0;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { roomViews: value ? viewTypeValues : [] });
	}
});
</script>
