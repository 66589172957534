<template>
	<span
		class="hotelrating"
		:class="{'hotelrating__sun' :showSuns }"
	>
		<template
			v-for="i in count"
			:key="i"
		>
			<BaseIcon
				:name="showSuns ? 'sun' : 'star'"
				class="hotelrating__icon"
			>
				<title>Hotelkategorie {{ rating }}</title>
			</BaseIcon>
		</template>
		<BaseIcon
			v-if="showHalf"
			:name="showSuns ? 'halfSun' : 'halfStar'"
			class="hotelrating__icon hotelrating__icon--half"
		>
			<title>Hotelkategorie {{ rating }}</title>
		</BaseIcon>
	</span>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed } from 'vue';

interface Props {
	rating: number,
	showSuns: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	showSuns: false
});

const count = computed(() => Math.floor(props.rating));
const showHalf = computed(() => props.rating % 1 !== 0);

</script>

<style lang="scss" scoped>
$size: 1.4rem;

.hotelrating {
	font-size: 0;
	display: inline-block;
	text-align: center;
	width: 100%;

	.hotelrating__icon {
		width: $size;
		height: $size;
		margin-right: 0.15rem;
		fill: $color-secondary;

		&.hotelrating__icon--half {
			width: 0.7rem;
		}
	}
}
</style>
