import GaItemList from './GaItemList';

const consentStatusExists = (): boolean => window.dataLayer.map((d) => d.event).includes('consent_status');
export default abstract class AnalyticsEvent {
	items: GaItemList;

	static analyticsEventListener: {analyticsEvent: AnalyticsEvent, eventString: string}[] = [];

	constructor(items: GaItemList) {
		this.items = items;
	}

	protected fireGoogleAnalyticsEventOrAddListener(event: string): void {
		if (!window.gtag) {
			console.log('fire GoogleAnalyticsEvent: ', event, '\n with following attributes:', this.items);
		} else if (consentStatusExists()) {
			this.fireGoogleAnalyticsEvent(event);
		} else {
			this.addToDataLayerListener(event);
			// new DatalayerListener(() => window.gtag('event', event, this.items));
		}
	}

	protected fireGoogleAnalyticsEvent(event: string): void {
		window.gtag('event', event, this.items);
	}

	fireGoogleAnalyticsEventByListener(item: any, listener: {analyticsEvent: AnalyticsEvent, eventString: string}) {
		if (item.event === 'consent_status') {
			const index = AnalyticsEvent.analyticsEventListener.findIndex((l) => l.analyticsEvent === listener.analyticsEvent && l.eventString === listener.eventString);
			if (index > -1) {
				AnalyticsEvent.analyticsEventListener.splice(index, 1);
			}
			this.fireGoogleAnalyticsEvent(listener.eventString);
		}
	}

	private addToDataLayerListener(eventString: string) {
		AnalyticsEvent.analyticsEventListener.push({ analyticsEvent: this, eventString });
	}

	public getItems() {
		return this.items;
	}
}

window.dataLayer = window.dataLayer || [];
window.dataLayer.push = function(item: any) {
	Array.prototype.push.call(this, item);
	AnalyticsEvent.analyticsEventListener.forEach((listener) => listener.analyticsEvent.fireGoogleAnalyticsEventByListener(item, listener));
};
