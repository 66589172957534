import { AdditionalCheckboxFilters, MostPopularFilters } from '@/interfaces/checkbox-filter-types';
import {
	mostPopularTypes,
	additionalCheckboxFilterTypes,
	boardTypes as boardItemTypes,
	roomTypes as roomItemTypes,
	transferTypes as transferItemTypes,
} from '@/js/data/index';
import { MergedOfferItemData } from '@interfaces/offer';
import { cloneDeep, dateDiff, offsetDate } from '@utils/utils';
import { TRAVEL_DURATION_EXACT, timeZoneOffsetInHours } from '@global-js/constants';
import { SearchFormDataType } from '@interfaces/search-form';
import { viewTypeValues } from '@/js/data/view-types';

export const getBoard = (val: string | number | string[] | number[], key = 'id', mapped?: string) => {
	if (Array.isArray(val)) {
		const selectableBoardTypes = boardItemTypes.filter((obj) => obj.showInMenu);

		// eslint-disable-next-line arrow-body-style
		return (val as number[]).map((prop) => {
			const item = selectableBoardTypes.find((type: { [key: string]: any }) => type[key] === prop);
			return mapped ? (item as { [key: string]: any })?.[mapped] : item;
		});
	}

	const item = boardItemTypes.find((type: { [key: string]: any }) => type[key] === val);
	return mapped ? (item as { [key: string]: any })?.[mapped] : item;
};

export const getRoom = (val: string | number | string[] | number[], key = 'id', mapped?: string) => {
	if (Array.isArray(val)) {
		// eslint-disable-next-line arrow-body-style
		return (val as number[]).map((prop) => {
			const item = roomItemTypes.find((type: { [key: string]: any }) => type[key] === prop);
			return mapped ? (item as { [key: string]: any })?.[mapped] : item;
		});
	}

	const item = roomItemTypes.find((type: { [key: string]: any }) => type[key] === val);
	return mapped ? (item as { [key: string]: any })?.[mapped] : item;
};

export const getTransfer = (val: string | number | string[] | number[], key = 'id', mapped?: string) => {
	if (Array.isArray(val)) {
		// eslint-disable-next-line arrow-body-style
		return (val as number[]).map((prop) => {
			const item = transferItemTypes.find((type: { [key: string]: any }) => type[key] === prop);
			return mapped ? (item as { [key: string]: any })?.[mapped] : item;
		});
	}

	const item = transferItemTypes.find((type: { [key: string]: any }) => type[key] === val);
	return mapped ? (item as { [key: string]: any })?.[mapped] : item;
};

const getMostPopularFilters = (
	ratingAttributeValues: number[],
	beachValues: number[],
	targetGroupValues: number[],
	hotelAttributeValues: number[]
): MostPopularFilters => {
	const ratingAttributes: string[] = [];
	const hotelAttributes: string[] = [];

	mostPopularTypes.forEach((type) => {
		if (type.attrType === 'HotelAttributes' && type.paramKey === 'beach' && beachValues && beachValues.includes(type.id)) {
			hotelAttributes.push(type.value);
		}
		if (type.attrType === 'HotelAttributes' && type.paramKey === 'trgrp' && targetGroupValues && targetGroupValues.includes(type.id)) {
			hotelAttributes.push(type.value);
		}
		if (type.attrType === 'RatingAttributes' && type.paramKey === 'raatt' && ratingAttributeValues && ratingAttributeValues.includes(type.id)) {
			ratingAttributes.push(type.value);
		}
		if (type.attrType === 'HotelAttributes' && type.paramKey === 'hotat' && hotelAttributeValues && hotelAttributeValues.includes(type.id)) {
			hotelAttributes.push(type.value);
		}
	});
	return { ratingAttributes, hotelAttributes };
};

const getAdditionalCheckboxFilters = (ratingAttributeValues: number[]): AdditionalCheckboxFilters => {
	const ratingAttributes: string[] = [];

	additionalCheckboxFilterTypes.forEach((type) => {
		if (type.attrType === 'RatingAttributes' && ratingAttributeValues && ratingAttributeValues.includes(type.id)) {
			ratingAttributes.push(type.value);
		}
	});
	return { ratingAttributes };
};

export const typeFormat = (data: any, type = ''): any => ({
	number: Number(data),
	array: Array.isArray(data) ? data : data.split(','),
	bool: !!Number(data),
	date: new Date(data).setUTCHours(timeZoneOffsetInHours, 0, 0, 0), // set hour to 11 to prevent timezone error
} as { [key: string]: any })[type] || data;

export const dateParts = (source: string | number | Date): any[] => {
	const date = new Date(source);
	const [y, m, d] = [date.getFullYear(), date.getMonth(), date.getDate()];
	const month = `0${m + 1}`.slice(-2);
	const day = `0${d}`.slice(-2);

	return [y, month, day];
};

export const dashedDate = (source: string | number | Date): string => {
	const parts = dateParts(source);
	return parts.join('-');
};

export const objectToQuery = (params: any): string => {
	const qs = new URLSearchParams(params);
	if (qs.toString() === '%5Bobject+Object%5D=') return ''; // Fix for older browsers to keep the url clean instead of showing [object: Object]
	return decodeURIComponent(qs.toString());
};

/**
 * Adds relative days in the form of 'n_days' to current date
 * and returns ISO date string in the form of YYYY-MM-DD
 *
 * @param {string} relativeDate
 * @returns {string}
 */
const relativeToAbsoluteDate = (relativeDate: string): string => {
	const dayOffset = Number(relativeDate.split('_')[0]);
	const newDate = offsetDate(Date.now(), dayOffset);
	return new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000))
		.toISOString()
		.split('T')[0];
};

export const isValidParam = (paramKey: string, paramValue: string): boolean => {
	if (!paramValue) return false;

	const currentYear = new Date().getFullYear();
	const mmDdRegexString = '[0-9]{2}-[0-9]{2}';
	const yyyyMmDdRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
	const dynamicYearRegex = new RegExp(
		`(^${currentYear}-` + mmDdRegexString + '$)|'
        + `(^${currentYear + 1}-` + mmDdRegexString + '$)|'
        + `(^${currentYear + 2}-` + mmDdRegexString + '$)'
	);
	const relativeDateRegex = /^[0-9]{1,3}_days$/;

	const enabledParams: Record<string, RegExp> = {
		ddate: new RegExp(dynamicYearRegex.source + '|' + relativeDateRegex.source),
		rdate: new RegExp(dynamicYearRegex.source + '|' + relativeDateRegex.source),
		adult: /^[1-4]{1}$/,
		child: /^(?:-?([1-9]|1[0-7])){1,3}?$/,
		board: /^[1-5]$/,
		room: /^(?:[0-9]{1,2},)*([0-9]{1,2})$/,
		aid: /^[0-9]{1,7}$/,
		depap: /^(?:[A-Z]{3},?)+$/,
		dur: /^(?:,?([1-9]|1[0-9]|2[0-8])){1,2}?$|^exact$/,
		dfl: /^1$/,
		sea: /^1$/,
		ibe: /^package$|^hotel$/,
		filter: yyyyMmDdRegex,
		trans: /^[1-3]$/,
		srtHot: /^101$|^5$/,
		stars: /^[1-6]$/,
		rarec: /^[0-9]{2}$/,
		price: /^[0-9]{3,4}$/,
		trgrp: /^(?:,?\d\d?\d?)+$/,
		raatt: /^(?:,?\d\d?\d?)+$/,
		beach: /^(?:,?\d\d?\d?)+$/,
		hotat: /^(?:,?\d\d?\d?)+$/,
		brand: /^(?:,?[A-Z0-9]{2,5})+$/,
		rid: /^[0-9]{1,7}$/,
		cyid: /^[0-9]{1,7}$/,
	};

	if (paramValue !== undefined) {
		if (!paramValue.match(enabledParams[paramKey])) {
			return false;
		}
	}
	return true;
};

export const paramsToForm = (params: Record<string, string>): Record<string, string> => {
	const {
		aid, depap, ibe, dur, ddate, rdate, adult, child, board, room, dfl, sea, filter, coname, trname, cyname, hotelname, trans, srtHot, stars, rarec, price, trgrp, raatt, beach, hotat, brand, rid, cyid
	} = params;

	const hotelId = isValidParam('aid', aid) ? typeFormat(aid, 'number') : undefined;
	const departure = isValidParam('depap', depap) ? typeFormat(depap, 'array') : undefined;
	const onlyHotel = isValidParam('ibe', ibe) ? ibe === 'hotel' : undefined;
	const boardVals = isValidParam('board', board) ? typeFormat(board, 'array').map(Number) : undefined;
	const roomVals = isValidParam('room', room) ? typeFormat(room, 'array').map(Number) : undefined;
	const transferVals = isValidParam('trans', trans) ? typeFormat(trans, 'array').map(Number) : undefined;
	const directFlight = isValidParam('dfl', dfl) ? typeFormat(dfl, 'bool') : undefined;
	const viewVals = isValidParam('sea', sea) ? typeFormat(sea, 'array').map(Number) : undefined;
	const from = isValidParam('ddate', ddate)
		? typeFormat(ddate.includes('_days')
			? relativeToAbsoluteDate(ddate)
			: ddate, 'date')
		: undefined;
	const to = isValidParam('rdate', rdate)
		? typeFormat(rdate.includes('_days')
			? relativeToAbsoluteDate(rdate)
			: rdate, 'date')
		: undefined;
	const relativeFrom = isValidParam('ddate', ddate) && ddate.includes('_days') ? ddate : '';
	const relativeTo = isValidParam('rdate', rdate) && rdate.includes('_days') ? rdate : '';

	const travelDuration = travelDurationParamToForm(dur, from, to);

	const isTravelDurationExactSelected = dur === 'exact';

	const adults = isValidParam('adult', adult) ? typeFormat(adult, 'number') : undefined;

	const childTransform = child && child.includes(',') ? child.replaceAll(',', '-') : child; // legacy fallback
	const children = isValidParam('child', childTransform) ? childTransform.split('-').map(Number) : undefined;
	const hotelAttributeVals = isValidParam('hotat', hotat) ? typeFormat(hotat, 'array').map(Number) : undefined;
	const ratingAttributeVals = isValidParam('raatt', raatt) ? typeFormat(raatt, 'array').map(Number) : undefined;
	const targetGroupVals = isValidParam('trgrp', trgrp) ? typeFormat(trgrp, 'array').map(Number) : undefined;
	const beachVals = isValidParam('beach', beach) ? typeFormat(beach, 'array').map(Number) : undefined;
	const operatorTypes = isValidParam('brand', brand) ? typeFormat(brand, 'array') : undefined;
	const regionId = isValidParam('rid', rid) ? typeFormat(rid, 'array').map(Number) : undefined;
	const cityId = isValidParam('cyid', cyid) ? typeFormat(cyid, 'array').map(Number) : undefined;

	const boardTypes = getBoard(boardVals, 'id', 'value');
	const roomTypes = getRoom(roomVals, 'id', 'value');
	const roomViews = viewVals?.includes(1) ? viewTypeValues : [];
	const transferTypes = getTransfer(transferVals, 'id', 'value');
	const sortingPricePopular = isValidParam('srtHot', srtHot) ? srtHot === '101' : undefined;
	const hotelCategory = isValidParam('stars', stars) ? typeFormat(stars, 'number') : undefined;
	const minMeanRecommendationRate = isValidParam('rarec', rarec) ? typeFormat(rarec, 'number') : undefined;
	const maxPrice = isValidParam('price', price) ? typeFormat(price, 'number') : undefined;
	const mostPopularFilters = getMostPopularFilters(ratingAttributeVals, beachVals, targetGroupVals, hotelAttributeVals);
	const additionalCheckboxFilters = getAdditionalCheckboxFilters(ratingAttributeVals);
	const destinationTypes = cityId && cityId.length > 0 ? cityId : regionId;

	let dest = '';
	if (coname) {
		dest = coname;
	} else if (trname) {
		dest = trname;
	} else if (cyname) {
		dest = cyname;
	} else if (hotelname) {
		dest = hotelname;
	}

	return JSON.parse(JSON.stringify({
		hotelId,
		departure,
		onlyHotel,
		travelDuration,
		boardTypes,
		roomTypes,
		transferTypes,
		directFlight,
		roomViews,
		filter,
		sortingPricePopular,
		hotelCategory,
		minMeanRecommendationRate,
		maxPrice,
		mostPopularFilters,
		additionalCheckboxFilters,
		operatorTypes,
		rid: regionId,
		cyid: cityId,
		destinationTypes,
		offerDuration: { from, to },
		offerDurationRelative: { relativeFrom, relativeTo },
		travelers: { adult: adults, children },
		destination: { label: dest },
		isTravelDurationExactSelected
	}));
};

const getParamString = (setFilters: string[]): Record<string, string> => {
	const params = {
		hotat: '',
		raatt: '',
		beach: '',
		trgrp: '',
	};
	const possibleFilters = [...mostPopularTypes, ...additionalCheckboxFilterTypes];

	setFilters.forEach((setFilter) => {
		const foundFilter = possibleFilters.find((possibleFilter) => possibleFilter.value === setFilter);
		if (foundFilter) {
			params[foundFilter.paramKey as 'hotat' | 'raatt' | 'beach' | 'trgrp'] += `${foundFilter.id},`;
		}
	});
	return params;
};

export const formToParams = (data: { [key: string]: any }, component: string): Record<string, any> => {
	const setFilters = [];

	if (data.mostPopularFilters.ratingAttributes) {
		setFilters.push(...data.mostPopularFilters.ratingAttributes);
	}
	if (data.mostPopularFilters.hotelAttributes) {
		setFilters.push(...data.mostPopularFilters.hotelAttributes);
	}
	if (data.additionalCheckboxFilters.ratingAttributes) {
		setFilters.push(...data.additionalCheckboxFilters.ratingAttributes);
	}

	const paramStrings = getParamString(setFilters);

	// If the offerDuration params were relative & the calendar input wasn't changed by
	// the user we keep on using the relative dates in the url.
	let offerDurationFrom;
	if (data.offerDurationRelative?.relativeFrom) {
		offerDurationFrom = { ddate: data.offerDurationRelative.relativeFrom };
	} else if (data.offerDuration.from) {
		offerDurationFrom = { ddate: dashedDate(data.offerDuration.from) };
	} else {
		offerDurationFrom = {};
	}

	let offerDurationTo;
	if (data.offerDurationRelative?.relativeTo) {
		offerDurationTo = { rdate: data.offerDurationRelative.relativeTo };
	} else if (data.offerDuration.to) {
		offerDurationTo = { rdate: dashedDate(data.offerDuration.to) };
	} else {
		offerDurationTo = {};
	}

	const dur = data.isTravelDurationExactSelected ? TRAVEL_DURATION_EXACT : data.travelDuration;

	const params = {
		ibe: data.onlyHotel ? 'hotel' : 'package',
		adult: data?.travelers?.adult,
		// TODO: REMOVE component === 'offerList' evaluation. Probably?
		...(data.directFlight ? { dfl: 1 } : {}),
		...(data.roomViews && data.roomViews.length > 0 ? { sea: 1 } : {}),
		...(data.hotelId && component === 'offerList' ? { aid: data.hotelId } : {}),
		...(data.boardTypes?.length ? { board: getBoard(data.boardTypes, 'value', 'id') } : {}),
		...(data.roomTypes?.length ? { room: getRoom(data.roomTypes, 'value', 'id') } : {}),
		...(data.transferTypes?.length ? { trans: getTransfer(data.transferTypes, 'value', 'id') } : {}),
		...(data.filter && component === 'offerList' ? { filter: dashedDate(data.filter) } : {}),
		...(data.departure && data.departure?.length ? { depap: data.departure } : {}),
		...(dur ? { dur } : {}),
		...offerDurationFrom,
		...offerDurationTo,
		...(data.travelers?.children?.length ? { child: data.travelers.children.join('-') } : {}),
		srtHot: (data.sortingPricePopular) ? '101' : '5',
		...(data.hotelCategory ? { stars: data.hotelCategory } : {}),
		...(data.minMeanRecommendationRate ? { rarec: data.minMeanRecommendationRate } : {}),
		...(data.maxPrice && data.maxPrice !== 1600 ? { price: data.maxPrice } : {}),
		...(paramStrings.raatt.length ? { raatt: paramStrings.raatt.slice(0, -1) } : {}),
		...(paramStrings.hotat.length ? { hotat: paramStrings.hotat.slice(0, -1) } : {}),
		...(paramStrings.beach.length ? { beach: paramStrings.beach.slice(0, -1) } : {}),
		...(paramStrings.trgrp.length ? { trgrp: paramStrings.trgrp.slice(0, -1) } : {}),
		...(data.operatorTypes && data.operatorTypes.length ? { brand: data.operatorTypes } : {}),
		...(data.rid && data.rid.length ? { rid: data.rid } : {}),
		...(data.cyid && data.cyid.length ? { cyid: data.cyid } : {}),
	};
	return JSON.parse(JSON.stringify(params));
};

export const offerItemDecor = (item: any, searchType: 'hotel' | 'package'): MergedOfferItemData => {
	const formatDate = (unformatted: string) => {
		const [dateString, hours] = unformatted.split(' ');
		const date = new Date(dateString);

		if (hours) {
			const [hour, mins] = hours.split(':').map(Number);
			date.setHours(hour, mins);
		}

		return date;
	};

	const formatTime = (unformatted: string | Date) => {
		if (typeof unformatted === 'object') return '';
		let [_, time] = unformatted.split(' ');
		if (!time) {
			[_, time] = unformatted.split('T');
			if (time) {
				const [hours, mins] = time.split(':');
				time = `${hours}:${mins}`;
			}
		}
		return time;
	};

	const updatedOfferItem: MergedOfferItemData = {
		...cloneDeep(item),
		type: searchType,
		available: null,
		availableChecked: false,
		loading: false,
		duration: searchType === 'hotel' ? item.OvernightStays : item.BetweenDeparturesDuration,
	};

	if (updatedOfferItem.OutboundFlight) {
		updatedOfferItem.OutboundFlight.Departure.Date = formatDate(item.OutboundFlight.Departure.Date);
		updatedOfferItem.OutboundFlight.Departure.time = formatTime(item.OutboundFlight.Departure.Date);
		updatedOfferItem.OutboundFlight.Arrival.Date = formatDate(item.OutboundFlight.Arrival.Date);
		updatedOfferItem.OutboundFlight.Arrival.time = formatTime(item.OutboundFlight.Arrival.Date);
	}
	if (updatedOfferItem.InboundFlight) {
		updatedOfferItem.InboundFlight.Departure.Date = formatDate(item.InboundFlight.Departure.Date);
		updatedOfferItem.InboundFlight.Departure.time = formatTime(item.InboundFlight.Departure.Date);
		updatedOfferItem.InboundFlight.Arrival.Date = formatDate(item.InboundFlight.Arrival.Date);
		updatedOfferItem.InboundFlight.Arrival.time = formatTime(item.InboundFlight.Arrival.Date);
	}

	if (item.OutboundFlightSegments && updatedOfferItem.OutboundFlightSegments) {
		for (let i = 0; i < item.OutboundFlightSegments.length; i++) {
			const segment = item.OutboundFlightSegments[i];
			const updatedSegment = updatedOfferItem.OutboundFlightSegments;
			updatedSegment[i].Departure.time = segment.Departure.Date && formatTime(segment.Departure.Date);
			updatedSegment[i].Departure.Date = segment.Departure.Date && new Date(segment.Departure.Date);
			updatedSegment[i].Arrival.time = segment.Arrival.Date && formatTime(segment.Arrival.Date);
			updatedSegment[i].Arrival.Date = segment.Arrival.Date && new Date(segment.Arrival.Date);
		}
	}

	if (item.InboundFlightSegments && updatedOfferItem.InboundFlightSegments) {
		for (let i = 0; i < item.InboundFlightSegments.length; i++) {
			const segment = item.InboundFlightSegments[i];
			const updatedSegment = updatedOfferItem.InboundFlightSegments;
			updatedSegment[i].Departure.time = segment.Departure.Date && formatTime(segment.Departure.Date);
			updatedSegment[i].Departure.Date = segment.Departure.Date && new Date(segment.Departure.Date);
			updatedSegment[i].Arrival.time = segment.Arrival.Date && formatTime(segment.Arrival.Date);
			updatedSegment[i].Arrival.Date = segment.Arrival.Date && new Date(segment.Arrival.Date);
		}
	}

	return updatedOfferItem;
};

// Remove this function once the season data on country, region and city pages comes from Flux
export const convertSeasonDuration = (duration: string, doNotConvert: boolean) => {
	if (doNotConvert) {
		return duration;
	}

	const typoDuration = [
		'-1', '0', '6_7', '6_14', '6_21', '9', '10', '7', '3', '12', '13', '6_1', '6_2', '6_3', '6_4', '6_5', '6_6', '6_7', '6_8', '6_9', '6_10', '6_11', '6_12', '6_13', '6_14', '6_15', '6_16', '6_17', '6_18', '6_19', '6_20', '6_21', '6_22', '6_23', '6_24', '6_25', '6_26', '6_27', '6_28'];

	const ttDuration = [
		'0', 'exact', '7', '14', '21', '1-4', '5-8', '9-12', '13-15', '16-22', '23,100', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];

	if (typoDuration.indexOf(duration)) {
		return ttDuration[typoDuration.indexOf(duration)];
	}

	return duration;
};

export interface ParsedData extends Partial<SearchFormDataType> {
	urlParams?: SearchFormDataType;
}

/**
 * Parse the data from the editorial and auto gengerated topic pages settings
 * @param dataSet DOMStringMap
 * @return ParsedData modified dataSet
 */
export const parseSearchMaskAndFilterSettings = (dataSet: DOMStringMap): ParsedData => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const parsedData: ParsedData = {};

	(Object.keys(dataSet) as Array<(keyof typeof dataSet)>).forEach((key) => {
		const transformToBooleans = ['isEditorial', 'onlyHotel'];

		if (dataSet[key] && typeof dataSet[key] === 'string') {
			const parsedDataChunk = JSON.parse((dataSet[key] as string));

			// Don't add falsy values
			if (parsedDataChunk) {
				parsedData[key as keyof typeof parsedData] = transformToBooleans.includes(key as string) ? !!parsedDataChunk : parsedDataChunk;
			}
		}
	});

	return parsedData;
};

export const parsedDataToQuery = (parsedData: ParsedData) => {
	const params = formToParams(parsedData, 'HotelGrid');
	return objectToQuery(params);
};

export function getTravelDuration(query: { [key: string]: string }, dataset: any) {
	const queryTravelDuration = getTravelDurationByQuery(query);
	const seasonSettingTravelDuration = getSeasonSettingsTravelDuration(dataset);
	const min = queryTravelDuration && queryTravelDuration[0] ? queryTravelDuration[0] : seasonSettingTravelDuration.min;
	const max = getMaxTravelDuration(queryTravelDuration, seasonSettingTravelDuration.max);
	return { min, max };
}

function getMaxTravelDuration(queryTravelDuration: number[], seasonSettingTravelDurationMax: number) {
	let maxDuration;
	if (queryTravelDuration && queryTravelDuration.length === 1) {
		maxDuration = queryTravelDuration[0];
	} else if (queryTravelDuration && queryTravelDuration[1]) {
		maxDuration = queryTravelDuration[1];
	} else {
		maxDuration = seasonSettingTravelDurationMax;
	}
	return maxDuration;
}

function getTravelDurationByQuery(query: { [key: string]: string }): number[] {
	let queryTravelDuration;
	if (query.dur === 'exact' && query.ddate && query.rdate) {
		queryTravelDuration = [dateDiff(query.ddate, query.rdate)];
	} else if (query.dur) {
		queryTravelDuration = typeFormat(query.dur, 'array').map(Number);
	}
	return queryTravelDuration;
}

function getSeasonSettingsTravelDuration(dataset: any): { min: number, max: number } {
	const min = typeof dataset.minDuration === 'string'
		? +dataset.minDuration
		: dataset.minDuration;	// Default minDuration
	const max = typeof dataset.maxDuration === 'string'
		? +dataset.maxDuration
		: dataset.maxDuration;	// Default maxDuration
	return { min, max };
}

function travelDurationParamToForm(dur: string, from: any, to: any) {
	const travelDurationParam = isValidParam('dur', dur) ? dur : undefined;
	let result;
	if (travelDurationParam === 'exact') {
		result = [dateDiff(from, to)];
	} else if (travelDurationParam !== undefined) {
		result = typeFormat(dur, 'array').map(Number);
	}
	return result;
}
