<template>
	<svg
		id="euro"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 19 19"
	>
		<g
			id="Ebene_1"
			data-name="Ebene 1"
		>
			<g
				id="Gruppe_342"
				data-name="Gruppe 342"
			>
				<circle
					id="Ellipse_9"
					data-name="Ellipse 9"
					cx="9.5"
					cy="9.5"
					r="9.5"
					fill="#16828f"
				/>
				<path
					id="Pfad_2119"
					data-name="Pfad 2119"
					d="M14.034,7.289c-1.316-.528-2.477,0-3.469,1.506l3.16,1.274-.317.8L10.156,9.533,10,9.885l-.162.394-.1.289,2.871,1.154-.324.8L9.516,11.405a3.519,3.519,0,0,0,.155,2.111A2.47,2.47,0,0,0,11.113,14.8a5.116,5.116,0,0,0,2.076.366l-.38.929A5.25,5.25,0,0,1,10.7,15.7a3.617,3.617,0,0,1-2.027-1.787,4.574,4.574,0,0,1-.2-2.906L7.44,10.6l.324-.8.943.38L8.8,9.913l.113-.274.211-.493-.957-.394.324-.8L9.5,8.365A4.7,4.7,0,0,1,11.669,6.3a3.519,3.519,0,0,1,2.737.049,4.82,4.82,0,0,1,2.027,1.52l-.788.7A4.321,4.321,0,0,0,14.027,7.31Z"
					transform="translate(-2.204 -1.793)"
					fill="#fafafa"
				/>
				<path
					id="Pfad_2120"
					data-name="Pfad 2120"
					d="M10.27,18.914a8.592,8.592,0,1,1,6.073-2.519A8.592,8.592,0,0,1,10.27,18.914Zm0-16.565A7.98,7.98,0,1,0,15.92,4.7,7.98,7.98,0,0,0,10.27,2.349Z"
					transform="translate(-0.495 -0.512)"
					fill="#16828f"
				/>
			</g>
		</g>
	</svg>
</template>
