<template>
	<div class="credit-card-radio-button-group">
		<CreditCardRadioButton
			v-for="option in options"
			:key="option"
			:option="option"
			:selected="modelValue"
			@CreditRadio:Change="onCreditRadioChange"
		/>
	</div>
</template>

<script lang="ts" setup>
import CreditCardRadioButton from '@lmt-rpb/CreditCardRadioButton/CreditCardRadioButton.vue';

interface Props {
	options: string[],
	modelValue?: string,
}

defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const onCreditRadioChange = (value: string | undefined) => {
	emit('update:modelValue', value);
};

</script>

<style lang="scss" scoped>
.credit-card-radio-button-group {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
	gap: 1.6rem;
	width: 90%;

	// margin-bottom: 3.5rem;
}

@media (min-width: $breakpoint-mobiletabs) {
	.credit-card-radio-button-group {
		width: auto;
	}
}

@media (min-width: $breakpoint-small) {
	.credit-card-radio-button-group {
		display: flex;
		justify-content: flex-start;
	}
}
</style>
