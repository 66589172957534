<template>
	<li
		:data-currency="currency"
		:data-price="price"
		class="quickpill"
	>
		<a
			:href="url"
			:title="titleTag"
			:target="linkTarget"
			data-ga-event="select_content"
			data-ga-content_type="Quickpill"
			:data-ga-item_id="titleTag"
		>
			<h3 class="quickpill__headline">{{ destination }}</h3>
			<span
				v-if="price"
				class="quickpill__price"
			> ab {{ priceFormatted }} </span>
		</a>
	</li>
</template>

<script lang="ts" setup>
import { formatPrice } from '@/js/utils/priceUtils';
import { computed } from 'vue';

interface Props {
	destination: string,
	url?: string,
	linkTarget?: string,
	price?: string,
	currency?: string,
	titleTag?: string,
}

const props = withDefaults(defineProps<Props>(), {
	url: '',
	linkTarget: '',
	currency: 'EUR',
	price: '',
	titleTag: '',
});

const priceFormatted = computed((): string => {
	if (Number.isNaN(props.price)) {
		return props.price;
	}
	return formatPrice(Number(props.price), props.currency);
});

</script>

<style lang="scss" scoped>
.quickpill__headline {
	font-size: 2.1rem;
	font-weight: 700;
	font-family: $font-family;
	color: $color-link;
	margin: 0.5rem 0.8rem 0.3rem 0.2rem;
	padding: 0;
	display: inline-block;
	transition: all 0.3s ease;
	white-space: nowrap;
	max-width: 80%;

	@include textEllipsis(auto);

	vertical-align: middle;
	line-height: 1.3;
	flex: 1 1 auto;
	text-align: left;
}

.quickpill {
	position: relative;
	margin: 0;
	text-decoration: none;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	border-bottom: 0.1rem dashed $color-lime-light;
	box-sizing: border-box;
	text-align: center;

	a {
		background: $color-page-background;
		display: flex;
		justify-content: space-between;
		padding: 0.5rem 1rem 0.8rem 1.2rem;
		transform: translate(0, 0);
		transition: all 0.3s ease;
		text-decoration: none;

		&:hover,
		&:active,
		&:focus {
			outline: 0;
			background: $color-light-gray;
			overflow: hidden;

			.quickpill__headline {
				color: $color-link;
			}
		}
	}
}

.quickpill__price {
	font-size: 1.6rem;
	font-weight: 600;
	color: $color-extra;
	display: block;
	clear: both;
	margin: 0.8rem 0.8rem 0 auto;
	padding-left: 0.3rem;
	position: relative;
	bottom: 0;
	right: 0;
	text-align: right;
	white-space: nowrap;
}

.quickpill__icon-arrow {
	fill: $color-link;
	height: 1.7rem;
	margin: 0.2rem 0 0;
	width: auto;
	align-self: center;
}

/* Mobile landscape */
@media screen and (min-width: $breakpoint-verysmall) {
	.quickpill__headline {
		font-size: 2.7rem;
		margin: 0.3rem 0 0.3rem 0.2rem;
	}

	.quickpill__price {
		font-size: 1.8rem;
		margin: 0.8rem 0.8rem 0 auto;
		position: relative;
		bottom: 0;
		right: 0;
		text-align: right;
	}
}

/* Tablet portrait */
@media screen and (min-width: $breakpoint-small) {
	.quickpill__headline {
		font-size: 3.5rem;
		font-weight: 700;
		margin: 0;
		transition: all 0.3s ease;
		max-width: 60rem; // Fix for too long region names.
	}

	.quickpill {
		margin: $quickpill-top-margin-desktop 0.5rem 0;
		display: inline-block;
		vertical-align: middle;
		width: auto;
		background-image:
			linear-gradient(
				to bottom,
				$color-primary-l6 0%,
				$color-primary-l4 100%
			);
		border-radius: 4rem;
		padding: 0.1rem;
		border-bottom: 0.1rem solid #fff;
	}

	.quickpill a {
		background: $color-page-background;
		border-radius: 4rem;
		display: block;
		padding: 0.4rem 3rem .7rem;
		transform: translate(0, 0);
		transition: transform 0.3s ease, 0.3s ease;
		height: 5.7rem;

		&:hover,
		&:active,
		&:focus {
			overflow: hidden !important;
			box-shadow: 0 -0.1rem 0 0 $color-primary-l6;
			transform: translate(0.1rem, -0.1rem);
			background: #fff;
		}
	}

	.quickpill__price {
		font-size: 2.1rem;
		display: inline-block;
		margin: 0 0.8rem 0 0.8rem;
		bottom: 0.5rem;
		vertical-align: bottom;
	}

	.quickpill__icon-arrow {
		display: none;
	}
}

/* Medium Screens */
@media screen and (min-width: $breakpoint-medium) {
	.quickpill__headline {
		max-width: none;
	}
}
</style>
