<template>
	<div
		class="direct-flight__input-container"
		:class="disabled ? 'direct-flight__input-container--disabled' : ''"
	>
		<Checkbox
			v-model="directFlight"
			:disabled="disabled"
			class="direct-flight"
			label="Nur Direktflüge anzeigen"
		/>
		<transition name="info-text">
			<div
				v-if="showInfoText"
				class="direct-flight__info-bubble"
			>
				<p
					class="direct-flight__info-text"
				>
					Es werden nur Angebote angezeigt, die mit hoher Wahrscheinlichkeit eine direkte Flugverbindung ohne Umsteigen beinhalten.
				</p>
			</div>
		</transition>
		<base-icon
			name="directFlight"
			class="direct-flight__info-icon"
			@keypress.enter.native="showInfoText = !showInfoText"
			@mouseover.native="showInfoText = true"
			@mouseleave.native="showInfoText = false"
		/>
	</div>
</template>

<script lang="ts" setup>

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeMount, onMounted, ref,
} from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
	disabled: false
});

const store = useStore();

const showInfoText = ref(false);

const directFlight = computed({
	get() {
		if (store.state.searchMask) {
			return store.state.searchMask.directFlight;
		}
		return false;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { directFlight: value });
	},
});

const resetInput = (): void => {
	store.dispatch('resetItem', 'directFlight');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

onMounted(() => {
	if (props.disabled) resetInput();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeMount(() => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});

</script>

<style lang="scss" scoped>
.direct-flight__input-container {
	position: relative;
	display: flex;
}

.direct-flight__info-icon {
	visibility: visible;
	opacity: 1;
	width: 2rem;
	height: 2rem;
	margin: 0.3rem 0 0 0.8rem;
	fill: $color-link;
	cursor: pointer;
}

.direct-flight__info-bubble {
	position: absolute;
	width: 100%;
	bottom: 3.55rem;
	left: 0;
}

.direct-flight__info-text {
	position: relative;
	color: $color-white;
	background-color: $color-primary;
	font-size: 1.2rem;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
}

.direct-flight__info-text::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 21rem;
	width: 0;
	height: 0;
	border: 1.55rem solid transparent;
	border-top-color: $color-primary;
	border-bottom: 0;
	border-right: 0;
	margin-bottom: -1.4rem;
}

// Vue Transitionsbase
.info-text-enter-from,
.info-text-leave-to {
	visibility: hidden;
	opacity: 0;
}

.info-text-enter-active,
.info-text-leave-active {
	transition: all 0.3s;
}

:deep(.checkbox__label) {
	margin-left: 0.1rem;
}

.direct-flight__input-container--disabled {

	pointer-events: none;

	.direct-flight__info-text {
		color: $color-state-disabled;
	}

	.direct-flight__info-icon {
		fill: $color-state-disabled;
	}

	.direct-flight__info-text::after {
		border-color: $color-state-disabled;
	}
	:deep(.checkbox__label::before) {
		border-color: $color-state-disabled;
	}
	:deep(.checkbox__label) {
		color: $color-state-disabled;
	}
}

</style>
