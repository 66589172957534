<template>
	<svg
		id="new-tab"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 12.41 11.932"
	>
		<path
			id="Subtraction_1"
			data-name="Subtraction 1"
			d="M11.353,11.353H0V0H5.913V.946H.946v9.46h9.46V5.44h.946v5.913Z"
			transform="translate(0 0.579)"
		/>
		<path
			id="Path_2121"
			data-name="Path 2121"
			d="M8.919,8l2.386,2.386L7.019,14.671,8.451,16.1l4.286-4.285L15.124,14.2V8Z"
			transform="translate(-2.714 -8)"
		/>
	</svg>
</template>
