<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25.898"
		height="23.286"
		viewBox="0 0 25.898 23.286"
	>
		<path
			class="icon-store"
			d="M14.949,22.593V17.3H6.983v5.29ZM26.9,17.3H25.592V25.27H22.916V17.3h-5.29V25.27H4.307V17.3H3V14.627l1.306-6.66H25.592l1.306,6.66V17.3ZM25.592,3.984V6.661H4.307V3.984Z"
			transform="translate(-2.001 -2.984)"
			fill="none"
			stroke="#707070"
			stroke-linejoin="round"
			stroke-width="2"
		/>
	</svg>
</template>
