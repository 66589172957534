<template>
	<div class="departure-field">
		<template v-if="isDesktop">
			<DropdownField
				ref="dropdown"
				v-model="fieldValue"
				class="departure-field__dropdown"
				form-field-class="departure-field__field"
				label="Abflughafen"
				icon="flightStart"
				:wide="true"
				:max-travel-duration-error="maxTravelDurationError"
				@DropdownField:Ok="onOk"
				@DropdownField:Cancel="onReset"
				@DropdownField:OutsideClick="onOk"
				@DropdownField:Clear="onClear"
			>
				<DepartureFieldContent v-model="selectedItems" />
			</DropdownField>
		</template>

		<template v-else>
			<FormField
				:selected="fieldValue"
				:show-modal="showModal"
				:show-toggle-icon="false"
				icon="flightStart"
				label="Abflughafen"
				:disabled="maxTravelDurationError"
				@click="showModal = !showModal"
				@FormField:Clear="onClear"
			/>

			<Modal
				v-model="showModal"
				class="departure-field__modal"
				title="Abflughafen"
				@Modal:Ok="onOk"
				@Modal:Cancel="onReset"
			>
				<DepartureFieldContent v-model="selectedItems" />
			</Modal>
		</template>
	</div>
</template>

<script lang="ts" setup>
import {
	watch, ref, computed,
} from 'vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep } from '@utils/utils';
import { AirportDataType } from '@interfaces/search-form';
import { useStore } from '@/components/common/store';
import DepartureFieldContent from './DepartureFieldContent.vue';

const store = useStore();
const showModal = ref(false);
const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const departure = computed({
	get(): string[] {
		return store.state.searchMask?.departure || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', { departure: value });
	}
});

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const selectedItems = ref(cloneDeep(departure.value));

const isDesktop = computed(() => store.state.config.isDesktop);

const airports = computed((): AirportDataType[] => store.state.items.airports);

const getAirportLabel = (code: string): string => {
	const airport = airports.value.find(({ value }: any) => value === code);
	if (!airport) {
		return 'unknown airport';
	}

	return airport.label;
};

const fieldValue = computed(() => {
	if (!departure.value.length) {
		return '';
	}
	return departure.value.map(getAirportLabel).join(', ');
});

watch(() => departure.value, () => {
	selectedItems.value = cloneDeep(departure.value);
});

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onReset = () => {
	selectedItems.value = cloneDeep(departure.value);
	closeDropdown();
};

const onClear = () => {
	departure.value = [];
};

const onOk = (): void => {
	departure.value = cloneDeep(selectedItems.value) as string[];
	closeDropdown();
};

defineExpose({
	selectedItems,
});

</script>

<style lang="scss" scoped>
.departure-field {
	position: relative;

	:deep(.form-field__icon) {
		flex-shrink: 0;
		width: 3rem;
		margin-right: 1rem;
		fill: $color-primary;
	}

	.departure-field__modal-body {
		@include max-width(65rem);

		padding: 2rem 2.5rem;
	}

	.dropdown--wide {
		:deep(.dropdown__box) {
			width: 400%;
			max-width: 60rem;

			.departure-content__body {
				max-width: 75rem;
			}

			.departure-content__pill {
				margin-right: 0.7rem;
				border: 0.2rem solid $color-primary-l4;
			}
		}
	}
}
</style>
