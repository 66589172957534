/**
 * Adds specified number of days to YYYY-MM-DD ISO formated date.
 *
 * @param {string} date (date string)
 * @param {number} numOfDays (the number of days being added)
 * @param {boolean} format (format to YYYY-MM-DD or not)
 * @returns {string} formatedDate
 */
function addDays(date, numOfDays, format) {
	var newDate = new Date(date);
	newDate.setDate(newDate.getDate() + numOfDays);
	if (format) {
		return newDate.toISOString().slice(0, 10);
	}
	return newDate;
}

/**
 * Given a day in YYYY-MM-DD check if it is valid.
 *
 * @param {string} day
 * @returns {boolean}
 */
function isValidDate(day) {
	try {
		var t = new Date(day);
		return t.toISOString().slice(0, 10) === day;
	} catch (err) {
		return false;
	}
}

/**
 * Gets parts ot the base URL.
 *
 * @returns {Array} baseURLMatch e.g. ["edev6", "restplatzboerse", "at"]
 */
function getBaseURLParts() {
	const baseURLRegex = /^[^\/\/]*\/\/([^/]+)/g;
	let baseURLMatch;
	if (window.baseURL) {
		baseURLMatch = baseURLRegex.exec(window.baseURL);
	} else { // Fallback for storybook / jest
		baseURLMatch = baseURLRegex.exec('https://www.restplatzboerse.at/');
	}
	return baseURLMatch[1].split('.');
}
/**
 * VanillaJS version of jquery's .ready()
 *
 * @param {function} fn Callback function you want to fire
 */
function documentReady(fn) {
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else { // document.readyState === 'loading'
		document.addEventListener("DOMContentLoaded", fn);
	}
}

export {
	addDays,
	isValidDate,
	getBaseURLParts,
	documentReady,
};
