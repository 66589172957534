<template>
	<component
		:is="theme"
		v-model="switchModel"
		:draggable-aria-label="'Mode Switch Handle'"
		v-bind="$attrs"
	/>
</template>

<script lang="ts" setup>
import SearchModeSwitchSimple from '@lmt-rpb/SearchModeSwitchSimple/SearchModeSwitchSimple.vue';
import SearchModeSwitchMini from '@lmt-rpb/SearchModeSwitchMini/SearchModeSwitchMini.vue';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';

const store = useStore();

const isDesktop = computed(() => store.state.config.isDesktop);

interface Props {
	forceTheme?: string
}

const props = withDefaults(defineProps<Props>(), {
	forceTheme: ''
});

const switchModel = computed({
	get() {
		return store.state.searchMask.onlyHotel;
	},

	set(value: boolean) {
		store.commit('searchMask/updateFormData', { onlyHotel: value });
	}
});

const theme = computed(() => {
	if (props.forceTheme) {
		return ({
			simple: SearchModeSwitchSimple,
			mini: SearchModeSwitchMini,
		} as {[key: string]: any})[props.forceTheme];
	}

	return isDesktop.value ? SearchModeSwitchMini : SearchModeSwitchSimple;
});

</script>

<style lang="scss" scoped>
.search-switch {
	width: 100%;
	min-width: 10rem;
	max-width: 30rem;
}
</style>
