<template>
	<div
		v-if="feature"
		class="offer-feature"
		:class="{'offer-feature-hotellist': hotelListStyle, 'offer-feature-offerlist': !hotelListStyle}"
		@mouseover="showTooltip = true"
		@mouseleave="showTooltip = false"
	>
		<BaseIcon
			v-if="showShield"
			name="shield"
			class="offer-feature__shield"
		/>

		<span
			class="offer-feature__title"
		>
			{{ feature.title }}
			<InfoTooltip
				class="offer-feature__tooltip"
				:show-tooltip-parent="showTooltip"
			>
				{{ feature.description }}
			</InfoTooltip>
		</span>
	</div>
</template>

<script lang="ts" setup>

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { SingleFeature } from '@/interfaces/common';
import { ref } from 'vue';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';

interface Props {
	feature: SingleFeature,
	hotelListStyle?: boolean,
	showShield?: boolean,
}

withDefaults(defineProps<Props>(), {
	hotelListStyle: false,
	showShield: true,
});

const showTooltip = ref(false);

</script>

<style lang="scss" scoped>
.offer-feature {
	display: flex;
	gap: 0.5rem;
	align-items: first baseline;
	color: $color-green-text;
	font-weight: $bold-default;

	&__description {
		list-style: none;
		margin: 0;
		padding: 4px 8px;
	}

	&__tooltip {
		margin-left: 0.5rem;
	}

	&__shield {
		flex-shrink: 0;
		fill: $color-green-text;
		width: 1rem;
		height: 1rem;
		position: relative;
		bottom: 0.1rem;
	}
}

.offer-feature-offerlist {
	:deep(.info-tooltip__icon-container) {
		position: relative;
		top: 0.1rem;
	}

	.offer-feature__shield {
		display: inline;
		height: 1.2rem;
		width: 1.2rem;
	}
}

.offer-feature-hotellist {
	width: 100%;
	justify-content: center;
	background: $color-green-text;
	font-size: $font-very-small;
	color: $color-white;
	fill: $color-white;
	padding: 0.2rem 0;
	z-index: 2;
	gap: 1rem;

	.offer-feature__shield {
		display: none;
		fill: $color-white;
		width: 1.4rem;
		height: 1.4rem;
	}

	:deep(.info-tooltip__icon) {
		fill: $color-white;
		width: 1.4rem;
		height: 1.4rem;
	}

	:deep(.info-tooltip__tooltip) {
		background: $color-green-text;
	}
}

</style>
