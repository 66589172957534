<template>
	<button
		:class="{
			'filter-button' : true,
			'filter-button--no-results': !hasResults,
			'filter-button--with-filters': filterNumber > 0
		}"
		v-bind="$attrs"
		@click.prevent="openSearchmaskFilters"
	>
		<div class="filter-button__content">
			<base-icon
				class="filter-button__filter-icon"
				name="filter"
			/>
			<div class="filter-button__label-num">
				{{ label }}
				<span v-if="filterNumber > 0">({{ filterNumber }})</span>
			</div>
		</div>
	</button>
</template>

<script setup lang="ts">
import { EventBus } from '@global-js/event-bus';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

defineProps<{
	label: string
	filterNumber: number
	hasResults: boolean
}>();

const openSearchmaskFilters = (): void => {
	const searchButton = document.getElementById('rpb_header-icon-search');
	if (searchButton?.className !== 'rpb_header__search--open') {
		EventBus.$emit('offer-search:toggle');
	}
	const filterHeader = document.getElementById('theme-main-filters');

	setTimeout(() => {
		filterHeader?.scrollIntoView({
			behavior: 'smooth',
		});
	}, 100);
};
</script>

<style lang="scss" scoped>
.filter-button {
	border: 0.1rem solid #85E1EC;
	border-radius: 1.9rem;
	background: $color-white;
	color: $color-primary;
	width: 10.4rem;
	height: 5.6rem;
	font-weight: 600;
}

.filter-button--with-filters {
	border: 0.1rem solid $color-lime-light;
}

.filter-button--no-results {
	border: 0.1rem solid $color-warning;
}

.filter-button__content {
	text-align: left;
	font-weight: $bold-default;
	font-size: $font-very-small;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	letter-spacing: 0;
	gap: 0.3rem;
	font-family: $font-family;
}

.filter-button__label-num {
	display: inline;
}
.filter-button__filter-icon {
	width: 2.4rem;
	height: 2.0rem;
	fill: $color-primary;
}

</style>
