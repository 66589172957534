<template>
	<div
		class="collapse"
		:class="{'is-open': isOpen}"
	>
		<div
			class="collapse__header"
			:class="{'is-block': props.block !== false}"
			@click="toggle"
		>
			<slot
				name="header"
				:toggle="toggle"
				:is-open="isOpen"
			>
				<button
					v-if="props.label"
					type="button"
					class="collapse__button"
				>
					<span class="collapse__button-label">{{ props.label }}
						<span
							v-if="props.inlineBracketsText"
							class="collapse__button-inline-text"
						> ({{ props.inlineBracketsText }})</span>

					</span>
				</button>
			</slot>

			<slot
				name="icon"
			>
				<svg
					v-if="props.hideArrowIcon === false"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 27.563 15.267"
					class="collapse__icon"
				>
					<path
						d="M24.907 14.798L13.782 3.673 2.657 14.798a1.511 1.511 0 01-2.188 0 1.511 1.511 0 010-2.188L12.719.422a1.548 1.548 0 012.125 0l12.25 12.188a1.54714964 1.54714964 0 01-2.188 2.188z"
					/>
				</svg>
			</slot>
		</div>

		<transition
			v-if="!props.keepAlive"
			name="expand"
			@enter="enter"
			@after-enter="afterEnter"
			@leave="leave"
			@after-leave="afterLeave"
		>
			<div
				v-if="isOpen"
				id="is-open"
				ref="collapse"
				class="collapse__body"
			>
				<slot :toggle="toggle"></slot>
			</div>
		</transition>
		<transition
			v-else
			name="expand"
			@enter="enter"
			@after-enter="afterEnter"
			@leave="leave"
			@after-leave="afterLeave"
		>
			<div
				v-show="isOpen"
				id="keep-alive"
				ref="collapse"
				class="collapse__body"
			>
				<slot :toggle="toggle"></slot>
			</div>
		</transition>
	</div>
</template>
<!-- eslint-disable require-jsdoc -->
<!-- eslint-disable no-param-reassign -->
<script lang="ts" setup>
import { ref, watch } from 'vue';

interface ICollapseProps {
	open?: boolean
	label?: string
	inlineBracketsText?: string
	block?: boolean
	hideArrowIcon?: boolean
	duration?: number
	collapseHeadline?: string
	customHeight?: number
	customOffset?: number
	suppressClosingTransition?: boolean
	suppressCollapseOpen?: boolean
	keepAlive?: boolean
}

const props = withDefaults(defineProps<ICollapseProps>(), {
	open: false,
	block: false,
	label: '',
	inlineBracketsText: '',
	hideArrowIcon: false,
	duration: 250,
	collapseHeadline: '',
	customHeight: 0,
	customOffset: 0,
	suppressClosingTransition: false,
	suppressCollapseOpen: false,
	keepAlive: true,
});

const isOpen = ref(props.open);
const bodyHeight = ref('');
const collapse = ref<HTMLElement | null>(null);
const emit = defineEmits(['Collapse:Toggle']);
type CallBackFunction = () => void;

// // force repaint https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
const forceRepaint = ((el: HTMLElement) => {
	// eslint-disable-next-line
	const repaint = getComputedStyle(el).height;
});

watch(() => props.open, () => {
	isOpen.value = props.open;
});

watch(() => props.customHeight, () => {
	if (collapse.value) {
		const el = collapse.value;
		const viewportheight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

		el.style.height = props.customHeight
			? ((viewportheight * props.customHeight) + props.customOffset) + 'px'
			: bodyHeight.value;
		forceRepaint(el);
	}
});

const toggle = () => {
	if (props.suppressCollapseOpen) {
		return false;
	}

	isOpen.value = !isOpen.value;
	emit('Collapse:Toggle');
	return true;
};

const detectAndCacheDimensions = (el: HTMLElement) => {
	if (bodyHeight.value) {
		return;
	}

	const visibility = el.style.visibility;
	const display = el.style.display;

	// trick to get element height
	el.style.visibility = 'hidden';
	el.style.display = '';

	bodyHeight.value = `${el.offsetHeight}px`;
	el.style.visibility = visibility;
	el.style.display = display;
};

const enter = (el: HTMLElement, done: CallBackFunction) => {
	detectAndCacheDimensions(el);

	el.style.height = '0';
	el.style.overflow = 'hidden';

	forceRepaint(el);

	const viewportheight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

	el.style.transition = `height ${props.duration / 1000}s ease-out`;
	el.style.height = props.customHeight
		? ((viewportheight * props.customHeight) + props.customOffset) + 'px'
		: bodyHeight.value;
	// wait until transition ends to trigger @after-enter
	setTimeout(done, props.duration);
};

const leave = (el: HTMLElement, done: CallBackFunction) => {
	detectAndCacheDimensions(el);
	el.style.height = bodyHeight.value;
	el.style.overflow = 'hidden';

	forceRepaint(el);

	if (props.suppressClosingTransition) {
		el.style.transition = 'height 0s ease-out';
	} else {
		el.style.transition = `height ${props.duration / 1000}s ease-out`;
	}
	el.style.height = '0';

	setTimeout(done, props.duration);
};

const afterEnter = (el: HTMLElement) => {
	if (!props.customHeight) {
		el.style.height = '';
	}

	el.style.overflow = '';
	el.style.transform = '';

	bodyHeight.value = '';
};

const afterLeave = (el: HTMLElement) => {
	el.style.height = '';
	el.style.overflow = '';
	el.style.transition = '';

	bodyHeight.value = '';
};

</script>

<style lang="scss" scoped>
$transition: 0.25s;

.collapse__headline {
	text-align: left;
	padding: 3.2rem 0 2.4rem;
	padding: 2.4rem 0 0;
	border-top: 0.1rem solid $color-primary-l4;
	margin-top: 2.4rem;
	font-size: 2rem;
	font-family: $font-family;
	font-weight: 600;
	color: $color-text-l24;
	margin-bottom: 2.4rem;
}

.collapse {
	transition: color $transition ease-out;
	color: $color-black;
}

.collapse__header {
	display: flex;
	align-content: center;

	&.is-block {
		justify-content: space-between;
	}
}

.collapse__button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	border: none;
	background: none;
	outline: none;
	color: inherit;
	font-size: $font-large;
	text-align: left;
	cursor: pointer;
}

.collapse__icon {
	width: 2rem;
	height: 2rem;
	margin-left: 1rem;
	transform: rotate(180deg);
	transition: transform 0.1s ease-out;
	fill: currentcolor;
	vertical-align: middle;
}

.collapse.is-open {
	color: $color-primary;

	& > .collapse__header > .collapse__icon {
		transform: rotate(0deg);
	}
}
</style>
