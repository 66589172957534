<template>
	<svg
		width="21"
		height="20"
		viewBox="0 0 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.58984 16.6667L2.58984 14.1667V3.33334L7.58984 5.83334M7.58984 16.6667L12.5898 14.1667M7.58984 16.6667V5.83334M7.58984 5.83334L12.5898 3.33334M12.5898 14.1667L17.5898 16.6667V5.83334L12.5898 3.33334M12.5898 14.1667V3.33334"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
