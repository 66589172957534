<template>
	<div
		class="autocomplete"
		@focus.capture="onFocus"
	>
		<DropdownField
			ref="dropdown"
			v-model="term"
			tabindex="-1"
			class="autocomplete__dropdown"
			:label="label"
			:readonly="false"
			:manual="open"
			:close-on-esc="false"
			:show-footer="false"
			:show-toggle-icon="false"
			:placeholder="placeholderText"
			:parent="'autocomplete'"
			:icon="icon"
			:prevent-mousedown="false"
			:allow-clear="true"
			:max-travel-duration-error="errorDuration"
			@DropdownField:FieldClick="onFieldClick"
			@DropdownField:OutsideClick="onOutsideClick"
			@DropdownField:Focus="onFocus"
			@DropdownField:Blur="onBlur"
			@DropdownField:Navigate="onNavigate"
			@DropdownField:Clear="clearInput"
		>
			<div
				ref="list"
				class="autocomplete__list"
				role="group"
				aria-label="Ergebnisse"
				tabindex="-1"
			>
				<template v-if="loading">
					<!-- Loading -->
					<slot name="loading">
						<div class="autocomplete__load-box">
							<Loading
								size="small"
								class="autocomplete__loader"
							/>
						</div>
					</slot>
				</template>
				<template v-else-if="error">
					<!-- Error -->
					<slot
						name="error"
						:error="error"
						:term="term"
					>
						<div class="autocomplete__error">
							<p>Der Server ist im Augenblick nicht erreichbar.</p>
							Bitte versuchen Sie es in Kürze erneut.
						</div>
					</slot>
				</template>
				<template v-else>
					<template v-if="minCharCount && !Object.keys(items).length">
						<!-- No result -->
						<slot name="no-result">
							<div class="autocomplete__helper">
								Keine Ergebnisse gefunden.
							</div>
						</slot>
					</template>
					<template v-else>
						<slot name="result">
							<!-- Result list -->
							<ul
								ref="itemList"
								class="autocomplete__item-list"
							>
								<template
									v-for="(category, key) in items"
									:key="key"
								>
									<!-- Category name -->
									<li
										class="autocomplete__item-header"
										aria-hidden="true"
									>
										{{ minCharCount || key === 'searchHistory'? getCategoryName(`${key}`) : 'Beliebteste Destinationen' }}
									</li>

									<!-- Result item -->
									<template
										v-for="(destination, index) in category"
										:key="`${key}-${index}`"
									>
										<li
											v-if="isSearchFormDataType(destination)"
											ref="item"
											:key="`${key}-${index}`"
											:class="{'is-active': flattenedItemsIndex(key, index) === activeIndex }"
											role="button"
											class="autocomplete__item"
											@click="selectItem(destination)"
										>
											<div class="autocomplete__search-history-destination">
												{{ destination.destination?.label }}
											</div>
											<div class="autocomplete__search-history-departure">
												{{ getAirportLabels(destination) }}
											</div>
											<div class="autocomplete__search-history-person">
												{{ pluralize(destination.travelers.adult, "Erwachsener", "Erwachsene") }}
												{{ destination.travelers.children.length ? ', ' + pluralize(destination.travelers.children.length, 'Kind', 'Kinder') : '' }}
											</div>
											<div class="autocomplete__search-history-duration">
												{{ formatDateInterval(destination.offerDuration.from, destination.offerDuration.to) }},
												{{ getTravelDuration(destination.travelDuration) }}
											</div>
										</li>
										<li
											v-else
											ref="item"
											role="button"
											class="autocomplete__item"
											:class="{'is-active': flattenedItemsIndex(key, index) === activeIndex }"
											@click="selectItem(destination)"
										>
											{{ destination.Label }}
											<span class="autocomplete__item-sublabel">{{ destination.SubLabel }}</span>
										</li>
									</template>
								</template>
							</ul>
						</slot>
					</template>
				</template>
			</div>
		</DropdownField>
	</div>
</template>

<script lang="ts" setup>

import topPlacesData from '@json/topPlaces.json';
import axios, {
	AxiosError,
	Canceler,
	AxiosResponse,
} from 'axios';
import {
	debounce,
	isOfferlistPage,
	formatDateInterval,
	pluralize,
	getTravelDuration,
} from '@utils/utils';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import { SuggestionDataType, FullSuggest, SearchFormDataType } from '@interfaces/search-form';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeUnmount, onMounted, ref, watch, nextTick,
} from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import airportData from '@/components/common/store/items/airports';

const store = useStore();

interface Props {
	url: string,
	label: string,
	filter?: any,
	minLength?: number,
	inline?: boolean,
	icon?: string,
	modelValue?: any,
	errorDuration?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	filter: (data: any) => data,
	minLength: 2,
	inline: false,
	icon: '',
	modelValue: {},
	errorDuration: false,
});

const term = ref<string>('');

const searchTerm = ref<string>('');

const open = ref<boolean | undefined>(props.inline);

const items = ref<FullSuggest>({});

const loading = ref(false);

const error = ref<AxiosError | null>(null);

const abort = ref<Canceler>();

const activeIndex = ref(0);

const activeItem = ref<SuggestionDataType>({});

const focus = ref(false);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const formField = ref<InstanceType<typeof FormField> | null>(null);

const itemList = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const defaultAutosuggestsLoaded = ref(false);

const initialLoad = ref(true);

const emit = defineEmits(['update:modelValue']);

const minCharCount = computed((): boolean => !!term.value && term.value.length >= props.minLength);

const pageType = computed((): string => store.state.config.pageType);

const placeholderText = computed((): string => (!focus.value && 'Beliebig') || '');

const itemsArray = computed(() : SuggestionDataType[] => Object.keys(items.value).reduce((acc: SuggestionDataType[], cat: string) => {
	const category = (items.value as {[key: string]: SuggestionDataType[]})[cat];
	return [...acc, ...category];
}, []));

let requestAvailableHotels = true;

const getSearchHistory = async() => {
	loading.value = true;
	const searchHistory = await searchHistoryService.getThreeReversed(requestAvailableHotels);
	loading.value = false;
	requestAvailableHotels = false;
	return searchHistory;
};

const getDefaultItems = async(): Promise<FullSuggest> => {
	const searchHistory = await getSearchHistory();
	const defaultItems: FullSuggest = {
		searchHistory,
		RegionGroups: topPlacesData.RegionGroups as SuggestionDataType[],
	};
	if (!searchHistory.length) {
		delete defaultItems.searchHistory;
	}
	return defaultItems;
};

const getAirportLabels = (formData: SearchFormDataType): string => {
	let result = '';
	if (!formData.onlyHotel && formData.departure.length) {
		result = 'Flug ab ' + airportData.filter((airport) => formData.departure.includes(airport.value)).map((a) => a.label).join(', ');
	} else if (!formData.onlyHotel) {
		result = 'Inkl. Flug';
	} else {
		result = 'Eigene Anreise';
	}
	return result;
};

const onFocus = (): void => {
	focus.value = true;
};

const onBlur = (): void => {
	focus.value = false;
};

const onNavigate = (direction: number) => {
	nextTick(() => {
		const list = itemList.value as HTMLElement;

		if (!list) {
			return;
		}
		const itemsArr: HTMLElement[] = Array.from(list.querySelectorAll('.autocomplete__item'));
		activeIndex.value = Math.min(Math.max(0, activeIndex.value + direction), itemsArr.length - 1);
		const top = itemsArr[activeIndex.value].offsetTop;
		(list as HTMLElement).scrollTo({ top, behavior: 'smooth' });
	});
};

const flattenedItemsIndex = (actualKey: string, actualIndex: number): number => {
	let destinationCount = 0;

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, destinationArray] of Object.entries(items.value)) {
		if (key === actualKey) {
			destinationCount += actualIndex;
			break;
		}
		destinationCount += destinationArray ? destinationArray.length : 0;
	}

	return destinationCount;
};

const buildUrlWithId = (item: SuggestionDataType): string => {
	const type = item.Type || item.type;
	const id = item.ID || item.id;

	if (!id || !type) {
		return '';
	}

	let url = '';

	if (type === 'region_group') {
		url = `/region/g/${id}/`;
	} else if (type === 'region') {
		url = `/hotels/r/${id}/`;
	} else if (type === 'city') {
		url = `/hotels/o/${id}/`;
	} else {
		url = `/hotel/${id}/`;
	}

	return url;
};

const itemKeysToLowerCase = (item: SuggestionDataType): SuggestionDataType => {
	const lowerCaseItemKeys: SuggestionDataType = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(item)) {
		lowerCaseItemKeys[key.toLowerCase()] = value;
	}

	return lowerCaseItemKeys;
};

const getIdFromUrl = (): number => {
	if (!isOfferlistPage()) {
		return 0;
	}

	let id = null;

	// get id from location path
	const pathArray = window.location.pathname.split('/').filter((fraction) => fraction);
	const onlyNumbers = new RegExp('^[0-9]+$');

	pathArray.forEach((path) => {
		if (path.match(onlyNumbers)) {
			id = +path;
		}
	});

	return id || 0;
};

const getIdFromStore = (): number | false => {
	const regionTypeIds = {
		/* eslint-disable quote-props */
		'page_country': 'rgid',
		'page_region': 'rid',
		'page_city': 'cyid',
	};
	let destinationIdSelector = '',
		destinationId = 0;

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of Object.entries(regionTypeIds)) {
		if (document.body.classList.contains(key)) {
			destinationIdSelector = value;
		}
	}

	const regionString = store.state.config[destinationIdSelector];

	if (regionString) {
		destinationId = parseInt(regionString, 10);
		destinationId += destinationIdSelector === 'rgid' ? 40000 : 0; // add 40000 for region groups
	}

	return destinationId || false;
};

const updateBySearchHistoryEntry = (searchParams: SearchFormDataType) => {
	store.commit('searchMask/updateFormData', {
		destination: searchParams.destination,
		departure: searchParams.departure,
		travelDuration: searchParams.travelDuration,
		travelers: searchParams.travelers,
		offerDuration: searchParams.offerDuration,
		onlyHotel: searchParams.onlyHotel,
	});
};

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const closeDropdown = (): void => {
	open.value = false;
};

const commitTerm = (): void => {
	store.commit('searchMask/updateFormData', {
		searchTerm: searchTerm.value,
	});
};

const isSearchFormDataType = (item: SuggestionDataType | SearchFormDataType): item is SearchFormDataType => ((item as SearchFormDataType).destination !== undefined);

const selectItem = (item: SuggestionDataType | SearchFormDataType): void => {
	if (isSearchFormDataType(item)) {
		updateBySearchHistoryEntry(item);
	} else {
		// eslint-disable-next-line no-param-reassign
		item.url = buildUrlWithId(item);
		const transformedItem = itemKeysToLowerCase(item);

		model.value = transformedItem;
	}
	closeDropdown();

	if (isOfferlistPage() && store.state.config.isDesktop) {
		EventBus.$emit('search:submit');
	}
};

const clearTerm = (): void => {
	term.value = model.value?.label || null;
	activeItem.value = {};
};

const selectItemBy = (index: number): void => {
	if (Object.keys(items.value).length) {
		selectItem(itemsArray.value[index] || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectFirst = (): void => {
	if (minCharCount.value) {
		selectItemBy(activeIndex.value);
	}
};

const selectItemByLabel = (label: string | undefined): void => {
	if (!label) {
		return;
	}

	if (Object.keys(items.value).length) {
		const active = itemsArray.value.find((item) => item.Label === label);
		selectItem(active || itemsArray.value[0]);
		activeItem.value = active ? itemKeysToLowerCase(active) : itemKeysToLowerCase(itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectItemById = (id: number | false): void => {
	if (!id) {
		return;
	}

	if (Object.keys(items.value).length) {
		const currentDestination: SuggestionDataType | undefined = itemsArray.value.find((item) => item.ID === +id);
		if (currentDestination) {
			currentDestination.url = buildUrlWithId(currentDestination);
		}

		activeItem.value = currentDestination ? itemKeysToLowerCase(currentDestination) : itemKeysToLowerCase(itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

// Resetting to correct value
const resetTerm = (value: any): void => {
	model.value = value;
	term.value = value?.label || null;
};

const getCategoryName = (category: string): string => ({
	Countries: 'Land',
	Hotels: 'Hotel',
	Cities: 'Stadt',
	Regions: 'Region',
	RegionGroups: 'Destinationen',
	searchHistory: 'Zuletzt gesucht',
} as {[key: string]: string})[category];

const clearInput = async(): Promise<void> => {
	input.value?.focus();

	if (open.value) {
		items.value = await getDefaultItems();
	}
	if (term.value) {
		term.value = '';
	}
	if (model.value) {
		model.value = null;
	}
};
const search = (): void => {
	loading.value = true;
	error.value = null;

	const url = `${props.url}?term=${encodeURIComponent(term.value)}`;

	// if previous search still in progress abort it
	if (abort.value) {
		abort.value();
	}

	axios.get(url, {
		cancelToken: new axios.CancelToken((abortCanceler: Canceler) => {
			abort.value = abortCanceler;
		})
	})
		.then(({ data }: AxiosResponse) => data)
		.then((data: FullSuggest) => {
			const filteredItems: FullSuggest = {};
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(data)) {
				if (value && value.length) {
					filteredItems[key] = value;
				}
			}
			items.value = filteredItems;
		})
		.catch((err: AxiosError) => {
			// ignore abort error
			if (err instanceof axios.Cancel) {
				return;
			}

			error.value = err;
		})
		.finally(() => {
			loading.value = false;
			if (initialLoad.value) {
				initialLoad.value = false;
				// Setting the correct item as target destination on initial load
				// to set the URL if user doesn't change anything (on region and city pages)
				if (['regionPage'].indexOf(pageType.value) !== -1 && !document.body.classList.contains('page_country')) {
					selectItemById(getIdFromStore());
				}

				// Getting wrong rgid from typo3 data on some countries, e.g. Spain
				if (document?.body.classList.contains('page_country')) {
					selectItemByLabel(term.value);
				}

				// Get the correct destination (not just the label)
				// for list pages on mobile to prevent unnecessary redirects
				if (
					!store.state.config.isDesktop
						&& ['regionList', 'hotelList', 'hotelPage'].indexOf(pageType.value) !== -1
				) {
					selectItemById(getIdFromUrl());
				}
				if (activeItem.value?.id) {
					store.commit('searchMask/updateFormData', {
						destination: activeItem.value,
					});
					store.dispatch('updateProxies', { initialDestination: activeItem.value });
					EventBus.$emit('Autocomplete:updatedActiveItem');
				}
			}

			if (activeItem.value?.id) {
				if (Object.keys(items.value).length) {
					const isCurrentDestination = (element: SuggestionDataType) => element.ID === activeItem.value?.id;
					activeIndex.value = itemsArray.value.findIndex(isCurrentDestination);
				}
			} else {
				activeIndex.value = 0;
			}
		});
};

const onTermChange = (): void => {
	error.value = null;
	if (!minCharCount.value) {
		getDefaultItems().then((defaultItems) => { items.value = defaultItems; });
	} else {
		search();
	}
};

const openDropdown = (): void => {
	onTermChange();
	open.value = true;
};

const onKeydown = (e: KeyboardEvent): void => {
	const limited = ['Tab', 'Shift'];

	if (!props.errorDuration) {
		if (!open.value && focus.value && limited.indexOf(e.key) === -1) {
			openDropdown();
			input.value?.focus();
		} else if (open.value && ['Enter', 'Tab'].indexOf(e.key) !== -1) {
			selectItemBy(activeIndex.value);
			closeDropdown();
		}
	}
};

const onInput = (): void => {
	if (input.value?.value && input.value.value !== '') {
		searchTerm.value = input.value.value;
		term.value = input.value.value;
	}
	if (input.value?.value === '') {
		store.commit('searchMask/updateFormData', { destination: null });
	}
	commitTerm();
	onTermChange();
};

const onFieldClick = (clickOnField: boolean) => {
	if (clickOnField && !open.value && !props.errorDuration) {
		input.value?.focus();
		openDropdown();
	}
};

const onOutsideClick = (): void => {
	selectFirst();
	closeDropdown();
};

watch(() => model.value, () => {
	if (model.value && model.value.label) {
		term.value = model.value.label;
	}
}, { immediate: true });

onMounted((): void => {
	formField.value = dropdown.value?.field as any;
	input.value = formField.value?.input as HTMLInputElement;

	document.addEventListener('keydown', onKeydown);
	input.value?.addEventListener('input', debounce(onInput, 400));
	if (['regionPage', 'hotelPage'].indexOf(pageType.value) !== -1) {
		if (!defaultAutosuggestsLoaded.value) {
			search();
			defaultAutosuggestsLoaded.value = true;
		}
		EventBus.$on('loadDefaultAutosuggests', search);
	}
});

onBeforeUnmount((): void => {
	document.removeEventListener('keydown', onKeydown);
	input.value?.removeEventListener('input', onInput);

	if (['regionPage', 'hotelPage'].indexOf(pageType.value) !== -1) {
		EventBus.$off('loadDefaultAutosuggests', search);
	}
});

defineExpose({
	selectFirst,
	resetTerm,
	clearInput,
	openDropdown,
	input,
	open,
	dropdown
});

</script>

<style lang="scss" scoped>
.autocomplete {
	&__search-history-destination {
		font-weight: $bold-default;
		font-size: $font-small;
		color: $color-text-light;
		margin-bottom: 0.2rem;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.is-active &__search-history-destination {
		color: white;
	}

	.is-active:hover &__search-history-destination {
		color: $color-text-light;
	}

	&__search-history-departure,
	&__search-history-duration,
	&__search-history-person {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: $font-very-small;
		font-weight: $bold-default;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.autocomplete__icon {
		flex-shrink: 0;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}

	// dropdown style
	.autocomplete__dropdown {
		max-width: 50rem;
		margin: 0 auto;

		.dropdown-field__field::after {
			display: none;
		}

		.dropdown-box {
			margin-top: 0;
			border-top: 0;
		}
	}

	.autocomplete__helper {
		position: relative;
		padding: 3rem 2rem;
	}

	.autocomplete__error {
		padding: 2rem;
		font-size: 1.8rem;
		font-weight: 600;
	}

	.autocomplete__load-box {
		position: relative;
		padding: 3.75rem 2rem;
	}

	.autocomplete__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-75%, -50%, 0);
	}

	.autocomplete__list {
		overflow: auto;
	}

	.autocomplete__item-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.autocomplete__item-header {
		margin: 2rem 0 1rem;
		padding-left: 3rem;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: left;
	}

	.autocomplete__item {
		display: block;
		width: 100%;
		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: 1.6rem;
		font-weight: 600;
		text-align: left;
		text-decoration: none;

		&.is-active {
			background: $color-destion-field-active-background;
			color: $color-white;
		}

		&:hover {
			background: $color-primary-l6;
			color: $color-text-l24;
		}
	}

	.autocomplete__item-sublabel {
		display: block;
		margin-top: 0.5rem;
		font-size: 1.2rem;
		font-weight: normal;
	}

	.autocomplete__empty-item {
		@extend .autocomplete__item;

		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
	}

	.clear-icon {
		position: absolute;
		width: 1.2rem;
		height: 1.2rem;
		margin-bottom: 1.1rem;
		fill: $color-dark-gray;
		top: 1.2rem;
		right: 0.6rem;
	}

	.clear-icon__container {
		position: absolute;
		width: 3rem;
		height: 90%;
		bottom: 0.1rem;
		right: 0.7rem;
		background-color: rgba($color-white, 0.5);
	}

	@include media-query-up($breakpoint-container) {
		.clear-icon {
			margin-top: 1.9rem;
			margin-bottom: 0;
			fill: $color-primary;
		}

		.autocomplete__dropdown {
			max-width: none;
			margin: 0;
		}
	}
}
</style>
