<template>
	<svg
		id="euro"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
	>
		<g
			id="Ebene_1"
			data-name="Ebene 1"
			transform="translate(0.5 0.5)"
		>
			<g
				id="Gruppe_342"
				data-name="Gruppe 342"
			>
				<circle
					id="Ellipse_9"
					data-name="Ellipse 9"
					cx="9.5"
					cy="9.5"
					r="9.5"
					fill="none"
					stroke="#16828F"
					stroke-miterlimit="10"
					stroke-width="0.75"
				/>
				<path
					id="Pfad_2119"
					data-name="Pfad 2119"
					d="M14.761,7.987c-1.318-.533-2.472-.034-3.476,1.5l3.169,1.277-.321.8-3.251-1.311-.157.348-.164.4-.1.28,2.868,1.161-.321.8-2.773-1.12a3.531,3.531,0,0,0,.15,2.049,2.486,2.486,0,0,0,1.448,1.284,5.184,5.184,0,0,0,2.083.369l-.41.956a5.177,5.177,0,0,1-2.131-.389A3.572,3.572,0,0,1,9.332,14.6a4.555,4.555,0,0,1-.15-2.9L8.15,11.286l.328-.8.942.376.089-.266.109-.273.212-.492-.942-.382.328-.8,1.011.41a4.658,4.658,0,0,1,2.165-2.049,3.469,3.469,0,0,1,2.732.048,4.719,4.719,0,0,1,2.049,1.523l-.785.683A4.385,4.385,0,0,0,14.768,8Z"
					transform="translate(-2.925 -2.483)"
					fill="#16828F"
				/>
			</g>
		</g>
	</svg>
</template>
