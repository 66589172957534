<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
	>
		<g
			id="Gruppe_2919"
			data-name="Gruppe 2919"
			transform="translate(-21.667 -319.318)"
		>
			<g
				id="Gruppe_2921"
				data-name="Gruppe 2921"
			>
				<g
					id="page-blank-svgrepo-com"
					transform="translate(45.828 324.818)"
				>
					<path
						class="icon-pages"
						data-name="Pfad 4643"
						d="M49.085,9.123A.517.517,0,0,0,49.06,9c-.006-.017-.011-.033-.018-.051a.5.5,0,0,0-.1-.147h0L40.293.149a.507.507,0,0,0-.149-.1A.47.47,0,0,0,40.1.033a.476.476,0,0,0-.133-.027c-.011,0-.021-.007-.031-.007H34.219a3.384,3.384,0,0,0-3.38,3.38V20.62A3.384,3.384,0,0,0,34.219,24H45.712a3.384,3.384,0,0,0,3.38-3.38V9.157C49.092,9.145,49.086,9.135,49.085,9.123ZM40.441,1.73l6.92,6.92H42.807a2.369,2.369,0,0,1-2.366-2.366S40.441,1.73,40.441,1.73Zm5.271,21.256H34.219a2.369,2.369,0,0,1-2.366-2.366V3.38a2.369,2.369,0,0,1,2.366-2.367h5.208v5.27a3.384,3.384,0,0,0,3.38,3.38h5.271V20.62A2.369,2.369,0,0,1,45.712,22.986Z"
						transform="translate(-52 -5)"
						fill="#707070"
						stroke="#707070"
						stroke-width="1"
					/>
				</g>
				<rect
					id="Rechteck_2370"
					data-name="Rechteck 2370"
					width="24"
					height="24"
					transform="translate(21.667 319.818)"
					fill="none"
				/>
			</g>
		</g>
	</svg>
</template>
