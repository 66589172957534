import { DEFAULT_DURATION_FILTER } from '@global-js/constants';
import { SearchFormDataType } from '../../interfaces/search-form';

/** returns true if hotel page */
function isHotelPage() : boolean {
	if (document.querySelector('.page_hotel')) return true;
	return false;
}
/**  Returns number of all search filters */
export function getFilterCount(data : SearchFormDataType) : number {
	let counter = 0;

	counter += data?.departure && data?.departure?.length > 0 ? 1 : 0;
	counter += JSON.stringify(data?.travelDuration) !== JSON.stringify(DEFAULT_DURATION_FILTER) ? 1 : 0;
	counter += !isHotelPage() && data?.destinationTypeName && data?.destinationTypeName !== '' ? 1 : 0;
	counter += data?.directFlight ? 1 : 0;
	counter += data?.transferTypes?.length || 0;
	counter += data?.boardTypes?.length || 0;
	counter += data?.roomTypes?.length || 0;
	counter += data?.roomViews?.length > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.hotelCategory && data?.hotelCategory > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.minMeanRecommendationRate && data?.minMeanRecommendationRate > 0 ? 1 : 0;
	counter += !isHotelPage() && data?.maxPrice && data?.maxPrice <= 1500 ? 1 : 0;
	counter += (!isHotelPage() && data?.mostPopularFilters?.hotelAttributes?.length) || 0;
	counter += (!isHotelPage() && data?.mostPopularFilters?.ratingAttributes?.length) || 0;
	counter += (!isHotelPage() && data?.additionalCheckboxFilters?.ratingAttributes?.length) || 0;
	counter += data?.operatorTypes && data?.operatorTypes?.length > 0 ? 1 : 0;

	return counter;
}
