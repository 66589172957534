<template>
	<div
		class="search-form"
		:style="[showBackground ? bgImages : {backgroundImage: 'none'}]"
		:class="{
			'show-background': showBackground,
			'search-form--standalone': (!isHeaderSearch && ['themePage', 'regionPage', 'hotelList', 'hotelPage', 'regionList'].indexOf(pageType) !== -1),
		}"
	>
		<Loading
			v-if="redirecting"
			class="search-form__loading"
		/>
		<div class="search-form__search">
			<ThemeMain
				v-show="showThemeMain"
				ref="main"
				class="search-form__theme"
				:show-close-button="showCloseButton"
				@ThemeMain:Submit="dynamicSubmit"
				@ThemeMain:Cancel="toggleTheme(false, true, true)"
			/>
			<ThemeMinimal
				v-if="!desktopContentFlowSearch()"
				v-show="showThemeMini"
				class="search-form__theme"
				@ThemeMinimal:Edit="toggleTheme(true, false, false)"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ThemeMain from '@lmt-rpb/ThemeMain/ThemeMain.vue';
import ThemeMinimal from '@lmt-rpb/ThemeMinimal/ThemeMinimal.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import { SearchFormDataType, BackgroundImages } from '@interfaces/search-form';
import { objectToQuery, formToParams } from '@services/transform';
import { EventBus } from '@global-js/event-bus';
import {
	isOfferlistPage,
	showOfferlist,
	getBasePath,
} from '@utils/utils';
import {
	computed, onBeforeMount, onBeforeUnmount, onMounted, ref,
} from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';

interface Props {
	client?: 'at' | 'ch' | 'com' | 'kuat' | 'lmat',
	images?: BackgroundImages | undefined,
	isHeaderSearch?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	client: 'at',
	images: undefined,
	isHeaderSearch: false,
});

const store = useStore();

const showThemeMini = ref(false);

const showThemeMain = ref(false);

const showCloseButton = ref(true);

const showBackground = ref(false);

const redirecting = ref(false);

const verticalScrollPosition = ref(0);

const headerIsHidden = ref(false);

const isMobile = computed((): boolean => store.state.config.isMobile);

const baseUrl = computed((): string => store.state.config.baseUrl);

const pageType = computed((): string => store.state.config.pageType);

const formData = computed((): SearchFormDataType => store.state.searchMask);

const locationType = computed((): string => store.state.config.locationType);

const isDesktop = computed(() => store.state.config.isDesktop);

const targetUrl = computed((): string => {
	let url = store.state.config.searchUrl; // default url
	if (formData.value.destination && formData.value.destination.url) {
		url = `${baseUrl.value}${formData.value.destination.url}`;
	} else {
		url = `${baseUrl.value}/region/`;
	}

	return url;
});

const basePath = computed((): string => getBasePath(props.client) + '/img/search-form');

const getBackgroundStyleProp = (images: BackgroundImages, backgroundSettings: string): Record<string, string> => {
	const { mobile, tablet, desktop } = images;

	if (isDesktop.value) {
		return { background: `url('${desktop}') ${backgroundSettings}` };
	}
	if (isMobile.value) {
		return { background: `url('${mobile}') ${backgroundSettings}` };
	}
	return { background: `url('${tablet}') ${backgroundSettings}` };
};

const handleFallbackImages = (backgroundSettings: string): Record<string, string> => {
	const images = {
		desktop: `${basePath.value}/main-image-desktop.jpg`,
		tablet: `${basePath.value}/main-image-tablet.jpg`,
		mobile: `${basePath.value}/main-image-mobile.jpg`,
	};

	return getBackgroundStyleProp(images, backgroundSettings);
};

const bgImages = computed((): Record<string, string> => {
	const backgroundPositionX = isMobile.value ? '20%' : 'center';
	const backgroundSettings = `${backgroundPositionX} 70%/cover no-repeat`;

	if (!props.images) return handleFallbackImages(backgroundSettings);
	const { mobile, tablet, desktop } = props.images;

	// In case bg images are missing after edev cloning
	// TODO: remove after edevs are gone
	if (!desktop || !tablet || !mobile) return handleFallbackImages(backgroundSettings);

	return getBackgroundStyleProp(props.images, backgroundSettings);
});

const selectedDestinationChanged = computed((): boolean => JSON.stringify(formData.value.destination) !== JSON.stringify(store.state.proxies?.initialDestination));

const onSubmit = (noLoadingSpinner: boolean = false): void => {
	searchHistoryService.update();
	const body = store.getters['searchMask/submitData'];
	const params = formToParams(body, 'search');

	if (locationType.value === 'COUNTRY' && params.rid) {
		delete params.rid;
	}
	if (locationType.value === 'TOPREGION' && params.cyid) {
		delete params.cyid;
	}

	const query = objectToQuery(params);

	const searchTerm = formData.value.searchTerm;
	const travelType = formData.value.onlyHotel ? 'hotel' : 'package';
	let url = '';

	url = `${targetUrl.value}?${query}`
			+ (searchTerm
				? `&term=${encodeURIComponent(searchTerm)}`
				: '');

	// When redirecting to our own hotel pages, add the ibe parameter
	if (url.indexOf('ibe=') === -1
				&& url.indexOf('/hotel/') !== -1) {
		url += (url.indexOf('?') !== -1) ? '&' : '?';
		url += 'ibe=' + travelType;
	}

	if (!store.state.config.redirectOnSubmit) {
		console.log('redirect: ', url);
	} else if (noLoadingSpinner) {
		window.location.href = url;
	} else {
		document.body.style.opacity = '0.3';
		redirecting.value = true;
		window.location.href = url;
	}
};

const handlePopState = ({ state }: PopStateEvent): void => {
	EventBus.$emit('window:popstate', state);
};

const handleKeydown = ({ key }: KeyboardEvent): void => {
	if (!key) {
		return;
	}

	const keyName = key.toLowerCase();

	if (['enter', 'escape'].includes(keyName)) {
		EventBus.$emit(`keydown.${keyName}`);
	}
};

const handlePageRedirect = (): void => {
	redirecting.value = false;
	document.body.style.opacity = '1';
};

const desktopContentFlowSearch = (): boolean => (pageType.value === 'regionList' || pageType.value === 'hotelList' || pageType.value === 'hotelPage') && isDesktop.value;

const toggleTheme = (delay = false, close = false, cancel = false): boolean => {
	if (close && cancel) {
		// On search cancel use the saved state to rollback to the old current state
		store.dispatch('searchMask/rollbackToSavedCurrentState');
	} else {
		// On theme open save the current state
		store.dispatch('searchMask/saveCurrentState');
	}
	const searchButton = document.getElementById('rpb_header-icon-search');
	if (props.isHeaderSearch) {
		if (document.body.classList.contains('search-delay')) {
			document.body.classList.remove('search-delay');
		}

		if (delay) {
			document.body.classList.add('search-delay');
		}
		if (document.body.classList.contains('page-header--hidden')) {
			headerIsHidden.value = true;
			document.body.classList.remove('page-header--hidden');
		} else if (headerIsHidden.value) {
			headerIsHidden.value = false;
			document.body.classList.add('page-header--hidden');
		}

		showThemeMain.value = !showThemeMain.value;
		if (pageType.value === 'hotelPage'
				|| pageType.value === 'hotelList'
				|| pageType.value === 'regionList') {
			showThemeMini.value = !showThemeMini.value;
		}
		if (document.body.classList.contains('search-open')) {
			document.body.classList.remove('search-open');
			searchButton?.classList.remove('rpb_header__search--open');
		} else {
			document.body.classList.add('search-open');
			searchButton?.classList.add('rpb_header__search--open');
		}

		if (pageType.value !== 'hotelPage'
				&& pageType.value !== 'hotelList'
				&& pageType.value !== 'regionList') {
			if (!document.body.classList.contains('hide-content')) {
				verticalScrollPosition.value = window.scrollY;
				document.body.classList.add('hide-content');
			} else {
				document.body.classList.remove('hide-content');
				window.scroll(0, verticalScrollPosition.value);
				// Add class so the scrollEvent in header.ts doesn't hide the header
				document.body.classList.add('autoscroll');
			}
		}
	}
	return true;
};

const dynamicSubmit = (): void => {
	if (isOfferlistPage() && !selectedDestinationChanged.value) {
		EventBus.$emit('FilterTagList:SetFormData');
		EventBus.$emit('SearchMask:MobileSubmit');
		toggleTheme(false, true, false);

		if (pageType.value === 'hotelPage') {
			if (isDesktop.value) {
				showOfferlist('#search-form-container .theme-main', true, 50, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 2000);
			} else {
				showOfferlist('.offers__list', false, 200, 1000);
				setTimeout(() => {
					document.body.classList.add('page-header--hidden');
				}, 200);
			}
		} else if (pageType.value === 'hotelList') {
			showOfferlist('.hotel-list', false, 150, 1000);
		} else if (pageType.value === 'regionList') {
			showOfferlist('.region-list', false, 150, 1000);
		}
	} else {
		onSubmit();
	}
};

onBeforeMount(() => {
	window.addEventListener('popstate', handlePopState);
	document.addEventListener('keydown', handleKeydown);

	// Prevents endless loading spinner if user clicks back fast from ibe on >= iOS 13
	window.addEventListener('pageshow', handlePageRedirect);

	EventBus.$on('search:submit', (buttonSearch = false) => {
		// redirectFired boolean to hide the second loader
		if (!store.state.searchMask.redirectFired || buttonSearch) {
			onSubmit(buttonSearch);
		}
		if (!buttonSearch) {
			// dont set this value when buttonSearch. When Browser back it wouldn't fire anymore.
			store.state.searchMask.redirectFired = true;
		}
	});
});

const addThemeMiniMarginClass = () => {
	document.body.classList.add('show-theme-mini-margin');
};

onMounted(() => {
	EventBus.$on('offer-search:toggle', toggleTheme);
	if ((pageType.value === 'hotelPage' && props.isHeaderSearch)
			|| (pageType.value === 'hotelList' && props.isHeaderSearch)
			|| (pageType.value === 'regionList' && props.isHeaderSearch)) {
		showThemeMini.value = true;
		showCloseButton.value = true;
		addThemeMiniMarginClass();
	} else if (pageType.value === 'homePage') {
		if (!props.isHeaderSearch) {
			showThemeMain.value = true;
			showBackground.value = true;
		}
	} else if (pageType.value === 'regionPage'
			|| pageType.value === 'themePage'
			|| pageType.value === 'hotelPage'
			|| pageType.value === 'hotelList'
			|| pageType.value === 'regionList') {
		if (!props.isHeaderSearch) {
			showThemeMain.value = true;
		}
	}

	store.dispatch('updateProxies', { initialDestination: formData.value.destination });
});

onBeforeUnmount((): void => {
	window.removeEventListener('popstate', handlePopState);
	document.removeEventListener('keydown', handleKeydown);
	window.removeEventListener('pageshow', handlePageRedirect);
	EventBus.$off('OfferSearch:Toggle', toggleTheme);

	EventBus.$off('search:submit', onSubmit);
});

defineExpose({
	showThemeMain,
	showThemeMini,
});
</script>

<style lang="scss" scoped>
.search-form {

	color: black;
	position: relative;
	scrollbar-color: $color-primary transparent;
	scrollbar-width: thin;
	-webkit-tap-highlight-color: transparent;

	::-webkit-scrollbar {
		width: 1.2rem;
	}

	::-webkit-scrollbar-thumb {
		border-radius: $border-radius-small;
		background-color: $color-primary;
	}

	&__search {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
	}

	&__upper {
		width: 100%;
		display: flex;
		min-height: 13.4rem;
		justify-content: center;
		align-items: flex-end;
		margin: 1.6rem 0;

		@media (min-width: $breakpoint-verysmall) {
			margin-top: 3rem;
		}

		@media (min-width: $breakpoint-small) {
			margin-top: unset;
			margin-bottom: 2rem;
		}

		@media (min-width: $breakpoint-extralarge) {
			margin-bottom: 6rem;
		}
	}

	// is home page
	&.show-background {
		display: flex;
		position: relative;
		align-content: center;
		justify-content: center;
		height: 55rem;
		padding: 2rem 0.5rem;

		@media (min-width: $breakpoint-verysmall) {
			height: 75.3rem;
		}

		@include media-query-up($breakpoint-extralarge) {
			justify-content: center;
			align-items: center;
			height: 32rem;
		}
	}

	.search-form__loading {
		position: absolute;
		top: 50%;
		z-index: 100;
		transform: translateY(-31%);
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}

	.search-form__theme {
		position: relative;
		min-height: 6.3rem;
	}
}

.slide-fade-enter-active {
	transition: opacity 0.5s;

	@include media-query-up($breakpoint-extralarge) {
		transition: opacity 0.3s;
		transition-delay: 0.2s;
	}
}

.slide-fade-enter-from {
	opacity: 0;
}

</style>
