<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10"
		height="18"
		viewBox="0 0 10 18"
	>
		<path
			id="menu-left"
			d="M9.663,3.954,2.4,11.219l7.265,7.265a.987.987,0,0,1,0,1.429.939.939,0,0,1-1.388,0l-8-8a.939.939,0,0,1,0-1.388l8-8a.939.939,0,0,1,1.388,0,.987.987,0,0,1,0,1.429Z"
			transform="translate(0.031 -2.219)"
		/>
	</svg>
</template>
