<template>
	<svg
		class="bookmark-hotel-button__svg"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		width="32"
		height="32"
		viewBox="0 0 27 32"
	>
		<path d="M13.813 29.063l-0.375-0.313-0.438-0.375-0.625-0.438-0.563-0.5-0.5-0.438-0.875-0.75-0.563-0.438-0.688-0.625-0.625-0.563-2.125-2.125-0.625-0.688-1.25-1.25-0.688-0.813-0.563-0.75-0.688-0.75-0.438-0.688-0.563-0.813-0.563-0.875-0.438-0.938-0.313-0.938-0.188-0.938-0.125-0.875v-1.5l0.063-0.75 0.25-1.125 0.25-0.75 0.25-0.563 0.25-0.625 0.438-0.75 0.688-0.875 0.688-0.688 1.125-0.813 0.875-0.438 1-0.313 0.625-0.125h0.438l1.438 0.063 1.375 0.375 0.875 0.5 0.75 0.5 0.625 0.563 0.75 0.75 0.5 0.688 0.25 0.563h0.125l0.125-0.25 0.125-0.313 0.25-0.313 0.375-0.563 0.375-0.438 0.438-0.438 0.563-0.5 0.75-0.5 0.75-0.313 0.875-0.375 1.188-0.188h0.688l1.125 0.125 1.125 0.375 0.813 0.438 1.188 0.813 0.75 0.875 0.813 1.125 0.563 1.125 0.5 1.438 0.25 1.5-0.125 2.063-0.063 0.75-0.313 1-0.25 0.688-0.938 1.688-0.688 1.063-0.813 1.063-0.75 0.875-0.938 1.188-0.813 0.938-0.875 0.875-1.313 1.375-0.75 0.75-1 0.938-1.438 1.375-1.5 1.25z" />
	</svg>
</template>
