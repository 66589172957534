<template>
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M29.2667 18.1052C28.4183 18.4416 27.782 18.4855 24.6152 18.5294L22.2968 18.5659L12.9501 31.2184H10.0758L9.60776 31.1819L9.57119 31.0502L9.52731 31.0137C9.35179 30.8454 9.29328 30.5895 9.38835 30.3701C9.42492 30.3262 9.60045 29.9093 11.9189 24.4534L14.5883 18.2295C12.7745 18.1052 8.41565 17.9808 7.10651 17.937L6.22157 17.8931L3.50823 21.7766H2.41119C1.65058 21.7766 1.3946 21.7766 1.18251 21.5718C1.11669 21.5133 1.07281 21.4402 1.05818 21.3597C0.970414 21.1842 0.970414 21.1037 1.13863 20.2627C1.21908 19.758 1.3946 19.1583 1.51893 18.5294L2.03089 16.1598L1.51893 13.8852C1.27027 13.0222 1.10206 12.1446 1.00698 11.2597V10.5795H2.36C3.12792 10.5795 3.29614 10.6673 3.50823 10.7916L3.5448 10.755L3.58868 10.7916C3.71301 10.9232 4.64915 12.2762 5.02946 12.8248L6.21426 14.4338C6.76277 14.4703 10.1051 14.346 11.246 14.3021C12.2187 14.2582 13.7326 14.1705 14.5883 14.09C14.2519 13.249 13.484 11.384 12.1748 8.38542C11.3338 6.43269 10.617 4.86758 10.1417 3.71935C9.92956 3.17083 9.71746 2.71007 9.59313 2.41022C9.53462 2.30783 9.50537 2.19081 9.51268 2.07379C9.47612 1.94946 9.3079 1.61304 9.56388 1.36437C9.73209 1.18885 9.94418 1.15228 10.3684 1.14497C10.617 1.14497 10.9096 1.1084 11.3411 1.1084H12.9428L22.37 13.834H24.8273C27.7893 13.834 27.8698 13.834 28.7986 14.0827C29.4349 14.2509 30.0127 14.5727 30.4954 15.0115C30.8172 15.326 31 15.7502 31 16.1963C31 17.4689 29.6909 17.937 29.2667 18.0979V18.1052Z"
			stroke="currentColor"
			stroke-width="2"
		/>
	</svg>
</template>
