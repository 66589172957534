<template>
	<div class="checkout-hotel-info-gallery">
		<Carousel
			ref="scroll"
			v-bind="carouselSettings"
			class="checkout-hotel-info-gallery__carousel"
		>
			<Slide
				v-for="(image, index) in images"
				:key="index"
				:index="index"
				class="checkout-hotel-info-gallery__slide"
			>
				<img
					class="checkout-hotel-info-gallery__image rpb_lazy"
					data-sizes="auto"
					:data-src="image.URL"
					src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
					:alt="`${image.Caption} - ${index}`"
					@click="openGallery(index)"
					@mousedown="onMouseDown"
					@mouseup="onMouseUp"
					@mousemove="onMouseMove"
				/>
			</Slide>
			<template #addons>
				<CarouselNavigation>
					<template #prev>
						<span class="checkout-hotel-info-gallery__aria-label">prev</span>
						<BaseIcon
							name="chevron"
							class="checkout-hotel-info-gallery__prev-icon"
						/>
					</template>
					<template #next>
						<span class="checkout-hotel-info-gallery__aria-label">next</span>
						<BaseIcon
							name="chevron"
							class="checkout-hotel-info-gallery__next-icon"
						/>
					</template>
				</CarouselNavigation>
			</template>
		</Carousel>
		<GalleryModal
			v-model="isModalVisible"
			:images="imageArray"
			:index="modalIndex"
			:client="client"
			@close="closeGallery"
		/>
	</div>
</template>

<script lang="ts" setup>
import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';
import GalleryModal from '@lmt-rpb/GalleryModal/GalleryModal.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { HotelCatalogueImageType } from '@/interfaces/hotel-catalogue-types';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<{
	images: any,
	client?: string
}>(), {
	client: 'at'
});

const scroll = ref();

const isMouseDown = ref(false);

const isDragging = ref(false);

const isModalVisible = ref(false);

const modalIndex = ref(0);

const carouselSettings = {
	itemsToShow: 1.5,
	itemsToSlide: 1,
	snapAlign: 'start',
	breakpoints: {
		768: { // breakpoint-small
			itemsToShow: 1.5,
		}
	}
};

const imageArray = computed((): HotelCatalogueImageType[] => {
	const imagesArr: HotelCatalogueImageType[] = [];
	const imagesObj = props.images;

	Object.entries(imagesObj).map((e) => (imagesArr.push(e[1] as HotelCatalogueImageType)));

	return imagesArr;
});

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (): void => {
	setTimeout(() => {
		isMouseDown.value = false;
		isDragging.value = false;
	}, 300); // click tilt time
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const openGallery = (index: number): void => {
	if (isDragging.value) {
		return;
	}

	modalIndex.value = Number(index) - 1;
	isModalVisible.value = true;
};

const closeGallery = (): void => {
	if (isDragging.value) {
		return;
	}

	isModalVisible.value = false;
};
</script>

<style lang="scss" scoped>
@import '~vue3-carousel/dist/carousel.css';

.checkout-hotel-info-gallery {
	position: relative;
	background-color: $color-page-background;

	&__image {
		object-fit: cover;
		max-width: 100%;
		height: 100%;
	}

	&__slide {
		position: relative;
		cursor: pointer;
		padding-right: 5px;
		height: 100%;
		display: inline-block;
	}

	&__carousel {
		height: 28.5rem;

		@media screen and (min-width: $breakpoint-small) {
			height: 33.5rem;
		}

		.checkout-hotel-info-gallery__slide {
			position: relative;
			cursor: pointer;
			height: 100%;
			display: inline-block;
		}

		:deep(.carousel__viewport) {
			width: 100%;
			height: 100%;
		}

		:deep(.carousel__track) {
			height: 100%;
			column-gap: 0.5rem;
		}

		:deep(.carousel__next),
		:deep(.carousel__prev) {
			@include hidden-between($breakpoint-mobiletabs, $breakpoint-extralarge);

			width: 5rem;
			height: 5rem;
			background: none;
			border: none;
			z-index: 5;
		}

		:deep(.carousel__prev) {
			transform: translate3d(1.5rem, -50%, 0);
		}

		:deep(.carousel__next) {
			transform: translate3d(-1.5rem, -50%, 0);
		}

		:deep(.carousel__prev--disabled),
		:deep(.carousel__next--disabled) {
			display: none;
		}
	}

	&__aria-label {
		@include sr-only;
	}

	&__prev-icon,
	&__next-icon {
		width: 2.8rem;
		fill: $color-white;

		&.is-thumb {
			width: 2rem;
			height: 2rem;
		}
	}

	&__prev-icon {
		transform: rotate(-90deg) translate(0.2rem, -0.4rem);
	}

	&__next-icon {
		transform: rotate(90deg) translateX(-0.2rem);
	}
}

@media screen and (min-width: $breakpoint-small) {
	.checkout-hotel-info-gallery {
		height: 33.5rem;

		&__carousel {
			:deep(.carousel__prev),
			:deep(.carousel__next) {
				background: $color-white;
				border-radius: 100%;
				border: 0.1rem solid $color-primary-l4;
			}
		}

		&__prev-icon,
		&__next-icon {
			width: 2.2rem;
			fill: $color-primary;
		}
	}
}
</style>
