export const storage = {
	getItem<T>(key: string) {
		try {
			const item = JSON.parse(window.localStorage.getItem(key) || '[]') as T[];
			if (!Array.isArray(item)) {
				throw new Error();
			}
			return item;
		} catch (e) {
			return [];
		}
	},
	setItem<T>(key: string, value: T[]) {
		try {
			window.localStorage.setItem(key, JSON.stringify(value));
		} catch (e) {
			//
		}
	},
	removeItem(key: string) {
		try {
			window.localStorage.removeItem(key);
		} catch (e) {
			//
		}
	},
	getEntryById<T extends {id: string}>(key: string, id: string) {
		const items = this.getItem<T>(key);
		return items.find((e) => e.id === id);
	},
	addNewEntry<T extends {id: string}>(key: string, newEntry: T) {
		const items = this.getItem<T>('key');
		if (!items.find((e) => e.id === newEntry.id)) {
			items.push(newEntry);
			storage.setItem('paymentDone', items);
		}
	}
};
