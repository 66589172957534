<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.3737 1.56549C16.3974 0.589176 14.8145 0.589172 13.8382 1.56549L2.04018 13.3635C1.69118 13.7125 1.45329 14.157 1.3565 14.6409L0.867276 17.0871C0.634036 18.2533 1.66223 19.2815 2.82844 19.0483L5.27455 18.559C5.75853 18.4623 6.20303 18.2243 6.55203 17.8753L18.35 6.07733C19.3263 5.10102 19.3263 3.51811 18.35 2.54179L17.3737 1.56549ZM15.0167 2.74399C15.3422 2.41856 15.8697 2.41856 16.1952 2.74399L17.1715 3.72031C17.497 4.04575 17.497 4.57338 17.1715 4.89882L14.9452 7.12515L12.7904 4.97032L15.0167 2.74399ZM11.6118 6.14884L3.21869 14.542C3.10236 14.6583 3.02307 14.8065 2.9908 14.9678L2.50158 17.4139L4.94769 16.9247C5.10902 16.8924 5.25719 16.8132 5.37352 16.6968L13.7667 8.30366L11.6118 6.14884Z"
		/>
	</svg>
</template>
