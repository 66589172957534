<template>
	<ins class="rpb_price"><small>ab</small> {{ formattedPrice }}</ins>
</template>

<script setup lang="ts">
import { formatPrice } from '@utils/priceUtils';
import { computed } from 'vue';

interface Props {
	price: number;
	currency: string;
}

const props = withDefaults(defineProps<Props>(), {
	price: 998,
	currency: 'EUR'
});

const formattedPrice = computed((): string => formatPrice(props.price, props.currency));

</script>

<style lang="scss" scoped>
.rpb_price {
	font-weight: 600;
	width: 100%;
	text-align: center;
	font-size: 3rem;
	line-height: 3.4rem;
	white-space: nowrap;
	text-decoration: none;

	small {
		font-size: 1.8rem;
		font-weight: normal;
	}
}
</style>
