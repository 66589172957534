<template>
	<span class="info-tooltip">
		<span
			ref="icon"
			class="info-tooltip__icon-container"
			@mouseover="showTooltip = true"
			@mouseleave="showTooltip = false"
		>
			<BaseIcon
				id="button"
				name="infoFill"
				class="info-tooltip__icon"
			/>
		</span>
		<div
			id="tooltip"
			ref="tooltip"
			class="info-tooltip__tooltip"
			:class="modifierClass"
			:data-show="showTooltip || showTooltipParent"
		>
			<slot></slot>
			<div
				class="info-tooltip__arrow"
				data-popper-arrow
			></div>
		</div>
	</span>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { createPopper } from '@popperjs/core';

interface Props {
	showTooltipParent?: boolean,
	placement?: 'top' | 'left' | 'right' | 'bottom',
	modifierClass?: string
}

const props = withDefaults(defineProps<Props>(), {
	showTooltipParent: false,
	placement: 'top',
	modifierClass: '',
});

const icon = ref<HTMLElement>();
const tooltip = ref<HTMLElement>();
const showTooltip = ref(false);

onMounted(async() => {
	setTimeout(() => {
		if (icon.value && tooltip.value) {
			createPopper(icon.value, tooltip.value, {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 10],
						},
					},
					{
						name: 'preventOverflow',
						options: {
							boundary: document.querySelector('.list-item.is-verified'),
						}
					},
				],
				placement: props.placement,
			});
		}
	}, 500);
});

</script>

<style lang="scss" scoped>
	.info-tooltip {
		fill: $color-primary;
		margin-right: 2rem;

		&__tooltip {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			background: $color-primary;
			color: white;
			padding: 4px 8px;
			font-size: 13px;
			border-radius: 4px;
			transition: visibility 0.2s, opacity 0.2s linear;
			z-index: 1;
			isolation: isolate;
		}

		&__tooltip[data-show='true'] {
			visibility: visible;
			opacity: 1;
		}

		&__icon {
			width: 1.1rem;
			height: 1.1rem;
		}

		&__arrow,
		&__arrow::before {
			position: absolute;
			left: 0;
			width: 8px;
			height: 8px;
			background: inherit;
		}

		&__arrow {
			visibility: hidden;
		}

		&__arrow::before {
			visibility: visible;
			content: '';
			transform: rotate(45deg);
		}

		&__tooltip[data-popper-placement^='top'] > &__arrow {
			bottom: -4px;
		}

		&__tooltip[data-popper-placement^='bottom'] > &__arrow {
			top: -4px;
		}

		&__tooltip[data-popper-placement^='left'] > &__arrow {
			right: -4px;
		}

		&__tooltip[data-popper-placement^='right'] > &__arrow {
			left: -4px;
		}
	}
</style>
