<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="148.388"
		height="22.228"
		viewBox="0 0 148.388 22.228"
	>
		<g
			id="Group_376"
			data-name="Group 376"
			transform="translate(-22.96 -1253.717)"
		>
			<g
				id="Group_128"
				data-name="Group 128"
				transform="translate(39.082 1255.027)"
			>
				<path
					id="Path_8"
					data-name="Path 8"
					d="M1190.706,239.793l8.061,9.77-132.145.122-.122-19.663,132.267-.122Z"
					transform="translate(-1066.5 -229.9)"
					fill="#2ccbdf"
				/>
			</g>
			<circle
				id="Ellipse_214"
				data-name="Ellipse 214"
				cx="11.114"
				cy="11.114"
				r="11.114"
				transform="translate(22.96 1253.717)"
				fill="#a01b79"
			/>
			<text
				id="Bestpreisgarantie"
				transform="translate(50.139 1269.045)"
				fill="#333"
				font-size="13"
				font-family="OpenSans-Semibold, Open Sans"
				font-weight="600"
			><tspan
				x="0"
				y="0"
			>Bestpreisgarantie</tspan></text>
			<g
				id="check"
				transform="translate(26.612 1258.703)"
			>
				<path
					id="check-2"
					data-name="check"
					d="M17.085,8.262a.93.93,0,0,1-.27.673l-8.28,8.519a.911.911,0,0,1-1.309,0L2.431,12.521a.976.976,0,0,1,0-1.347L3.74,9.827a.911.911,0,0,1,1.309,0L7.88,12.749,14.2,6.241a.911.911,0,0,1,1.309,0l1.31,1.347A.93.93,0,0,1,17.085,8.262Z"
					transform="translate(-2.161 -5.964)"
					fill="#fff"
				/>
			</g>
		</g>
	</svg>
</template>
