<template>
	<div class="board-type">
		<SelectField
			v-if="isDesktop"
			ref="boardFieldDesktop"
			v-model="selected"
			class="board"
			:board="true"
			:items="boardList"
			label="Verpflegung"
			:label-prop="'label'"
			value-prop="value"
			icon="board"
			:button-wording="localeString"
			:item-any="true"
			:loading="showLoadingSpinner && isHotelPage"
			@SelectField:Toggle="onToggle"
			@SelectField:Clear="clearInput"
		>
			<li
				v-for="(item, index) in boardList"
				:key="index"
			>
				<button
					class="select-field__list-item"
					:class="{'is-selected': selected === item['value'], 'is-disabled' : isEntryDisabled(item)}"
					@click.prevent="() => select(item)"
					@keydown.enter="() => select(item)"
				>
					{{ item.label }}
					<span
						v-if="item.price"
						class="select-field__price"
					>
						ab {{ getPriceCorrectCurrency(item.price) }}
					</span>
				</button>
			</li>
		</SelectField>

		<FormField
			v-if="!isDesktop"
			:selected="fieldValue"
			label="Verpflegung"
			:board="true"
			:show-modal="showModal"
			icon="board"
			:show-toggle-icon="false"
			@click="toggleModal"
			@FormField:Clear="clearInput(); clearProxyInput()"
		/>

		<Modal
			v-model="showModal"
			:board="true"
			class="board__modal"
			title="Verpflegung"
			@Modal:Ok="onOk"
			@Modal:Cancel="onReset"
		>
			<SelectField
				ref="boardFieldMobile"
				v-model="proxy"
				class="board"
				:items="boardList"
				:manual="true"
				:show-footer="false"
				:allow-clear="true"
				:loading="showLoadingSpinner && isHotelPage"
				value-prop="value"
				icon="board"
				@SelectField:Clear="clearInput(); clearProxyInput()"
			>
				<li
					v-for="(item, index) in boardList"
					:key="index"
				>
					<button
						class="select-field__list-item"
						:class="{'is-selected': proxy === item['value'], 'is-disabled' : isEntryDisabled(item)}"
						@click.prevent="() => select(item)"
						@keydown.enter="() => select(item)"
					>
						{{ item.label }}
						<span
							v-if="item.price"
							class="select-field__price"
						>
							ab {{ getPriceCorrectCurrency(item.price) }}
						</span>
					</button>
				</li>
			</SelectField>
		</Modal>
	</div>
</template>

<script lang="ts" setup>
import { watch, ref, computed } from 'vue';
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString, cloneDeep } from '@utils/utils';
import { useStore } from '@/components/common/store';
import { Board, UpsellingBoard } from '@/interfaces/search-form';
import { getOfferUpsellingBoard as requestUpsellingBoardList } from '@/components/common/services/bestOfferBoardService';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';

interface Props {
	isHotelPage?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	isHotelPage: false,
});

const store = useStore();
const localeString = ref(getLocaleString('close'));
const boardTypes = computed({
	get() {
		if (store.state.searchMask?.boardTypes) {
			return store.state.searchMask.boardTypes;
		}
		return [];
	},

	set(value: string[]) {
		if (value[0] === '') {
			value = [];
		}
		store.commit('searchMask/updateFormData', { boardTypes: value });
	}
});

const selected = ref< string | null>((boardTypes.value.length && boardTypes.value[0]) || null);

watch(() => selected.value, () => {
	boardTypes.value = [selected.value || ''];
});

watch(() => boardTypes.value, () => {
	selected.value = (boardTypes.value.length && boardTypes.value[0]) || null;
});

const proxy = ref< string | null >((boardTypes.value.length && boardTypes.value[0]) || null);
const open = ref(false);
const showModal = ref(false);

const isDesktop = computed(() => store.state.config.isDesktop);

const defaultTypes = computed(() => store.state.types.board.filter((obj: any) => obj.showInMenu));

const upsellingList = ref(defaultTypes.value);

const showLoadingSpinner = ref(false);

const boardList = computed(() => (props.isHotelPage ? upsellingList.value : defaultTypes.value));

const fieldValue = computed(() => boardTypes.value.map((value: string) => boardList.value.find((x) => x.value === value)?.label).toString());

const upsellingResponseError = ref(false);

const isEntryDisabled = (entry: Board) => !entry.price && props.isHotelPage && !upsellingResponseError.value;

const clearInput = () => {
	selected.value = null;
};

const clearProxyInput = () => {
	proxy.value = null;
};

const getBoardListUpdatedPrices = (responseBoards: UpsellingBoard[]) => {
	const updatedTypes = cloneDeep(defaultTypes.value);

	updatedTypes.forEach((item) => {
		const responseItem = responseBoards.find((respItem: any) => respItem.Board.Type === item.value);
		item.price = responseItem?.Price;
	});

	return updatedTypes;
};

const getUpsellingData = async() => {
	showLoadingSpinner.value = true;
	upsellingResponseError.value = false;
	try {
		const upsellingBoardsReponse = await requestUpsellingBoardList();
		upsellingList.value = getBoardListUpdatedPrices(upsellingBoardsReponse);
	} catch (error) {
		console.error(error);
		upsellingResponseError.value = true;
		upsellingList.value = defaultTypes.value;
	} finally {
		showLoadingSpinner.value = false;
	}
};

const onToggle = (toggle: boolean) => {
	open.value = toggle;
	if (toggle && props.isHotelPage) {
		getUpsellingData();
	}
};

const onOk = () => {
	boardTypes.value = [proxy.value || ''];
	onToggle(false);
};

const onReset = () => {
	proxy.value = boardTypes.value[0];
	onToggle(false);
};

const toggleModal = () => {
	showModal.value = !showModal.value;
	if (showModal.value && props.isHotelPage) {
		getUpsellingData();
	}
};

const select = (item: Board) => {
	if (!isEntryDisabled(item)) {
		selected.value = item.value;
		proxy.value = item.value;
	}
};

</script>

<style lang="scss" scoped>
.board-type {
	.board__icon {
		width: 3rem;
		height: 3.5rem;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}
}

.board__modal {
	.board {
		padding: 2.5rem 1.5rem 0;
	}
}

@include media-query-up($breakpoint-small) {
	.clear-icon {
		fill: $color-primary;
	}
}
.select-field__price {
	float: right;
	color: $color-extra;
}

.select-field__list-item {
	display: block;
	width: 100%;
	padding: 1.2rem 0.5rem 1.2rem 4.4rem;
	border: none;
	background: none;
	color: $color-text-l24;
	font-size: $font-small;
	font-weight: 600;
	text-align: left;
	text-decoration: none;

	&:active,
	&:focus {
		color: $color-primary-l1;
	}

	&:hover {
		background: $color-primary-l6;
	}

	&.is-selected {
		background: $color-primary-l1;
		color: $dropdown-active-color;

		.select-field__price {
			color: $dropdown-active-color;
		}
	}

	&.is-disabled {
		cursor: default;
		background: $color-page-background;
		color: $color-state-disabled;

		&:active,
		&:focus {
			background: $color-page-background;
		}

		&:hover {
			background: $color-page-background;
		}

		&.is-selected {
			background: $color-light-gray;
		}
	}
}
</style>
