<template>
	<section class="no-offers-found">
		<p class="no-offers-found__empty">
			<span class="no-offers-found__empty-title">Ihre Suche ergab leider keine Ergebnisse.</span>
		</p>
		<div class="no-offers-found__inner-wrap">
			<div
				class="no-offers-found__empty-desc"
				@click.prevent="openSearchmaskFilters"
			>
				<p>Bitte ändern Sie Ihre Angaben (z.B. den Reisetermin) oder schränken Sie Ihre Suche weniger ein.</p>
			</div>
			<RoundedButton
				v-if="!isDesktop"
				:theme="'outlined'"
				class="no-offers-found__button"
				v-bind="$attrs"
				@click.prevent="openSearchmaskFilters"
			>
				Filter öffnen
			</RoundedButton>
		</div>
	</section>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const openSearchmaskFilters = (): void => {
	if (isDesktop.value) {
		return;
	}
	const searchButton = document.getElementById('rpb_header-icon-search');
	if (searchButton?.className !== 'rpb_header__search--open') {
		searchButton?.click();
	}
	const filterHeader = document.getElementById('theme-main-filters');

	setTimeout(() => {
		filterHeader?.scrollIntoView({
			behavior: 'smooth',
		});
	}, 100);
};
</script>

<style lang="scss" scoped>
.no-offers-found {
	margin-bottom: 2rem;
	padding: 2rem;
	border: 0.1rem solid $color-warning;
	border-radius: 1.9rem;
	width: 100%;
	@media screen and (min-width: $breakpoint-large){
		padding: 1.5rem 2rem;
	}

	.no-offers-found__empty {
		font-size: 1.2rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 0;

	}

	.no-offers-found__empty-title {
		display: block;
		font-size: 2.6rem;
		font-weight: 600;
		font-family: $font-family-special;
		margin-bottom: 1.6rem;
	}

	.no-offers-found__inner-wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.no-offers-found__empty-desc {
		color: $color-primary;
		font-family: $font-family;
		font-size: $font-small;
		font-weight: 600;
		text-decoration: underline;
		margin-bottom: 2.6rem;
		cursor:pointer;
		@media screen and (min-width: $breakpoint-small) {
			margin-bottom: 1.5rem;
		}
		@media screen and (min-width: $breakpoint-extralarge) {
			font-size: 1.8rem;
			font-style: italic;
			text-decoration: initial;
			margin-bottom: 0;
			color: $color-text;
			cursor: auto;
		}

		p {
			margin-bottom: 0.5rem;
		}
	}

	.no-offers-found__button {
		border-color: $color-primary-l4;
		max-width: 12.9rem;
		height: 3.3rem;
		font-size: 1.4rem;
		padding: 0;
	}

	@include media-query-up($breakpoint-large) {
		.no-offers-found__empty {
			font-size: 1.6rem;
		}

		.no-offers-found__empty-title {
			display: inline-block;
		}
	}
}
</style>
