<template>
	<svg
		width="26"
		height="5"
		viewBox="0 0 26 6"
	>
		<rect
			x="26"
			width="6"
			height="26"
			rx="2"
			transform="rotate(90 26 0)"
		/>
	</svg>
</template>
