/**
 *
 * @param {(number|HTMLElement)} destination - Destination to scroll to (DOM element or number)
 * @param {number} duration - Duration of scrolling animation
 * @param {string} easing - Timing function name (Allowed values: 'linear', 'easeInQuad', 'easeOutQuad', 'easeInOutQuad', 'easeInCubic', 'easeOutCubic', 'easeInOutCubic', 'easeInQuart', 'easeOutQuart', 'easeInOutQuart', 'easeInQuint', 'easeOutQuint', 'easeInOutQuint')
 * @param {number} offset - Offset for scroll-to position
 * @param {function} callback - Optional callback invoked after animation
 */
function scrollIt(destination) {
	'use strict';

	var duration = arguments.length <= 1 || arguments[1] === undefined ? 200 : arguments[1];
	var easing = arguments.length <= 2 || arguments[2] === undefined ? 'linear' : arguments[2];
	var offset = arguments.length <= 3 || arguments[3] === undefined ? 0 : arguments[3];
	var callback = arguments[4];

	function getOffset(el, leftoffset)
	{
		var offset = 0;
		var offsetType = leftoffset ? 'offsetLeft' : 'offsetTop';
		do {
			if (el[offsetType]) {
				offset += el[offsetType];
			}
		} while(el = el.offsetParent);

		return offset;
	}

	// Predefine list of available timing functions
	// If you need more, tween js is full of great examples
	// https://github.com/tweenjs/tween.js/blob/master/src/Tween.js#L421-L737
	var easings = {
		linear: function linear(t) {
			return t;
		},
		easeInQuad: function easeInQuad(t) {
			return t * t;
		},
		easeOutQuad: function easeOutQuad(t) {
			return t * (2 - t);
		},
		easeInOutQuad: function easeInOutQuad(t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
		},
		easeInCubic: function easeInCubic(t) {
			return t * t * t;
		},
		easeOutCubic: function easeOutCubic(t) {
			return --t * t * t + 1;
		},
		easeInOutCubic: function easeInOutCubic(t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
		},
		easeInQuart: function easeInQuart(t) {
			return t * t * t * t;
		},
		easeOutQuart: function easeOutQuart(t) {
			return 1 - --t * t * t * t;
		},
		easeInOutQuart: function easeInOutQuart(t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
		},
		easeInQuint: function easeInQuint(t) {
			return t * t * t * t * t;
		},
		easeOutQuint: function easeOutQuint(t) {
			return 1 + --t * t * t * t * t;
		},
		easeInOutQuint: function easeInOutQuint(t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
		}
	};

	// Store initial position of a window and time
	// If performance is not available in your browser
	// It will fallback to new Date().getTime() - thanks IE < 10
	var start = window.pageYOffset;
	var startTime = typeof(window.performance) !== 'undefined' && typeof(window.performance['now']) === 'function' ? performance.now() : new Date().getTime();
	// const startTime = typeof(window.performance['now']) == 'function' ? performance.now() : new Date().getTime();

	// Take height of window and document to sesolve max scrollable value
	// Prevent requestAnimationFrame() from scrolling below maximum scollable value
	// Resolve destination type (node or number)
	var scrollLimit = document.documentElement.scrollHeight - document.documentElement.clientHeight;
	var destinationOffset = (typeof destination === 'number' ? destination : getOffset(destination)) - offset;
	var destinationOffsetToScroll = Math.round(destinationOffset > scrollLimit ? scrollLimit : destinationOffset);

	// If requestAnimationFrame is not supported
	// Move window to destination position and trigger callback function
	if ('requestAnimationFrame' in window === false) {
		window.scroll(0, destinationOffsetToScroll);
		if (callback) {
			callback();
		}
		return;
	}

	// function resolves position of a window and moves to exact amount of pixels
	// Resolved by calculating delta and timing function choosen by user
	function scroll() {
		var now = typeof(window.performance) !== 'undefined' && typeof(window.performance['now']) === 'function' ? performance.now() : new Date().getTime();
		var time = Math.min(1, (now - startTime) / duration);
		var timeFunction = easings[easing](time ? time : 0);
		var targetPos = Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start);

		if (window.isNaN(targetPos)) {
			console.log("NaN Error");
			return false;
		}

		window.scroll(0, targetPos);

		// Stop requesting animation when window reached its destination
		// And run a callback function
		if (Math.ceil(window.pageYOffset) === destinationOffsetToScroll ||
			Math.floor(window.pageYOffset) === destinationOffsetToScroll) {

			if (typeof callback === 'function') {
				callback();
			}
			return;
		}

		if (time === 1) { // Prevent endless loop.
			if (typeof callback === 'function') {
				callback();
			}
			return false;
		}

		// If window still needs to scroll to reach destination
		// Request another scroll invokation
		requestAnimationFrame(scroll);
	}


	// Invoke scroll and sequential requestAnimationFrame
	scroll();
}

export { scrollIt };
