interface CountLabel {
	singular: string;
	plural: string;
}

const DEFAULT_DURATION_FILTER: ReadonlyArray<number> = [1, 14];
const TRAVEL_DURATION_EXACT: Readonly<string> = 'exact';
const DEFAULT_TRAVEL_DURATION: Readonly<number> = 7;
const ANY: Readonly<string> = 'Any';

const HOTEL_LABELS: Readonly<CountLabel> = {
	singular: 'Hotel',
	plural: 'Hotels',
};

const NBSP = '\u00A0';
const NDASH = '\u2013';

const OFFER_INCLUDE_TYPES = ({
	railandfly: 'Zug zum Flug (innerhalb Deutschlands)',
	transfer: 'Inkl. Transfer vor Ort',
	rentalcar: 'Mietwagen inkl.',
}) as {[key: string]: string};

const PAYMENT_TYPES = ({
	AX: { label: 'American Express', img: 'AX', type: 'CreditCard' },
	VI: { label: 'Visa', img: 'VI', type: 'CreditCard' },
	MC: { label: 'Mastercard', img: 'MC', type: 'CreditCard' },
	BankTransfer: { label: 'Banküberweisung', img: 'BankTransfer' },
	DirectDebitInternational: { label: 'SEPA-Lastschrift', img: 'sepa' },
	PayPal: { label: 'PayPal', img: 'paypal' },
} as {[key: string]: { label: string; img: string; type?: 'CreditCard' | undefined }});

const timeZoneOffsetInHours = 11;
const timeZoneOffsetInMs = timeZoneOffsetInHours * 60 * 60 * 1000;

const CHECKOUT_OTHER_CUSTOMER_ID = 99;
const CHECKOUT_SUMMARY_STEP = 0;
const CHECKOUT_PERSONAL_DATA_STEP = 2;
const CHECKOUT_PAYMENT_DATA_STEP = 1;
const CHECKOUT_INSURANCE_STEP = 3;
const CHEKCOUT_FINALIZE_STEP = 4;

export {
	CountLabel,
	DEFAULT_DURATION_FILTER,
	DEFAULT_TRAVEL_DURATION,
	ANY,
	HOTEL_LABELS,
	NBSP,
	NDASH,
	OFFER_INCLUDE_TYPES,
	PAYMENT_TYPES,
	timeZoneOffsetInHours,
	timeZoneOffsetInMs,
	CHECKOUT_OTHER_CUSTOMER_ID,
	CHECKOUT_SUMMARY_STEP,
	CHECKOUT_PERSONAL_DATA_STEP,
	CHECKOUT_PAYMENT_DATA_STEP,
	CHECKOUT_INSURANCE_STEP,
	CHEKCOUT_FINALIZE_STEP,
	TRAVEL_DURATION_EXACT,
};
