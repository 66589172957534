<template>
	<RowList class="travelers-field-content">
		<template #content>
			<div class="travelers-field-content__content">
				<Counter
					v-model="model.adult"
					class="travelers-field-content__counter"
					single-label="Erwachsener"
					plural-label="Erwachsene"
				/>

				<Counter
					v-model="childrenCount"
					class="travelers-field-content__counter"
					single-label="Kind"
					plural-label="Kinder"
					:min="0"
					:max="3"
				/>

				<p class="travelers-field-content__helper">
					Standardmäßig wird nur 1 Zimmer gebucht. Wünschen Sie mehrere Zimmer, rufen Sie uns bitte unter <a
						:href="`tel:${phoneNumberLink}`"
						class="travelers-field-content__phone"
					>{{ phoneNumber }}</a> an.
				</p>

				<template v-if="childrenCount">
					<p class="travelers-field-content__alter">
						Alter der Kinder bei der Rückreise
					</p>
					<div class="travelers-field-content__slider">
						<div
							v-for="(child, index) in childrenCount"
							:key="index"
							class="travelers-field-content__age-row"
						>
							<p class="travelers-field-content__age-value">
								<span
									v-if="childrenCount > 1"
									class="travelers-field-content__chilren-index"
								>{{ index + 1 }}. Kind</span>

								{{ childrenAgeValue(model.children[index]) }}
							</p>
							<RangeSlider
								v-model="model.children[index]"
								class="travelers-content__age"
								:default-value="defaultAge"
								:min="1"
								:max="17"
							/>
						</div>
					</div>
				</template>
			</div>
		</template>
		<template #footer>
			<div class="travelers-field-content__footer">
				<transition name="fade">
					<p
						v-if="isInfants"
						class="travelers-field-content__infants-warning"
					>
						Kinder unter 2 Jahren haben keinen Anspruch auf einen eigenen Sitzplatz im Flieger.
					</p>
				</transition>
			</div>
		</template>
	</RowList>
</template>

<script lang="ts" setup>
import RowList from '@lmt-rpb/RowList/RowList.vue';
import Counter from '@lmt-rpb/Counter/Counter.vue';
import RangeSlider from '@lmt-rpb/RangeSlider/RangeSlider.vue';
import { pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { Travelers } from '@/components/common/types';

const emit = defineEmits(['update:modelValue']);

interface Props {
	defaultAge?: number,
	modelValue?: Travelers,
}
const props = withDefaults(defineProps<Props>(), {
	defaultAge: 12,
	modelValue: () => ({ adult: 2, children: [] }),
});
const store = useStore();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: Travelers) {
		emit('update:modelValue', newValue);
	}
});

const childrenCount = ref(0);

watch(() => props.modelValue, () => {
	childrenCount.value = model.value.children.length;
}, { immediate: true });

watch(() => childrenCount.value, () => {
	model.value.children = model.value.children.slice(0, childrenCount.value);
}, { immediate: true });

const phoneNumber = computed((): string => store.state.config.phone);

const phoneNumberLink = computed((): string => store.state.config.phoneLink);

const isInfants = computed((): boolean => model.value.children
	.slice(0, childrenCount.value)
	.some((value: number) => value < 2));

const childrenAgeValue = (age: number) => (age < 2 && 'jünger als 2 Jahre') || pluralize(age, 'Jahr', 'Jahre');

</script>

<style lang="scss" scoped>
.travelers-field-content {
	@include max-width(50rem);

	overflow: auto;
	overflow: overlay;

	.travelers-field-content__counter {
		margin-bottom: 1.5rem;
	}

	.travelers-field-content__content {
		padding: 1rem 1rem 3rem;
	}

	.travelers-field-content__helper {
		font-size: 1.4rem;
		line-height: 1.5;
	}

	.travelers-field-content__phone {
		color: $color-primary;
		text-decoration: none;
	}

	.travelers-field-content__alter {
		font-size: 1.6rem;
		font-weight: 600;
	}

	.travelers-field-content__age-value {
		position: relative;
		color: $color-primary;
		font-size: 2.2rem;
		font-weight: bold;
		text-align: center;
		margin: 2.2rem 0;
	}

	.travelers-field-content__chilren-index {
		position: absolute;
		top: 0.7rem;
		left: 1.1rem;
		padding-right: 0.1rem;
		color: $color-text;
		font-style: italic;
		font-weight: $bold-default;
		font-size: $font-small;
	}

	.travelers-field-content__age {
		margin-bottom: 4rem;
	}

	.travelers-field-content__infants-warning {
		margin-bottom: 0;
		padding: 1rem;
		color: $color-warning;
		font-size: 1.4rem;
		line-height: 1.5;
	}

	.travelers-field-content__footer {
		background: $color-light-gray;
	}
}

</style>
