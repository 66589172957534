<template>
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.0391 0.0395478C12.4018 0.0395478 12.7093 0.158192 12.9616 0.39548C13.2296 0.632768 13.3637 0.883239 13.3637 1.14689C13.3637 1.39736 13.2612 1.63465 13.0562 1.85876L8.30206 7.09887L6.69369 5.25989L10.8802 0.612994C11.2113 0.230697 11.5976 0.0395478 12.0391 0.0395478ZM1.49015 0C1.94743 0 2.33375 0.171375 2.64912 0.514124L13.0562 12.0819C13.2454 12.2928 13.34 12.5367 13.34 12.8136C13.34 13.1827 13.1823 13.4727 12.867 13.6836C12.5516 13.8945 12.2362 14 11.9209 14C11.4636 14 11.0773 13.8286 10.7619 13.4859L0.354832 1.91808C0.149845 1.70716 0.0473506 1.46987 0.0473506 1.20621C0.0473506 0.863465 0.197149 0.580037 0.496747 0.355932C0.812113 0.118644 1.14325 0 1.49015 0ZM1.27728 14C0.930375 14 0.630778 13.8945 0.378485 13.6836C0.126192 13.4595 4.57764e-05 13.2156 4.57764e-05 12.952C4.57764e-05 12.662 0.118308 12.3917 0.354832 12.1412L5.15628 6.70339L6.71734 8.62147L2.4599 13.4463C2.14453 13.8154 1.75033 14 1.27728 14Z"
			fill="#333333"
		/>
	</svg>
</template>
