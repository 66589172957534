import { ref, computed } from 'vue';
import { useStore } from '@/components/common/store';
import { INSURANCE_CHOICE, INSURANCE_INFO_ACCEPTED } from '@global-js/ids-and-classnames/checkout-insurance-ids';

const store = useStore();

type FieldValidation = {
	stepNr: number,
	valid: boolean,
	validated: boolean,
	hasBackendError: boolean,
}

interface ICheckoutValidationState {
	[field: string]: FieldValidation
}

export const CheckoutValidationState = ref<ICheckoutValidationState>({});

export const CheckedInsuranceIndex = ref('');

export const ignorePropertiesForCustomer = ['gender-0', 'first-name-0', 'last-name-0', 'birth-date-0'];

export const InvalidFields = computed(() => {
	const customerWantsInsurance = store.getters['checkout/insurance'];
	const paymentType = store.getters['checkout/travel'].PaymentType;
	return Object
		.entries(CheckoutValidationState.value)
		.flatMap((entry) => {
			if (entry[1].valid || !entry[1].validated) {
				return [];
			}
			if (!store.state.checkout.HasInsuranceOffer && entry[0].includes('insurance')) {
				return [];
			}
			if (
				!customerWantsInsurance && entry[0].includes('insurance')
				&& entry[0] !== 'insurance-radio-button-want'
			) {
				return [];
			}
			if (
				customerWantsInsurance
				&& entry[0].includes('insurance')
				&& !entry[0].includes(`insurance-payment-${CheckedInsuranceIndex.value}`)
				&& entry[0] !== INSURANCE_INFO_ACCEPTED
				&& entry[0] !== INSURANCE_CHOICE
			) {
				return [];
			}
			if (paymentType !== 'DirectDebitInternational' && entry[0].includes('sepa')) {
				return [];
			}
			if (paymentType === 'DirectDebitInternational' && entry[0].includes('insurance-payment')) {
				return [];
			}
			if (
				(paymentType === 'DirectDebitInternational' || paymentType === 'BankTransfer')
				&& entry[0] === 'credit-card-radio-group'
			) {
				return [];
			}
			if (store.getters['checkout/isCustomerTraveller'] && ignorePropertiesForCustomer.some((key) => entry[0] === key)) {
				return [];
			}
			return [{ name: entry[0], stepNr: entry[1].stepNr }];
		});
});

export const fieldIsValid = (name: string): boolean => {
	let isValid = true;
	InvalidFields.value.forEach((invalidField) => {
		if (invalidField.name === name) isValid = false;
	});
	return isValid;
};

export const validateCheckoutState = (stepNr?: number): void => {
	Object
		.entries(CheckoutValidationState.value).forEach((field) => {
			// when no stepNr is given, validate all fields
			if (!stepNr) {
				// eslint-disable-next-line no-param-reassign
				field[1].validated = true;
			}
			if (field[1].stepNr === stepNr) {
				// eslint-disable-next-line no-param-reassign
				field[1].validated = true;
			}
		});
};

export const validateStepAndGetInvalidFields = (stepNr: number) => {
	validateCheckoutState(stepNr);
	const filteredFields = InvalidFields.value.filter((field) => field.stepNr === stepNr);
	return filteredFields;
};
