<template>
	<!-- Generated by IcoMoon.io -->
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		width="13"
		height="32"
		viewBox="0 0 13 32"
	>
		<path
			fill="#fff"
			d="M7.438 21.688h0.063c0.25 0.313 0.438 0.688 0.438 1.125s-0.188 0.813-0.5 1.125-0.688 0.5-1.125 0.5-0.813-0.188-1.125-0.5l-0.063-0.063c-0.25-0.313-0.438-0.625-0.438-1.063s0.188-0.813 0.5-1.125c0.125-0.125 0.375-0.313 0.563-0.375v-8.063c0-0.313 0.25-0.625 0.563-0.625s0.625 0.313 0.625 0.625v8.063c0.188 0.063 0.375 0.25 0.5 0.375zM11.875 19.813c0.5 0.875 0.75 1.938 0.75 3 0 1.75-0.688 3.313-1.813 4.438s-2.75 1.875-4.5 1.875-3.313-0.75-4.438-1.875-1.875-2.688-1.875-4.438c0-1.063 0.25-2.125 0.75-3 0.438-0.75 0.938-1.438 1.625-1.938v-11.063c0-1.063 0.5-2.125 1.188-2.813v0 0c0.688-0.688 1.688-1.125 2.75-1.125s2.063 0.438 2.75 1.125h0.063v0.063c0.688 0.688 1.125 1.688 1.125 2.75v11.063c0.688 0.5 1.25 1.188 1.625 1.938zM9.375 25.875c0.75-0.75 1.25-1.875 1.25-3.063 0-0.75-0.188-1.438-0.5-2.063s-0.875-1.125-1.438-1.5c-0.313-0.188-0.438-0.5-0.438-0.875v-11.563c0-0.5-0.25-1-0.563-1.375v0c-0.313-0.313-0.875-0.563-1.375-0.563s-1 0.25-1.313 0.563v0c-0.313 0.375-0.563 0.875-0.563 1.375v11.563c0 0.313-0.188 0.625-0.5 0.813-0.625 0.375-1.063 0.938-1.375 1.563s-0.563 1.313-0.563 2.063c0 1.188 0.5 2.313 1.25 3.063s1.875 1.25 3.063 1.25 2.313-0.5 3.063-1.25z"
		/>
	</svg>
</template>
