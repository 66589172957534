<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 19.207 21.853"
	>
		<path
			id="shield"
			d="M21.1,0,11.541,2.732,1.98,0a20.7,20.7,0,0,0,0,2.732l9.561,2.99L21.1,2.732A20.7,20.7,0,0,0,21.1,0ZM2.155,4.164c.512,5.35,2.422,14.346,9.386,17.689C18.5,18.509,20.415,9.514,20.927,4.164L11.541,7.692,2.155,4.164Z"
			transform="translate(-1.937)"
		/>
	</svg>
</template>
