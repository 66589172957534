<template>
	<div
		class="calendar"
		:class="{'is-horizontal': horizontal}"
	>
		<pager
			v-if="horizontal"
			:disabled="steps === 0"
			direction="left"
			class="calendar__pager is-prev"
			@slide="onSlide"
		/>

		<pager
			v-if="horizontal"
			:disabled="steps === monthsToShow.length - 2"
			direction="right"
			class="calendar__pager is-next"
			@slide="onSlide"
		/>

		<div class="calendar__wrap">
			<div
				class="calendar__inner"
				:style="innerStyle"
			>
				<Month
					v-for="(monthData, index) in monthsToShow"
					:key="index"
					v-observe-visibility="{
						callback: (isVisible: boolean) => isVisible && visibilityChanged(monthData, index),
						once: true
					}"
					v-bind="monthData"
					class="calendar__month"
					:selection="model"
					:horizontal="horizontal"
					@select="onSelect"
				/>
			</div>
		</div>
		<Confirm
			v-if="showDismiss"
			title="Möchten Sie die Auswahl verwerfen?"
			:cancel="`Verwerfen`"
			class="calendar__duration-cancel-confirm"
			ok="Übernehmen"
			@Confirm:Ok="onDismissOk"
			@Confirm:Cancel="onDismissCancel"
			@Confirm:BrowserBack="onDismissCancel"
		>
			<template #cancel-icon>
				<BaseIcon
					name="trashcan"
					class="calendar__button-icon is-cancel"
				/>
			</template>
			<template #ok-icon>
				<BaseIcon
					name="check"
					viewBox="0 0 15.906 12"
					class="calendar__button-icon is-ok"
				/>
			</template>
		</Confirm>
	</div>
</template>

<script lang="ts" setup>
import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';
import {
	now, dateDiff, formatDateInterval, dateRange,
} from '@utils/utils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Confirm from '@lmt-rpb/Confirm/Confirm.vue';
import { EventBus } from '@global-js/event-bus';
import { OfferDuration, SelectionType } from '@interfaces/search-form';
import { useStore } from '@/components/common/store';
import {
	computed,
	onMounted,
	onUnmounted,
	Ref,
	ref,
} from 'vue';
import Month from '../Month/Month.vue';
import Pager from '../Pager/Pager.vue';

const store = useStore();

interface MonthData {
	year: number;
	month: number;
	offset: number;
}

interface Props {
	modelValue?: SelectionType,
	maxMonths: number,
	horizontal: boolean,
	isExact?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: () => ({ from: 0, to: 0 }),
	maxMonths: 24,
	horizontal: true,
});
const showConfirm = ref(false);

const showDismiss = ref(false);

const emit = defineEmits(['update:modelValue', 'TravelDuration:Changed', 'Calendar:AlertOkClick', 'Calendar:AlertCancelClick']);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const nowDate: Date = now() as Date;

const currentMonth: number = nowDate.getMonth();

const currentYear: number = nowDate.getFullYear();

const monthsToShow: Ref<MonthData[]> = ref([]);

const steps = ref(0);

const offerDuration = computed((): OfferDuration | undefined => store.state.searchMask?.offerDuration);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const innerStyle = computed((): Record<string, string> => {
	if (!isDesktop.value) return {};
	const percent = steps.value * -50;

	return {
		transform: `translateX(${percent}%)`
	};
});

const scrollToSelectedMonth = () => {
	if (!isDesktop.value) {
		const indexToScroll = dateDiff(new Date(), offerDuration.value?.from, 'month');

		const monthToScroll = document.querySelector(`.calendar__month[offset="${monthsToShow.value[indexToScroll]?.offset}"]`);
		monthToScroll?.scrollIntoView();
	}
};

const onSelect = (day: Date) => {
	const from = model.value?.from;
	const to = model.value?.to;
	const date = day.getTime();

	if (!!from && !!to) {
		// reselect date
		model.value = { from: date, to: 0 };
	} else if (from === date) {
		// reset selection by reselect start date
		model.value = { from: 0, to: 0 };
	} else if (from && date < from) {
		// switch start date if it's earlier than previously
		const previous = model.value.from;
		model.value = { from: date, to: previous };
	} else if (!from) {
		// set from date
		model.value = { from: date, to: 0 };
	} else if (!to) {
		// set to date
		model.value = { ...model.value, to: date };
	}
};

const onSlide = (direction: number) => {
	steps.value = Math.max(0, Math.min(steps.value + direction, monthsToShow.value.length));
};
const onDismissCancel = () => {
	emit('Calendar:AlertCancelClick');
	showDismiss.value = false;
};

const onDismissOk = () => {
	emit('Calendar:AlertOkClick');
	showDismiss.value = false;
};

const getMonth = (offset: number): MonthData => {
	const date = new Date(currentYear, offset);
	const index = date.getMonth();

	return {
		year: date.getFullYear(),
		month: index,
		offset
	};
};

const visibilityChanged = (month: MonthData, index: number) => {
	// lookahead 2 visible month
	const count = monthsToShow.value.length;
	if (index + 2 > count && count < props.maxMonths) {
		const next = getMonth(month.offset + 1);
		monthsToShow.value.push(next);
	}
};

onMounted(() => {
	EventBus.$on('Calendar:Scroll', scrollToSelectedMonth);

	if (!offerDuration.value?.from) {
		monthsToShow.value = [currentMonth, currentMonth + 1].map(getMonth);
	} else {
		const indexToScroll = dateDiff(new Date(), offerDuration.value?.from, 'month');
		const indexPlus = indexToScroll >= 3 ? 1 : 0;

		for (let index = 0; index <= indexToScroll + indexPlus; index++) {
			monthsToShow.value.push(getMonth(currentMonth + index));
		}
		onSlide(indexToScroll);
		if (!isDesktop.value) {
			setTimeout(() => {
				const monthToScroll = document.querySelector(`.calendar__month[offset="${monthsToShow.value[indexToScroll + indexPlus]?.offset}"]`);
				monthToScroll?.scrollIntoView();
			}, 50);
		}
	}
});

onUnmounted(() => {
	EventBus.$off('Calendar:Scroll', scrollToSelectedMonth);
});

defineExpose({
	showConfirm,
	showDismiss,
});
</script>

<style lang="scss" scoped>
.calendar {
	position: relative;
	min-height: 100%;
	padding: 0 1rem;

	.calendar__pager {
		position: absolute;
		z-index: 1;
		top: 50%;
		right: 1rem;
		left: auto;
		transform: translateY(-50%);

		&.is-prev {
			right: auto;
			left: 1rem;
		}
	}

	.calendar__wrap {
		@include max-width(50rem);
	}

	.calendar__button-icon {
		&.is-cancel {
			width: 2.2rem;
			height: 1.6rem;
			margin: 0 -0.1rem -0.2rem 0;
			fill: $color-placeholder-text;
		}

		height: 1.2rem;
		margin-right: 0.2rem;
		fill: $color-primary;
	}

	&.is-horizontal {
		height: 100%;
		min-height: auto;

		.calendar__wrap {
			margin: 0 4rem 2rem 4rem;
			overflow: hidden;
			max-width: none;
		}

		.calendar__inner {
			display: flex;
			flex-direction: row;
			width: 100%;
			transition: transform ease-out 0.25s;
		}

		.calendar__month {
			min-width: 50%;
		}
	}
}
</style>
