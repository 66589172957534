<template>
	<svg viewBox="0 0 16 16">
		<g transform="translate(0.001)">
			<path
				d="M13.612,2.868c-2.838-3.099-7.652-3.31-10.75-0.472c-1.573,1.441-2.469,3.476-2.47,5.609h1.426
			c0-3.414,2.767-6.181,6.18-6.182c1.757,0,3.431,0.747,4.604,2.056l-2.225,2.224h5.231v-5.23l-2,2L13.612,2.868z"
			/>
			<path
				d="M14.181,8.005c0.001,3.414-2.766,6.182-6.179,6.183c-1.757,0.001-3.432-0.747-4.605-2.056
			l2.225-2.227H0.391v5.231l2-2c2.84,3.097,7.653,3.306,10.751,0.465c1.569-1.438,2.463-3.468,2.466-5.596H14.181z"
			/>
		</g>
	</svg>
</template>
