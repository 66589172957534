<template>
	<svg
		width="21"
		height="20"
		viewBox="0 0 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.92326 4.16666C6.38349 4.16666 6.75659 4.53976 6.75659 5V12.9882L8.66734 11.0774C8.99276 10.752 9.52043 10.752 9.84584 11.0774C10.1713 11.4028 10.1713 11.9305 9.84584 12.2559L6.51252 15.5892C6.18708 15.9147 5.65944 15.9147 5.334 15.5892L2.00067 12.2559C1.67523 11.9305 1.67523 11.4028 2.00067 11.0774C2.32611 10.752 2.85374 10.752 3.17918 11.0774L5.08993 12.9882V5C5.08993 4.53976 5.46303 4.16666 5.92326 4.16666ZM14.5007 4.41074C14.8261 4.08531 15.3538 4.08531 15.6792 4.41074L19.0125 7.74407C19.3379 8.06951 19.3379 8.59716 19.0125 8.92258C18.6871 9.248 18.1594 9.248 17.834 8.92258L15.9233 7.01184V15C15.9233 15.4602 15.5502 15.8333 15.0899 15.8333C14.6297 15.8333 14.2566 15.4602 14.2566 15V7.01184L12.3458 8.92258C12.0204 9.248 11.4928 9.248 11.1673 8.92258C10.8419 8.59716 10.8419 8.06951 11.1673 7.74407L14.5007 4.41074Z"
		/>
	</svg>
</template>
