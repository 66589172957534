<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10"
		height="17.999"
		viewBox="0 0 10 17.999"
	>
		<path
			id="menu-right"
			d="M.275,18.483,7.54,11.218.275,3.953a.987.987,0,0,1,0-1.429.986.986,0,0,1,1.429,0l7.959,8a.939.939,0,0,1,0,1.388l-7.959,8a.986.986,0,0,1-1.429,0,.987.987,0,0,1,0-1.429Z"
			transform="translate(0.031 -2.218)"
		/>
	</svg>
</template>
