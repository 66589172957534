<template>
	<div
		ref="slide"
		class="mode-switch"
		aria-live="polite"
		@click.prevent="onClick"
	>
		<draggable
			v-model:progressValue="progress"
			class="mode-switch__handle"
			:class="{'is-dragging': dragging, 'initial-position': progress === 0}"
			role="switch"
			:aria-label="draggableAriaLabel"
			:aria-checked="`${model}`"
			parent-ref="slide"
			tabindex="0"
			@Draggable:Drag="onDrag($event)"
			@Draggable:DragEnd="onDragEnd($event)"
			@keydown.left="uncheck"
			@keydown.right="check"
			@keydown.enter.prevent="toggle"
		/>

		<span
			class="mode-switch__label"
			:class="[`progress-${colorStages}`]"
		>
			<slot name="left">
				<svg
					class="mode-switch__icon"
					viewBox="0 0 53 32"
				>
					<title>{{ getLocaleString('package') }}</title>
					<path d="M52.403 15.729c-0.456-1.671-1.961-2.88-3.748-2.88-0.358 0-0.704 0.048-1.032 0.139l0.027-0.006-13.769 3.691-17.878-16.671-5.001 1.346 10.726 18.589-12.877 3.446-5.089-4.003-3.758 1.011 4.714 8.169 1.973 3.437 42.963-11.512c1.673-0.455 2.883-1.961 2.883-3.749 0-0.358-0.049-0.705-0.139-1.035l0.006 0.027z" />
				</svg>
				{{ getLocaleString('package') }}
			</slot>
		</span>

		<span
			class="mode-switch__label"
			:class="[`progress-${colorStages}`]"
		>
			<slot name="right">
				Nur Hotel
				<svg
					class="mode-switch__icon"
					viewBox="0 0 32 32"
				>
					<title>Nur Hotel</title>
					<path d="M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" />
				</svg>
			</slot>
		</span>

		<input
			class="mode-switch__input"
			type="checkbox"
			:value="model"
			@change="$emit('SearchModeSwitchSimple:Change', !model)"
		/>
	</div>
</template>

<script lang="ts" setup>
import Draggable from '@lmt-rpb/Draggable/Draggable.vue';
import { getLocaleString } from '@utils/utils';
import { computed, ref, watch } from 'vue';

interface Props {
	threshold?: number,
	draggableAriaLabel: string
	modelValue?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	threshold: 5,
	modelValue: false,
});

const emit = defineEmits(['update:modelValue', 'SearchModeSwitchSimple:Change', 'SearchModeSwitchSimple:Drag', 'SearchModeSwitchSimple:DragEnd', 'SearchModeSwitchSimple:Toggle']);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const progress = ref(0);

const dragging = ref(false);

const colorStages = computed((): number => Math.floor(progress.value / 20));

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	emit('SearchModeSwitchSimple:Drag', progress.value);
	dragging.value = true;
};

const toggle = (): void => {
	model.value = !model.value;
	emit('SearchModeSwitchSimple:Toggle');
};

const onDragEnd = (diff: number): void => {
	if (Math.abs(diff) >= props.threshold) {
		toggle();
	}

	emit('SearchModeSwitchSimple:DragEnd');
	dragging.value = false;
};

const onClick = (event: MouseEvent): void => {
	if (dragging.value) {
		event.stopPropagation();
		return;
	}

	toggle();
};

const check = (): void => {
	progress.value = 100;
	model.value = true;
};

const uncheck = (): void => {
	progress.value = 0;
	model.value = false;
};

watch(() =>	model.value, () => {
	progress.value = model.value ? 100 : 0;
}, { immediate: true });

defineExpose({
	progress,
});

</script>

<style lang="scss" scoped>
.mode-switch {
	display: flex;
	position: relative;
	width: 100%;
	max-width: 38rem;
	padding: 0.95rem 0;
	border: 0.1rem solid $color-primary-l4;
	border-radius: $border-radius-x-large;
	background: $color-white;
	font-size: 1.4rem;
	overflow: visible;
	cursor: pointer;

	.mode-switch__input {
		display: none;
	}

	.mode-switch__label {
		position: relative;
		flex: 1 1 50%;
		transition: color ease-out 0.25s, fill 0.25s;
		font-weight: 600;
		text-align: center;
		pointer-events: none;

		@include switch-transition($color-text-l24);
	}

	.mode-switch__icon {
		width: 1.8rem;
		height: 1.8rem;
		margin: 0 0.25rem;
		vertical-align: middle;
	}

	.initial-position {
		left: -0.2rem !important;
	}

	.mode-switch__handle {
		cursor: pointer;
		position: absolute;
		top: -0.1rem;
		width: 50%;
		height: 105%;
		border-radius: $border-radius-x-large;
		background: $search-mode-switch-bg;

		&:not(.is-dragging) {
			transition: left ease-out 0.15s;
		}

		&:focus {
			border-color: $color-primary-l2;
			outline: none;
			background-color: $color-primary-l2;
			box-shadow: 0 0 0 0.3rem $color-primary-l2;
		}
	}
}

</style>
