<template>
	<label
		:for="option"
		class="payment-radio-button"
		:class="{'selected': isSelected }"
	>
		<input
			:id="option"
			type="radio"
			class="payment-radio-button__input"
			:value="option"
			name="payment-radio-input"
			enterkeyhint="next"
			@change="onChange"
		/>
		<span
			class="payment-radio-button__label"
			:class="{'selected': isSelected }"
		>{{ option }}</span>
		<div
			class="payment-radio-button__sub-label"
			:class="{'selected': isSelected }"
		>gebührenfrei</div>
	</label>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const emit = defineEmits(['PaymentRadio:Change']);

interface Props {
	option: string,
	selected?: string,
}

const props = defineProps<Props>();

const isSelected = computed((): boolean => props.selected === props.option);

const onChange = (event: Event) => {
	emit('PaymentRadio:Change', (event?.target as HTMLInputElement).value);
};

</script>

<style lang="scss" scoped>
.payment-radio-button {
	position: relative;
	border: 0.1rem solid $color-search-mask-border;
	border-radius: $border-radius-x-small;
	min-height: 6rem;
	cursor: pointer;
	padding: 0.7rem;
	background: $color-page-background;
	margin-bottom: 0;
	box-shadow: inset #85E1EC66 0.2rem 0 0.2rem;
	box-shadow: #0000000A 0.1rem 0.2rem 0.2rem;


	&__label {
		cursor: pointer;
		font-size: $font-small;
		font-weight: bold;
		color: $color-checkout-button;
		margin-left: 1rem;


		&.selected {
			color: $color-page-background;
		}
	}

	&__input + .payment-radio-button__label::before {
		content: '';
		position: absolute;
		top: 1.2rem;
		left: 0.7rem;
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 2rem;
		margin-right: 0.8rem;
		border: 0.1rem solid $color-checkout-button;
		flex-shrink: 0;
	}

	&__input:checked + &__label::before {
		border: 0.4rem solid $color-checkout-button;
		box-shadow: 0 0 0 0.1rem white;
		background: $color-white;
	}

	&__input:focus,
	&__input:hover,
	&__input:active {
		& + .payment-radio-button__label::before {
			box-shadow: 0 0 0 0.4rem $color-checkout-hover-radio;
		}

		&:checked + .payment-radio-button__label::before {
			border: 0.3rem solid $color-checkout-button;
			box-shadow: 0 0 0 0.1rem white;
		}
	}

	&__sub-label {
		font-weight: 400;
		color: #44A678;
		font-size: $font-tiny-small;
		margin-left: 2.8rem;


		&.selected {
			color: $color-page-background;
		}
	}

	&__input {
		cursor: pointer;
		opacity: 0;
	}

	&.selected {
		background: $color-checkout-button;
		color: $color-page-background;
		border: 0.2rem solid  $color-primary;
		box-shadow: inset #00000029 0 0.3rem 0.6rem;
	}

	@media (min-width: $breakpoint-medium) {
		max-width: 25rem;
	}

	@media (min-width: $breakpoint-large) {
		&__label {
			font-size: $font-medium;
		}

		&__sub-label {
			font-size: $font-very-small;
		}
	}
}
</style>
