import { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';
import { nextTick } from 'vue';
import { scrollIt } from '@/js/utils/utils';
import { formatPrice } from './priceUtils';

export interface ValidationError {
	field: string,
	message: string,
	stepNr: number,
}
const calculateFormattedPrice = (amount: number | undefined, currency: string | undefined, stornoAmount: number | undefined): string => {
	if (amount === undefined || currency === undefined) return '';
	const totalAmount = amount + (stornoAmount || 0);
	return formatPrice(totalAmount, currency, true);
};

export const getDepositAmount = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.Deposit?.Amount, priceInformation?.Deposit?.Currency, flexStorno?.Amount);

export const getDepositAmountConverted = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.Deposit?.Converted?.Amount, priceInformation?.Deposit?.Converted?.Currency, flexStorno?.Converted?.Amount);

export const getFinalPayment = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.FinalPayment?.Amount, priceInformation?.FinalPayment?.Currency, flexStorno?.Amount);

export const getFinalPaymentConverted = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.FinalPayment?.Converted?.Amount, priceInformation?.FinalPayment?.Converted?.Currency, flexStorno?.Converted?.Amount);

export const getTotal = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.Total?.Amount, priceInformation?.Total?.Currency, flexStorno?.Amount);

export const getTotalConverted = (priceInformation: PriceInformation | undefined, flexStorno?: SimplePrice): string => calculateFormattedPrice(priceInformation?.Total?.Converted?.Amount, priceInformation?.Total?.Converted?.Currency, flexStorno?.Converted?.Amount);

export const scrollToFirstError = (firstInvalidField: ValidationError | string, timeout = 0, offset = 200): void => {
	if (!firstInvalidField) return;
	const scrollToField = (fieldId: string) => {
		const invalidField = document.getElementById(fieldId);
		if (invalidField) {
			const scroll = () => {
				scrollIt(invalidField, 500, 'easeOutQuad', offset);
			};

			scroll();
		}
	};

	const handleTimeout = () => {
		setTimeout(() => {
			if (typeof firstInvalidField === 'string' || firstInvalidField.field) {
				scrollToField(typeof firstInvalidField === 'string' ? firstInvalidField : firstInvalidField.field);
			}
		}, timeout);
	};

	nextTick(handleTimeout);
};
