<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 20 20"
		xml:space="preserve"
	>
		<g transform="translate(0.5 0.5)">
			<path
				fill="none"
				stroke-width="0.75"
				d="M9.5,0C14.7,0,19,4.3,19,9.5c0,5.2-4.3,9.5-9.5,9.5S0,14.7,0,9.5C0,7,1,4.6,2.8,2.8
			C4.6,1,7,0,9.5,0z"
			/>
			<path
				d="M11.8,14.8l0.2-0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.5,0-0.6-0.2
			c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6l0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.3,0-0.5
			c0-0.4-0.2-0.8-0.5-1.1c-0.4-0.3-0.9-0.4-1.3-0.4c-0.4,0-0.7,0-1,0.1C7.8,8.6,7.5,8.8,7.1,8.9L6.9,9.6c0.1-0.1,0.3-0.1,0.4-0.1
			c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.4-0.1,0.6L8,12.8
			c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.3,0,0.5c0,0.4,0.2,0.8,0.5,1.1c0.4,0.3,0.9,0.5,1.4,0.4c0.3,0,0.7,0,1-0.1L11.8,14.8z"
			/>
			<path
				d="M11.9,5.5c0.3-0.2,0.4-0.6,0.4-1c0-0.4-0.2-0.7-0.4-0.9c-0.6-0.5-1.4-0.5-2,0
			C9.6,3.9,9.5,4.2,9.5,4.6c0,0.4,0.2,0.7,0.4,1C10.5,6.1,11.4,6.1,11.9,5.5z"
			/>
		</g>
	</svg>
</template>
