'use strict';

var rbgallery = function(options, elements) {
	var options = options || {};
	var tthis = this,
		current = 0,
		lightboxOpen = false,
		aniActive = false,
		currentTranslate = 0,
		pressed = false,
		pressedStartpos = 0,
		pressedTranslate = 0,
		lastMoveTime = 0,
		gallery = null,
		documentScrollPos = 0,
		lightbox = null;

	var template = options.template || '<div class="rb_lbgallery"> \
		<header> \
			<div class="rb_lbgallery__logo"></div> \
			<div class="rb_lbgallery__btnclose" title="Schließen">\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" width="27" height="27"> \
				<path d="M14.482 13.597l5.664 5.632a.778.778 0 0 1 0 1.127c-.29.321-.805.321-1.126 0l-5.504-5.536-5.536 5.536c-.321.321-.836.321-1.126 0a.778.778 0 0 1 0-1.127l5.664-5.632v-.194L6.854 7.771a.778.778 0 0 1 0-1.127c.29-.321.805-.321 1.126 0l5.536 5.536 5.504-5.536c.321-.321.836-.321 1.126 0a.778.778 0 0 1 0 1.127l-5.664 5.632z" fill="#16828f"/> \
				<circle cx="13.5" cy="13.5" r="13" fill="none" stroke="#85e1ec"/> \
			</svg> \
			</div> \
		</header> \
		<div class="rb_lbgallery__content"> \
			<ul></ul> \
			<div class="rb_lbgallery__arrow rb_lbgallery__arrow--left"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="32" viewBox="0 0 15 32"><path fill="#444" d="M14.813 4.875l-11.125 11.125 11.125 11.125c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.5 0.625-2.125 0l-12.25-12.25c-0.625-0.625-0.625-1.5 0-2.125l12.25-12.25c0.625-0.625 1.5-0.625 2.125 0s0.625 1.563 0 2.188z"></path></svg></div> \
			<div class="rb_lbgallery__arrow rb_lbgallery__arrow--right"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="32" viewBox="0 0 15 32"><path fill="#444" d="M0.438 27.125l11.125-11.125-11.125-11.125c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l12.188 12.25c0.625 0.625 0.625 1.5 0 2.125l-12.188 12.25c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188z"></path></svg></div> \
		</div> \
		<footer></footer> \
	</div>';

	/**
	 * Trigger a custom event.
	 * @param  string eventname Custom event name.
	 * @param  object target    DOM object element.
	 */
	function triggerEvent(eventname, target) {
		var target = target || document;
		var ev = null;
		try {
			ev = new Event(eventname);
		} catch (e) {
			// IE 11
			ev = document.createEvent('CustomEvent');
			ev.initCustomEvent(eventname, false, false, {});
		}

		target.dispatchEvent(ev);
	}

	/**
	 * Check for iOS devices.
	 */
	function iOSDeviceTest() {
		return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	}

	/**
	 * Ensures that the copyright information has a copy sign in front.
	 * @param {string} text Copyright text.
	 */
	function formatCopyrightString(text) {
		if (text.length && text.indexOf('©') === -1 && text.indexOf('&copy;') === -1) {
			text = '© ' + text;
		}

		return text;
	}

	/**
	 * Add copyright to a slider element.
	 * @param {object} element The element config.
	 */
	function addCopyright(element) {
		var copyrightElement = document.createElement('span');
		copyrightElement.classList.add('rb_lbgallery__copyright');

		if ('copyright' in element && element.copyright) {
			copyrightElement.innerText = formatCopyrightString(element.copyright);
			element.object.appendChild(copyrightElement);
		}
	}

	/**
	 * Prevents videos from getting larger than parent container.
	 */
	function resizeVideos() {
		var videos = document.querySelectorAll('.rb_lbgallery__videoWrapper');
		var videoHeight = null;
		var maxWidth = 0;
		var containerHeight = null;
		var i = null;

		for (i = 0; i < videos.length; i++) {
			videoHeight = videos[i].clientHeight;
			containerHeight = videos[i].parentNode.clientHeight - 50;

			if (videoHeight > containerHeight || videos[i].classList.contains('video-size-changed')) {
				videos[i].classList.add('video-size-changed');
				maxWidth = containerHeight / 0.570776256;

				if (maxWidth > videos[i].parentNode.clientWidth) {
					videos[i].removeAttribute('style');
					videos[i].classList.remove('video-size-changed');
				} else {
					videos[i].style.paddingTop = 0; // Remove padding hack.
					videos[i].style.width = Math.round(maxWidth) + 'px';
					videos[i].style.height = containerHeight + 'px';
				}
			}
		}
	}

	/**
	 * Create video element for embedded youtube, vimeo and giata source.
	 *
	 * @param {Element} element
	 */
	function createVideoElement(element) {
		var newEl = null;
		var videoWrapper = null;

		videoWrapper = document.createElement('div');
		videoWrapper.classList.add('rb_lbgallery__videoWrapper');
		newEl = document.createElement('iframe');
		newEl.frameBorder = 0;
		newEl.allowFullscreen = true;

		if (element.type === 'youtube' || element.type === 'ytube') {
			newEl.src = 'https://www.youtube.com/embed/' + element.content + '?rel=0';
		} else if (element.type === 'vimeo') {
			newEl.src = 'https://player.vimeo.com/video/' + element.content;
		} else if (element.type === 'giata') {
			newEl.src = element.content;
		}

		element.object.appendChild(videoWrapper);
		videoWrapper.appendChild(newEl);
		element.loaded = true;
		addCopyright(element);
		resizeVideos();
	}


	/**
	 * Creates a image element with img tag and span for the copyright.
	 * @param {object} element The element config.
	 */
	function createImageElement(element) {
		var img = document.createElement('img');
		img.src = element.content;
		img.loading = 'lazy';

		if ('copyright' in element && element.copyright) {
			img.title = formatCopyrightString(element.copyright);
		}

		element.object.appendChild(img);
		element.loaded = true;
		addCopyright(element);

		return true;
	}

	/**
	 * Loads the content of one slide.
	 * @param  object  element The slide element.
	 * @return boolean         True if element not already loaded.
	 */
	function loadContent(element) {
		var copyright = document.createElement('span');
		copyright.className = 'rb_lbgallery__copyright';

		if ('loaded' in element && element.loaded) {
			return false;
		}

		if (!('type' in element)) {
			element.type = 'image';
		}

		switch (element.type) {
		case 'youtube':
		case 'ytube':
		case 'vimeo':
		case 'giata':
			createVideoElement(element);
			return true;
		case 'image':
		default:
			createImageElement(element);
			return true;
		}
	}

	/**
	 * Sets the css transform and translate styling.
	 * @param integer offset     Target position.
	 * @param boolean animate    Skips the animation if true.
	 * @param boolean dontRedraw Doesn't force a page redraw if true.
	 */
	function setTranslate(offset, animate, dontRedraw) {
		var x = 0; // eslint-disable-line no-unused-vars

		gallery.style.transition = (!animate) ? '' : 'transform .25s ease-out';
		gallery.style.transform = 'translateX(-' + offset + 'px)';

		if (!animate && !dontRedraw) {
			x = elements[current].object.offsetLeft; // Force browser to redraw the screen.
		}

		currentTranslate = offset;
	}

	/**
	 * Runs the callback function of the current object
	 */
	function runCallbackFunc() {
		if (elements[current].callback) {
			if (typeof elements[current].callback === 'function') {
				elements[current].callback(current, elements[current].content);
			} else if (typeof elements[current].callback === 'string' && elements[current].callback.indexOf('(') === -1) {
				eval(elements[current].callback)(current, elements[current].content); // eslint-disable-line no-eval
			}
		}
	}

	/**
	 * Go to the next or previous image.
	 * @param  string direction "next" to go forward or "prev" to go backwards.
	 */
	this.move = function(direction) {
		var direction = direction || 'next';
		var nextElementNr = direction === 'next' ? getNext(current) : getPrev(current);
		var offset = 0;

		if (aniActive) {
			return false;
		}

		elements[current].object.classList.remove('current');
		elements[nextElementNr].object.classList.add('current');

		if (!elements[nextElementNr].object.previousElementSibling) {
			offset = currentTranslate - elements[current].object.offsetLeft;

			if (direction === 'next') {
				gallery.appendChild(gallery.firstChild);
			} else {
				gallery.insertBefore(gallery.lastChild, gallery.firstChild);
			}

			setTranslate(elements[current].object.offsetLeft + offset);
		}

		if (elements[current].type !== 'image') {
			stopVideo(elements[current]);
		}

		current = nextElementNr;
		setTranslate(elements[current].object.offsetLeft, true);

		loadContent(elements[(direction === 'next' ? getNext(current) : getPrev(current))]);
		runCallbackFunc();
		updatePreviews();

		return true;
	};

	/**
	 * Redirect to move function.
	 */
	tthis.prev = function() {
		this.move('prev');
	};

	/**
	 * Redirect to move function.
	 */
	tthis.next = function() {
		this.move('next');
	};

	/**
	 * Get the previous image number.
	 * @param  integer position A element position number.
	 * @return integer
	 */
	function getPrev(position) {
		position = parseInt(position, 10);

		if (position <= 0) {
			return elements.length - 1;
		}

		return position - 1;
	}

	/**
	 * Get the next image number.
	 * @param  integer position A element position number.
	 * @return integer
	 */
	function getNext(position) {
		position = parseInt(position, 10);

		if (position >= elements.length - 1) {
			return 0;
		}

		return position + 1;
	}

	/**
	 *  Fetch youtube & vimeo preview image URLs.
	 *  Create the preview image panel.
	 */
	function fetchPreviewImageURLs() {
		var eid = 0;

		for (eid = 0; eid < elements.length; eid++) {
			if (!elements[eid].preview) {
				if (elements[eid].type === 'youtube' || elements[eid].type === 'ytube') {
					elements[eid].preview = 'https://i1.ytimg.com/vi/' + elements[eid].content + '/mqdefault.jpg';
				} else if (elements[eid].type === 'vimeo') {
					getVimeoThumbnail(eid);
				}
			}
		}

		buildPreviewImages();
	}

	/**
	 * Create and add the preview image dom elments.
	 */
	function buildPreviewImages() {
		var listelement = null;
		var image = null;
		var ulelement = document.createElement('ul');
		var playButtonElement = null;
		var footer = lightbox.querySelector('footer');
		var eid = 0;

		for (eid = 0; eid < elements.length; eid++) {
			listelement = document.createElement('li');
			listelement.dataset.elementid = eid;

			image = document.createElement('img');
			image.dataset.elementid = eid;
			image.src = elements[eid].preview;

			listelement.appendChild(image);
			ulelement.appendChild(listelement);

			// Add playbutton for video element.
			if (elements[eid].type !== 'image') {
				playButtonElement = document.createElement('img');
				playButtonElement.src = '/fileadmin/2/restplatzboerse/all/img/playbutton.svg';
				playButtonElement.dataset.elementid = eid;
				listelement.appendChild(playButtonElement);
				listelement.classList.add('rb_lbgallery__preview--video');
				playButtonElement.classList.add('rb_lbgallery__preview--playImg');
			}
		}

		footer.innerHTML = '';
		footer.appendChild(ulelement);
		ulelement.addEventListener('mousedown', handlePreviewImageClick);

		updatePreviews();
	}

	/**
	 * Gets the vimeo thumbnail picture in size medium.
	 * To get other sizes change 'thumbnail_medium' (small, large).
	 *
	 * @param {integer} elementid
	 */
	function getVimeoThumbnail(elementid) {
		var data = null;
		var url = 'https://vimeo.com/api/v2/video/' + elements[elementid].content + '.json';

		var request = new XMLHttpRequest();
		request.open('GET', url, true);

		request.onload = function() {
			if (request.status >= 200 && request.status < 400) {
				data = JSON.parse(request.responseText);
				elements[elementid].preview = data[0].thumbnail_medium;
				buildPreviewImages();
			} else {
				console.log('Can\'t fetch video thumbnail. status code: ' + request.status);
			}
		};

		request.onerror = function() {
			console.log('Can\'t fetch video thumbnail. status code: ' + request.status);
		};
		request.send();
	}

	/**
	 * Stops current video in gallery.
	 *
	 * @param {Element} element current displayed element in gallery
	 */
	function stopVideo(element) {
		var iframeSrc = null;
		var currentElement = element.object;
		var iframe = currentElement.querySelector('iframe');
		if (!iframe) return;
		// Self-assignment hack to ensure that all videos are stopped.
		iframeSrc = iframe.src;
		iframe.src = iframeSrc;
	}

	/**
	 * Resizes the logo, footer & slideshow in general to prevent iOS menubar
	 * being on top of thumbnails & close button.
	 */
	function iOSResizeBugfix() {
		var logo = null;
		var footer = null;

		logo = lightbox.querySelector('.rb_lbgallery__logo');
		footer = lightbox.querySelector('footer');
		lightbox.style.height = window.innerHeight + 'px';

		document.scrollingElement.scrollTo(0, 0);

		logo.style.display = 'none';
		logo.offsetLeft; // eslint-disable-line
		logo.style.display = 'inline-block';
		footer.style.display = 'none';
		footer.offsetLeft; // eslint-disable-line
		footer.style.display = 'block';
	}

	/**
	 * Adds viewTranstion if supported
	 *
	 */
	function withViewTransition(callback) {
		if (!document.startViewTransition) {
			callback();
		} else {
			document.startViewTransition(callback);
		}
	}

	/**
	 * Create the lightbox content.
	 */
	function buildGallery() {
		var listelement = null;
		var i = 0;

		gallery.innerHTML = '';

		for (i = 0; i < elements.length; i++) {
			listelement = document.createElement('li');
			listelement.dataset.elementid = i;

			gallery.appendChild(listelement);

			elements[i].loaded = false;
			elements[i].object = listelement;
		}

		listelement.parentNode.insertBefore(listelement, elements[0].object);
		setTranslate(elements[current].object.offsetLeft);

		loadContent(elements[current]);
		loadContent(elements[getPrev(current)]);
		loadContent(elements[getNext(current)]);

		lightbox.querySelector('.rb_lbgallery__logo').addEventListener('click', function() {
			withViewTransition(() => tthis.close());
		});
		lightbox.querySelector('.rb_lbgallery__btnclose').addEventListener('click', function() {
			withViewTransition(() => tthis.close());
		});
		lightbox.querySelector('.rb_lbgallery__arrow--left').addEventListener('mousedown', function(e) {
			this.style.animationName = '';
			// eslint-disable-next-line no-void
			void this.offsetWidth;
			this.style.animationName = 'rb_lbgallery__arrow_bounce';
			tthis.prev();
			e.preventDefault();
		});
		lightbox.querySelector('.rb_lbgallery__arrow--right').addEventListener('mousedown', function(e) {
			this.style.animationName = '';
			// eslint-disable-next-line no-void
			void this.offsetWidth;
			this.style.animationName = 'rb_lbgallery__arrow_bounce';
			tthis.next();
			e.preventDefault();
		});

		if (iOSDeviceTest()) {
			lightbox.querySelector('.rb_lbgallery__content').addEventListener('touchmove', iOSBugfix);

			window.addEventListener('resize', function() {
				if (lightboxOpen) {
					resizeVideos();
					/*  Workaround: iOS Preview thumbs & logo scale bug. iOS menubar bug, where the
					menubar is above the thumbnails & close  button. */
					iOSResizeBugfix();
				}
			}, false);
		}

		runCallbackFunc();
		fetchPreviewImageURLs();
	}

	/**
	 * Set the status to pressed.
	 * @param  event e Touchdown event.
	 */
	function pressDownEvent(e) {
		if (e.button !== 0 && e.type !== 'touchstart') {
			return false;
		}

		pressed = true;
		pressedStartpos = e.clientX || e.touches[0].clientX;
		pressedTranslate = currentTranslate;

		if (e.type !== 'touchstart') {
			e.preventDefault();
		}
	}

	/**
	 * Go to the next or prev image at touch up event.
	 * @param  {[type]} e [description]
	 * @return {[type]}   [description]
	 */
	function pressUpEvent(e) {
		var distance = 0;

		if (!pressed) {
			return false;
		}

		pressed = false;
		distance = Math.abs(currentTranslate - pressedTranslate);

		if (e.type !== 'touchstart') {
			e.preventDefault();
		}

		if (!e.target.closest('.uc-embedding-wrapper')) {
			if (distance <= 5) {
				tthis.move('next');
				return false;
			}

			tthis.move(currentTranslate > pressedTranslate ? 'next' : 'prev');
		}
		return true;
	}

	/**
	 * Prevent iOS from up and down scrolling
	 * @param  event e Native touch event
	 */
	function iOSBugfix(e) {
		if (e.type === 'touchmove') {
			e.preventDefault();
		}
	}

	/**
	 * Move the image positions for touchmove events.
	 * @param  event e TouchMove event.
	 */
	function moveEvent(e) {
		var now = (+new Date());
		var posX = ('clientX' in e) ? e.clientX : e.touches[0].clientX;

		if (!pressed) {
			return false;
		}

		// Event nur alle 10ms akzeptieren.
		if (lastMoveTime + 10 > now) {
			return false;
		}

		lastMoveTime = now;
		setTranslate(pressedTranslate - (posX - pressedStartpos), false, true);
		return true;
	}

	/**
	 * Set the active preview image and scroll it into view.
	 */
	function updatePreviews() {
		var brect = null;
		var activePreview = lightbox.querySelector('footer .current');
		if (activePreview) {
			activePreview.classList.remove('current');
		}

		activePreview = lightbox.querySelectorAll('footer ul li')[current];
		activePreview.classList.add('current');

		brect = activePreview.getBoundingClientRect();

		// Scroll preview image into view
		if (brect.right > window.innerWidth || brect.left < 0) {
			lightbox.querySelector('footer').scrollLeft = activePreview.offsetLeft - 5;
		}
	}

	/**
	 * Goto a image number.
	 * @param  integer nr            The image number that should be loaded.
	 * @param  bool    skipanimation No animation true or false.
	 */
	this.goto = function(nr, skipanimation) {
		var oldNum = current;
		var currentNum = nr;
		if (elements.length <= nr) {
			currentNum = 0;
		}

		setTranslate(elements[currentNum].object.offsetLeft, !skipanimation);
		loadContent(elements[currentNum]);
		loadContent(elements[getPrev(currentNum)]);
		loadContent(elements[getNext(currentNum)]);
		current = currentNum;

		if (oldNum !== current) {
			runCallbackFunc();
		}
		updatePreviews();
	};

	/**
	 * Open the lightbox.
	 * @param  integer [index] Opens lightbox at a specific image number.
	 */
	this.open = function(index) {
		var index = index || 0;
		getLightboxElement();

		document.body.classList.add('rb_lbgallery_open');

		bindEventHandlers();
		lightboxOpen = true;
		triggerEvent('gallery_open', window);
		lightbox.style.display = 'flex';

		// iOS iPad Workaround (gallery rendering bug when the keyboard is shown)
		if ('activeElement' in document && 'blur' in document.activeElement) {
			document.activeElement.blur();
		}
		if ('focus' in lightbox) {
			lightbox.focus();
		}

		tthis.goto(index, true);

		if (iOSDeviceTest()) {
			document.body.style.overflow = 'hidden';
			documentScrollPos = document.scrollingElement.scrollTop;
			iOSResizeBugfix();
		}
	};

	/**
	 * Close the lightbox.
	 */
	this.close = function() {
		if (iOSDeviceTest()) {
			document.body.style.overflow = 'auto';
			document.scrollingElement.scrollTo(0, documentScrollPos);
		}
		lightbox.style.display = 'none';
		document.body.classList.remove('rb_lbgallery_open');

		lightboxOpen = false;
		triggerEvent('gallery_close', window);

		unbindEventHandlers();

		if (elements[current].type !== 'image') {
			stopVideo(elements[current]);
		}
	};

	/**
	 * Handle all key events.
	 * @param  event e KeyDown event.
	 */
	function handleKeyEvent(e) {
		switch (e.keyCode) {
		case 9:
			tthis.move(e.shiftKey ? 'prev' : 'next');
			e.preventDefault();
			break;
		case 27:
			withViewTransition(() => tthis.close());
			break;
		case 33:
		case 37:
			tthis.prev();
			break;
		case 34:
		case 39:
			tthis.next();
			break;
		default:
			break;
		}
	}

	/**
	 * Preview image clicked action
	 * @param  event e MouseClick Event.
	 */
	function handlePreviewImageClick(e) {
		if (e.target.dataset.elementid) {
			tthis.goto(e.target.dataset.elementid);
		}

		if (elements[current].type !== 'image') {
			stopVideo(elements[current]);
		}
	}

	/**
	 * Add event handlers.
	 */
	function bindEventHandlers() {
		document.addEventListener('mouseup', pressUpEvent);
		document.addEventListener('touchend', pressUpEvent);
		gallery.addEventListener('mousedown', pressDownEvent);
		gallery.addEventListener('touchstart', pressDownEvent);
		gallery.addEventListener('mousemove', moveEvent);
		gallery.addEventListener('touchmove', moveEvent);

		document.addEventListener('keydown', handleKeyEvent);

		window.addEventListener('resize', function() {
			tthis.goto(current, true);
		});
	}

	/**
	 * Remove event handlers.
	 */
	function unbindEventHandlers() {
		document.removeEventListener('mouseup', pressUpEvent);
		document.removeEventListener('touchend', pressUpEvent);
		gallery.removeEventListener('mousedown', pressDownEvent);
		gallery.removeEventListener('touchstart', pressDownEvent);
		gallery.removeEventListener('mousemove', moveEvent);
		gallery.removeEventListener('touchmove', moveEvent);

		document.removeEventListener('keydown', handleKeyEvent);
	}

	/**
	 * Get or create the lightbox element.
	 * @return object Lightbox DOM Element.
	 */
	function getLightboxElement() {
		var tmpobj = document.createElement('div');
		var logoobj = null;
		var pagelogo = document.querySelector('.logo img, #logo img, #rpb_logo img, img.logo, img#logo, .kuoni-logo img');

		if (lightbox !== null) {
			return lightbox;
		}

		tmpobj.innerHTML = template;
		lightbox = tmpobj.firstChild;
		gallery = lightbox.querySelector('ul');
		logoobj = lightbox.querySelector('.rb_lbgallery__logo');

		if (!options.logo && pagelogo) {
			options.logo = pagelogo.src;
		}

		if (options.logo) {
			logoobj.appendChild(document.createElement('img'));
			logoobj.querySelector('img').src = options.logo;
		}

		document.body.appendChild(lightbox);
		buildGallery();

		return lightbox;
	}

	if (!elements || !elements.length) {
		console.error('Parameter fuer Bilder ist leer.');
	}

	// If click listener for opening the gallery is set somewhere else, add the option addClickHandler: false.
	if (options.addClickHandler !== false) {
		for (var i = 0; i < elements.length; i++) {
			if ('dom' in elements[i]) {
				elements[i].dom.addEventListener('mousedown', (function(index) {
					return function(e) {
						if ('button' in e && e.button !== 0) {
							return false;
						}

						withViewTransition(() => (tthis.open(index)));
						e.preventDefault();
						return false;
					};
				}(('lightboxOpenimagenr' in elements[i].dom.dataset) ? elements[i].dom.dataset.lightboxOpenimagenr : i)));

				elements[i].dom.addEventListener('keydown', (function(index) {
					return function(e) {
						if (e.keyCode !== 13) {
							return false;
						}

						withViewTransition(() => (tthis.open(index)));
						e.preventDefault();
						return false;
					};
				}(('lightboxOpenimagenr' in elements[i].dom.dataset) ? elements[i].dom.dataset.lightboxOpenimagenr : i)));
				elements[i].dom.style.cursor = 'pointer';
			}
		}
	}

	return this;
};

module.exports = rbgallery;
