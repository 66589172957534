import { DEFAULT_DURATION_FILTER, TRAVEL_DURATION_EXACT } from '@global-js/constants';

export const travelDurationOptions = [
	{ label: 'Exakt wie angegeben', value: TRAVEL_DURATION_EXACT },
	{ label: '7 Tage', value: '7' },
	{ label: '5-8 Tage', value: '5,8' },
	{ label: '9-12 Tage', value: '9,12' },
	{ label: '14 Tage', value: '14'	},
	{ label: 'Beliebig', value: DEFAULT_DURATION_FILTER.join(',') },
];

export const travelDurationSingleValues = travelDurationOptions.filter((o) => Number(o.value)).map((o) => Number(o.value));
