<template>
	<svg viewBox="0 0 59 47">
		<g>
			<path
				class="st0"
				d="M57.5,22h-9.7c-0.7-2.9-3.2-5-6.3-5s-5.6,2.1-6.3,5H1.5C0.7,22,0,22.7,0,23.5S0.7,25,1.5,25h33.7
			c0.7,2.9,3.2,5,6.3,5s5.6-2.1,6.3-5h9.7c0.8,0,1.5-0.7,1.5-1.5S58.3,22,57.5,22z"
			/>
			<path
				class="st0"
				d="M1.5,8h9.7c0.7,2.9,3.2,5,6.3,5s5.6-2.1,6.3-5h33.7C58.3,8,59,7.3,59,6.5S58.3,5,57.5,5H23.8
			c-0.7-2.9-3.2-5-6.3-5s-5.6,2.1-6.3,5H1.5C0.7,5,0,5.7,0,6.5S0.7,8,1.5,8z"
			/>
			<path
				class="st0"
				d="M57.5,39H12.8c-0.7-2.9-3.2-5-6.3-5C2.9,34,0,36.9,0,40.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			C0,44.1,2.9,47,6.5,47c3.1,0,5.6-2.1,6.3-5h44.7c0.8,0,1.5-0.7,1.5-1.5S58.3,39,57.5,39z"
			/>
		</g>
	</svg>
</template>
