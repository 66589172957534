<template>
	<section class="hotel-category">
		<span
			v-for="index in 5"
			:key="`hotel-category-icon-${index}`"
			class="hotel-category__item"
			@click="onClick(index)"
		>
			<BaseIcon
				:name="icon"
				class="hotel-category__icon"
				:class="{'is-active': index <= rate }"
			>
				<title>Hotelkategorie {{ index }}</title>
			</BaseIcon>
		</span>
	</section>
</template>

<script lang="ts" setup>
import { watch, ref, computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { useStore } from '@/components/common/store';

const store = useStore();

const props = defineProps({
	showSuns: {
		type: Boolean,
		default: false
	},
	value: {
		type: Number,
		default: 0,
		validator: (value: number) => value >= 0 && value <= 5
	}
});

const rate = ref(props.value);

const icon = computed((): 'star' | 'sun' => (props.showSuns ? 'sun' : 'star'));

watch(() =>	props.value, () => {
	rate.value = props.value;
});

const	onClick = (index: number) => {
	rate.value = rate.value === index ? 0 : index;
	store.commit('searchMask/updateFormData', { hotelCategory: rate.value });
};

</script>

<style lang="scss" scoped>
.hotel-category {
	&__icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1rem;
		fill: $color-stars-background;
		cursor: pointer;
	}

	&__icon.is-active {
		fill: $hotel-category-active;
	}
}
</style>
