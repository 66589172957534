<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 597.997 583.997"
		preserveAspectRatio="none"
	>
		<path
			id="Subtraction_3"
			data-name="Subtraction 3"
			d="M688-10795H646.076A387.6,387.6,0,0,0,688-10827.1v32.1h0Zm-491.075,0H90v-106.829a368.885,368.885,0,0,0,47.707,58.5A384.667,384.667,0,0,0,196.92-10795ZM90-11280.514V-11374a5,5,0,0,1,5-5h89.836a382.9,382.9,0,0,0-52.2,45.144A368.236,368.236,0,0,0,90-11280.516Zm598-74.728h0c-9.482-8.354-19.52-16.347-29.833-23.757H683a5,5,0,0,1,5,5v18.759Z"
			transform="translate(-90.001 11378.999)"
		/>
	</svg>
</template>
