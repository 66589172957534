<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 19 19"
	>
		<g
			id="info"
			transform="translate(0 0)"
		>
			<g
				id="Ebene_1"
				data-name="Ebene 1"
				transform="translate(0 0)"
			>
				<path
					id="Path_2124"
					data-name="Path 2124"
					d="M9.5,0A9.486,9.486,0,1,1,2.778,2.778,9.5,9.5,0,0,1,9.5,0Zm2.382,14.8.226-.8a2.446,2.446,0,0,1-.488.134,4.34,4.34,0,0,1-.622.134,1.2,1.2,0,0,1-.792-.226.806.806,0,0,1-.269-.707,5.654,5.654,0,0,1,.092-.615c.042-.269.134-.488.177-.707l.573-1.944a3.407,3.407,0,0,0,.134-.8,3.252,3.252,0,0,0,.042-.573,1.781,1.781,0,0,0-.573-1.371,2.326,2.326,0,0,0-1.633-.481,4.432,4.432,0,0,0-1.286.177,9.677,9.677,0,0,0-1.371.53l-.219.792a3.838,3.838,0,0,1,.53-.177,3.131,3.131,0,0,1,.622-.085,1,1,0,0,1,.749.219.841.841,0,0,1,.219.664,3.216,3.216,0,0,1-.042.615,6.256,6.256,0,0,1-.177.749l-.622,1.944a5.655,5.655,0,0,0-.127.756,2.58,2.58,0,0,0-.049.615A1.739,1.739,0,0,0,7.6,14.978a2.453,2.453,0,0,0,1.675.53,3.35,3.35,0,0,0,1.195-.177Zm-.262-9.238a1.562,1.562,0,0,0,.53-1.187,1.491,1.491,0,0,0-.53-1.152,1.81,1.81,0,0,0-2.474,0,1.491,1.491,0,0,0-.53,1.152,1.562,1.562,0,0,0,.53,1.187,1.81,1.81,0,0,0,2.474,0Z"
					transform="translate(0 0)"
				/>
			</g>
		</g>
	</svg>
</template>
