/* eslint-disable camelcase */
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import AnalyticsEvent from './AnalyticsEvent';
import GaItemList from './GaItemList';

export default class PromotionAnalytics extends AnalyticsEvent {
	static fromHotelBoxData(creativeName: string, promotionName: string, item: HotelBoxData, currency: string, index: number): PromotionAnalytics {
		const gaItemList: GaItemList = {
			creative_name: creativeName,
			promotion_name: promotionName,
			items: [{
				item_id: item.HotelIffCode,
				item_name: item.Name,
				currency,
				index,
				item_category: item.CountryName,
				item_category2: item.RegionName,
				item_category3: item.CityName,
				price: item.Price,
				quantity: 1,
			}],
		};
		return new PromotionAnalytics(gaItemList);
	}

	fireSelectPromotion(): void {
		window.dataLayer.push({ ecommerce: null });
		super.fireGoogleAnalyticsEventOrAddListener('select_promotion');
	}
}
