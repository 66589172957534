export function initBreakpoints(store: any) {
	const mediaQueryDesktop = window.matchMedia('(min-width: 1300px)');
	const updateIsDesktop = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isDesktop: media.matches });
	updateIsDesktop(mediaQueryDesktop);
	mediaQueryDesktop.onchange = updateIsDesktop;

	const mediaQueryIsMinTablet = window.matchMedia('(min-width: 768px)');
	const updateIsMinTablet = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isMinTablet: media.matches });
	updateIsMinTablet(mediaQueryIsMinTablet);
	mediaQueryIsMinTablet.onchange = updateIsMinTablet;

	const mediaQueryMobile = window.matchMedia('(max-width: 543px)');
	const updateIsMobile = (media: MediaQueryListEvent | MediaQueryList) => store.commit('updateConfig', { isMobile: media.matches });
	updateIsMobile(mediaQueryMobile);
	mediaQueryMobile.onchange = updateIsMobile;
}
