import {
	isBoolean, isNumber, isObject, isString,
} from '@vueuse/core';

export function flatMap<T = unknown>(list: {[x: string]: unknown}[]): T {
	return list.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as unknown as T;
}

export function hasValue(o: string | number | boolean | undefined | unknown): boolean {
	if (typeof o === 'string') {
		return o.trim().length > 0 && isString(o);
	}
	if (typeof o === 'number') {
		return !Number.isNaN(o) && isNumber(o);
	}
	if (typeof o === 'object') {
		return JSON.stringify(o) !== '{}' && isObject(o);
	}
	if (typeof o === 'boolean') {
		return isBoolean(o);
	}
	return false;
}

export function mergeMaps<K = string, V = unknown>(...maps: Array<Map<K, V>>): Map<K, V> {
	return new Map(
		maps.flatMap((m) => Array.from(m.entries()))
	);
}
