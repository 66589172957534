<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="19.565"
		height="11.294"
		viewBox="0 0 19.565 11.294"
	>
		<g transform="translate(-481 -8651)">
			<g
				transform="translate(481 8651)"
				fill="#fff"
			>
				<path
					d="M 9.782564163208008 10.79367733001709 C 7.853904247283936 10.79367733001709 5.961454391479492 10.27906703948975 4.309814453125 9.305487632751465 C 2.789484262466431 8.409310340881348 1.50576388835907 7.148205757141113 0.5821851491928101 5.646836280822754 C 1.50576388835907 4.145458698272705 2.789484262466431 2.884354829788208 4.309814453125 1.988187193870544 C 5.961454391479492 1.014607191085815 7.853904247283936 0.4999972283840179 9.782564163208008 0.4999972283840179 C 11.71130466461182 0.4999972283840179 13.60381412506104 1.014607191085815 15.25549411773682 1.988187193870544 C 16.77585220336914 2.884354829788208 18.05959320068359 4.145459175109863 18.98319053649902 5.64683723449707 C 18.05959320068359 7.148214340209961 16.77585220336914 8.409310340881348 15.25549411773682 9.305487632751465 C 13.60381412506104 10.27906703948975 11.71130466461182 10.79367733001709 9.782564163208008 10.79367733001709 Z"
					stroke="none"
				/>
				<path
					d="M 9.782564163208008 0.9999971389770508 C 7.943134307861328 0.9999971389770508 6.138484001159668 1.490656852722168 4.563714027404785 2.418917655944824 C 3.202827453613281 3.221115112304688 2.040691375732422 4.329834461212158 1.174703598022461 5.646835803985596 C 2.040691375732422 6.963831901550293 3.202827453613281 8.07255744934082 4.563714027404785 8.874747276306152 C 6.138484001159668 9.803017616271973 7.943134307861328 10.29367733001709 9.782564163208008 10.29367733001709 C 11.62207412719727 10.29367733001709 13.42678451538086 9.803017616271973 15.0016040802002 8.874747276306152 C 16.36251831054688 8.07255744934082 17.52466201782227 6.9638352394104 18.39065933227539 5.646832466125488 C 17.52466201782227 4.329831123352051 16.36251831054688 3.22111701965332 15.0016040802002 2.418927192687988 C 13.42679405212402 1.490656852722168 11.62207412719727 0.9999971389770508 9.782564163208008 0.9999971389770508 M 9.782564163208008 -2.86102294921875e-06 C 13.96303367614746 -2.86102294921875e-06 17.61271476745605 2.270916938781738 19.56536483764648 5.64683723449707 C 17.61271476745605 9.022747039794922 13.96303367614746 11.29367733001709 9.782564163208008 11.29367733001709 C 5.602264404296875 11.29367733001709 1.952634811401367 9.022747039794922 3.814697265625e-06 5.64683723449707 C 1.952634811401367 2.270916938781738 5.602264404296875 -2.86102294921875e-06 9.782564163208008 -2.86102294921875e-06 Z"
					stroke="none"
					fill="#16828f"
				/>
			</g>
			<path
				d="M4.288,8.577A4.288,4.288,0,0,1,1.256,1.256,4.288,4.288,0,0,1,7.321,7.321,4.26,4.26,0,0,1,4.288,8.577Zm-1.039-6.5a1.17,1.17,0,1,0,1.17,1.17A1.171,1.171,0,0,0,3.249,2.079Z"
				transform="translate(486.702 8652.358)"
				fill="#16828f"
			/>
		</g>
	</svg>
</template>
