<template>
	<svg
		width="21"
		height="20"
		viewBox="0 0 21 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.4232 4.16666C18.4232 4.38768 18.3354 4.59964 18.1791 4.75592C18.0229 4.9122 17.8109 5 17.5899 5L11.7566 5C11.5355 5 11.3236 4.9122 11.1673 4.75592C11.011 4.59964 10.9232 4.38768 10.9232 4.16666C10.9232 3.94565 11.011 3.73369 11.1673 3.57741C11.3236 3.42113 11.5355 3.33333 11.7566 3.33333L17.5899 3.33333C17.8109 3.33333 18.0229 3.42113 18.1791 3.57741C18.3354 3.73369 18.4232 3.94565 18.4232 4.16666ZM18.4232 10C18.4232 10.221 18.3354 10.433 18.1791 10.5893C18.0229 10.7455 17.8109 10.8333 17.5899 10.8333L5.92323 10.8333C5.70221 10.8333 5.49025 10.7455 5.33397 10.5893C5.17769 10.433 5.08989 10.221 5.08989 10C5.08989 9.77898 5.17769 9.56702 5.33397 9.41074C5.49025 9.25446 5.70221 9.16666 5.92323 9.16666L17.5899 9.16666C17.8109 9.16666 18.0229 9.25446 18.1791 9.41074C18.3354 9.56702 18.4232 9.77898 18.4232 10ZM15.0899 15.8333C15.0899 16.0543 15.0021 16.2663 14.8458 16.4226C14.6895 16.5789 14.4776 16.6667 14.2566 16.6667L2.58989 16.6667C2.36888 16.6667 2.15692 16.5789 2.00064 16.4226C1.84436 16.2663 1.75656 16.0543 1.75656 15.8333C1.75656 15.6123 1.84436 15.4004 2.00064 15.2441C2.15692 15.0878 2.36888 15 2.58989 15L14.2566 15C14.4776 15 14.6895 15.0878 14.8458 15.2441C15.0021 15.4004 15.0899 15.6123 15.0899 15.8333ZM9.25656 4.16666C9.25656 4.38768 9.16876 4.59964 9.01248 4.75592C8.8562 4.9122 8.64424 5 8.42323 5L2.58989 5C2.36888 5 2.15692 4.9122 2.00064 4.75592C1.84436 4.59964 1.75656 4.38768 1.75656 4.16667C1.75656 3.94565 1.84436 3.73369 2.00064 3.57741C2.15692 3.42113 2.36888 3.33333 2.58989 3.33333L8.42323 3.33333C8.64424 3.33333 8.8562 3.42113 9.01248 3.57741C9.16876 3.73369 9.25656 3.94565 9.25656 4.16666Z"
		/>
		<path
			d="M10.0899 1.66667C10.5843 1.66667 11.0677 1.81329 11.4788 2.08799C11.8899 2.3627 12.2104 2.75314 12.3996 3.20996C12.5888 3.66677 12.6383 4.16944 12.5419 4.65439C12.4454 5.13935 12.2073 5.5848 11.8577 5.93444C11.508 6.28407 11.0626 6.52217 10.5776 6.61863C10.0927 6.7151 9.59 6.66559 9.13319 6.47637C8.67637 6.28715 8.28592 5.96672 8.01122 5.55559C7.73652 5.14447 7.58989 4.66112 7.58989 4.16667C7.58989 3.50363 7.85329 2.86774 8.32213 2.3989C8.79097 1.93006 9.42685 1.66667 10.0899 1.66667ZM1.75656 10C1.75656 10.4945 1.90318 10.9778 2.17789 11.3889C2.45259 11.8001 2.84304 12.1205 3.29985 12.3097C3.75667 12.4989 4.25933 12.5484 4.74429 12.452C5.22924 12.3555 5.6747 12.1174 6.02433 11.7678C6.37396 11.4181 6.61206 10.9727 6.70852 10.4877C6.80499 10.0028 6.75548 9.50011 6.56626 9.04329C6.37704 8.58648 6.05661 8.19603 5.64549 7.92133C5.23436 7.64662 4.75101 7.5 4.25656 7.5C3.59352 7.5 2.95763 7.76339 2.48879 8.23223C2.01995 8.70108 1.75656 9.33696 1.75656 10ZM13.4232 15.8333C13.4232 16.3278 13.5698 16.8111 13.8446 17.2223C14.1193 17.6334 14.5097 17.9538 14.9665 18.143C15.4233 18.3323 15.926 18.3818 16.411 18.2853C16.8959 18.1888 17.3414 17.9507 17.691 17.6011C18.0406 17.2515 18.2787 16.806 18.3752 16.3211C18.4717 15.8361 18.4221 15.3334 18.2329 14.8766C18.0437 14.4198 17.7233 14.0294 17.3122 13.7547C16.901 13.48 16.4177 13.3333 15.9232 13.3333C15.2602 13.3333 14.6243 13.5967 14.1555 14.0656C13.6866 14.5344 13.4232 15.1703 13.4232 15.8333Z"
		/>
	</svg>
</template>
