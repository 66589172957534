import dialogPolyfill from 'dialog-polyfill';
import ModalManager from './modalManager';

export default class Modal {
	modalRef: HTMLDialogElement

	maxWindowWidthQuery: MediaQueryList | undefined;

	constructor(modalRef: HTMLDialogElement, maxWindowWidth: MediaQueryList = window.matchMedia('(min-width: 1300px)')) {
		this.modalRef = modalRef;
		this.maxWindowWidthQuery = maxWindowWidth;
		this._init();
	}

	_init() {
		// close modal on native back event
		const isDialogSupported = typeof this.modalRef.showModal === 'function' && this.modalRef.showModal !== undefined;

		// dialog polyfill
		if (!isDialogSupported && !window.HTMLDialogElement) {
			dialogPolyfill.registerDialog(this.modalRef);
		}

		this.modalRef.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
				if (isNotCombinedKey) {
					this.closeModal();
				}
			}
		});
		this.maxWindowWidthQuery?.addEventListener('change', () => this._historyBack());
		this.modalRef.addEventListener('keyup', this._handleEscape);
	}

	openModal = (): void => {
		if (!this.modalRef || this.modalRef.open) {
			return;
		}

		this.modalRef.showModal();
		window.history.pushState({ checkoutModal: true }, '');
		ModalManager.modalOpenHistory.push(this);
		document.body.style.overflow = 'hidden';
		this.modalRef.style.display = '';
	};

	_historyBack = (): void => {
		ModalManager.closeModalAndChildren(this);
	};

	_animationCallback = (): void => {
		this.modalRef.removeEventListener('animationend', this._animationCallback);
		this.modalRef.classList.remove('closeAnimation');
		this._historyBack();
	};

	closeModal = (): void => {
		if (this.maxWindowWidthQuery?.matches) {
			this._historyBack();
		} else if (this.modalRef) {
			this.modalRef.classList?.add('closeAnimation');
			this.modalRef.addEventListener('animationend', this._animationCallback);
		}
	};

	_handleEscape = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			this._animationCallback();
		}
	}

	show() {
		this.modalRef.showModal();
	// Additional logic to notify ModalManager or handle visibility
	}

	hide() {
		this.modalRef.close();
	// Additional logic to notify ModalManager or handle visibility
	}
}

// add static public prop
// global --> überall darauf zugreifen
// ganze modal reinpushen