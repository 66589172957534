import { storage } from '@/components/common/services/localStorage/localStorageUtils';

type TimerStorageEntryStatus = 'payment' | 'payed' | 'finished';

interface TimerStorageEntry {
	id: string,
	startTime: number,
	status: TimerStorageEntryStatus,
}

const clearFromStorageAfter = 60 * 60 * 1000;

const STORAGE_ID = 'checkoutState';

export function clearExpired() {
	const items = storage.getItem<TimerStorageEntry>(STORAGE_ID);
	const runnningTimers = items.filter((i) => i.startTime + clearFromStorageAfter > Date.now());
	storage.setItem(STORAGE_ID, runnningTimers);
}

export function getById(id: string): TimerStorageEntry {
	const entries = storage.getItem<TimerStorageEntry>(STORAGE_ID);
	let result = entries.find((e) => e.id === id);
	if (!result) {
		result = getNew(id, entries);
	}
	return result;
}

export function updateStatusById(id: string, status: TimerStorageEntryStatus) {
	const entries = storage.getItem<TimerStorageEntry>(STORAGE_ID);
	const updateEntry = entries.find((e) => e.id === id);
	if (updateEntry) {
		updateEntry.status = status;
	}
	storage.setItem(STORAGE_ID, entries);
}

function getNew(id: string, timers: TimerStorageEntry[]) {
	const startTime = Date.now();
	const newTimer: TimerStorageEntry = {
		id,
		startTime,
		status: 'payment'
	};
	timers.push(newTimer);
	storage.setItem(STORAGE_ID, timers);
	return newTimer;
}
