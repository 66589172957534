<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 27.563 15.267"
	>
		<path
			d="M24.875,23.188,13.75,12.063,2.625,23.188a1.511,1.511,0,0,1-2.188,0A1.511,1.511,0,0,1,.437,21L12.687,8.812a1.548,1.548,0,0,1,2.125,0L27.062,21a1.547,1.547,0,0,1-2.188,2.188Z"
			transform="translate(0.032 -8.39)"
		/>
	</svg>
</template>
