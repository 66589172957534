<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="26.25"
		viewBox="0 0 30 26.25"
	>
		<g
			id="printer"
			transform="translate(0 -2)"
		>
			<path
				id="Path_4209"
				data-name="Path 4209"
				d="M8,2H23V5.75H8Z"
				transform="translate(-0.5)"
			/>
			<path
				id="Path_4210"
				data-name="Path 4210"
				d="M28.125,8H1.875A1.881,1.881,0,0,0,0,9.875V19.25a1.881,1.881,0,0,0,1.875,1.875H7.5v7.5h15v-7.5h5.625A1.881,1.881,0,0,0,30,19.25V9.875A1.881,1.881,0,0,0,28.125,8ZM3.75,13.625A1.875,1.875,0,1,1,5.625,11.75,1.874,1.874,0,0,1,3.75,13.625ZM20.625,26.75H9.375V17.375h11.25Z"
				transform="translate(0 -0.375)"
			/>
		</g>
	</svg>
</template>
