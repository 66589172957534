<template>
	<div class="travelers">
		<template v-if="isDesktop">
			<DropdownField
				ref="dropdown"
				v-model="fieldLabel"
				class="travelers__dropdown"
				label="Reisende"
				icon="group"
				:wide="true"
				:max-travel-duration-error="maxTravelDurationError"
				@DropdownField:Ok="onOk"
				@DropdownField:Cancel="onCancel"
				@DropdownField:OutsideClick="onOk"
				@DropdownField:Clear="onClear"
			>
				<TravelersFieldContent v-model="travelerData" />
			</DropdownField>
		</template>
		<template v-else>
			<FormField
				class="travelers__field"
				label="Reisende"
				:selected="fieldLabel"
				:show-modal="showModal"
				:show-toggle-icon="false"
				icon="group"
				@click="showModal = !showModal"
				@FormField:Clear="onClear"
			/>

			<Modal
				v-model="showModal"
				title="Reisende"
				class="travelers-modal"
				@Modal:Ok="onOk"
				@Modal:Cancel="onCancel"
				@Modal:Close="onCancel"
			>
				<TravelersFieldContent v-model="travelerData" />
			</Modal>
		</template>
	</div>
</template>

<script lang="ts" setup>
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep, pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { Travelers } from '@/components/common/types';
import TravelersFieldContent from './TravelersFieldContent.vue';

const store = useStore();

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	}
});

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const showModal = ref(false);

const travelers = computed({
	get() {
		return store.state.searchMask.travelers;
	},
	set(value: Travelers) {
		store.commit('searchMask/updateFormData', { travelers: value });
	}
});
const travelerData = ref<Travelers>(cloneDeep(travelers.value));

const isDesktop = computed(() => store.state.config.isDesktop);

const fieldLabel = computed((): string => {
	const adultLabel = isDesktop.value ? `${travelers.value.adult} Erw.` : pluralize(travelers.value.adult, 'Erwachsener', 'Erwachsene');
	const childLabel = pluralize(travelers.value.children.length, 'Kind', 'Kinder');

	return `${adultLabel} ${childLabel}`;
});

const onOk = () => {
	travelers.value = cloneDeep(travelerData.value);

	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onCancel = () => {
	travelerData.value = cloneDeep(travelers.value);

	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onClear = () => {
	travelers.value = { adult: 2, children: [] };
};

watch(() => travelers.value, () => {
	if (JSON.stringify(travelers.value) !== JSON.stringify(travelerData.value)) {
		travelerData.value = cloneDeep(travelers.value);
	}
}, { immediate: true });

defineExpose({
	travelerData,
	travelers
});
</script>

<style lang="scss" scoped>
.travelers {
	:deep(.form-field__icon) {
		flex-shrink: 0;
		width: 2.5rem;
		height: 3rem;
		fill: $color-primary;
	}
}
</style>
