<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 12 9.4"
		xml:space="preserve"
	>
		<g>
			<path
				class="st0"
				d="M10.9,2.9c-1,0.7-2.1,1.4-3.1,2.2C7.3,5.4,6.6,6,6,6h0h0C5.5,6,4.7,5.4,4.3,5.1c-1-0.7-2.1-1.5-3.1-2.2

			C0.7,2.6,0,1.8,0,1.2C0,0.6,0.4,0,1.1,0h9.9C11.5,0,12,0.5,12,1.1C12,1.8,11.4,2.5,10.9,2.9z M12,8.4c0,0.6-0.5,1.1-1.1,1.1H1.1

			C0.5,9.4,0,8.9,0,8.4V3c0.2,0.2,0.4,0.4,0.7,0.6C1.8,4.4,2.9,5.1,4,5.9c0.6,0.4,1.3,0.9,2,0.9h0h0c0.7,0,1.4-0.5,2-0.9

			c1.1-0.8,2.2-1.6,3.3-2.3C11.6,3.5,11.8,3.3,12,3V8.4z"
			/>
		</g>
	</svg>
</template>
