<template>
	<svg
		id="move-up"
		xmlns="http://www.w3.org/2000/svg"
		width="25.778"
		height="20.667"
		viewBox="0 0 25.778 20.667"
	>
		<path
			id="Path_2135"
			data-name="Path 2135"
			d="M25.037,16.889V30.667h-2.3V16.889H17L23.889,10l6.889,6.889Z"
			transform="translate(-17 -10)"
		/>
		<path
			id="Path_2136"
			data-name="Path 2136"
			d="M5.741,13.778V0h2.3V13.778h5.741L6.889,20.667,0,13.778Z"
			transform="translate(12 0)"
		/>
	</svg>
</template>
