<template>
	<svg viewBox="0 0 26 26">
		<rect
			x="10"
			width="6"
			height="26"
			rx="2"
		/>
		<rect
			x="26"
			y="10"
			width="6"
			height="26"
			rx="2"
			transform="rotate(90 26 10)"
		/>
	</svg>
</template>
