import { EventBus } from '@global-js/event-bus';
import { objectToQuery } from './transform';

window.isInitialPageload = true;

const { origin, pathname, search } = window.location;
// CAUTION: params is shared by all functions in this file
const params = new URLSearchParams(search);
export const enabled = [
	'ddate',
	'rdate',
	'adult',
	'child',
	'board',
	'room',
	'depap',
	'dur',
	'dfl',
	'sea',
	'ibe',
	'filter',
	'trans',
	'srtHot',
	'stars',
	'rarec',
	'price',
	'trgrp',
	'raatt',
	'beach',
	'hotat',
	'brand',
	'rid',
	'cyid',
];

export const updateURL = (dataset: { [key: string]: any } = params) => {
	let hash;
	const urlBase = `${origin}${pathname}`;
	const urlData: { [key: string]: any } = {};
	const paramsToIgnore = ['aid'];
	const defaultSearchParams = {
		srtHot: '101',
		ibe: 'package',
		adult: 2,
	};

	enabled.forEach((name) => {
		// the default params should not be updated on the initial pageload
		if (paramsToIgnore.includes(name)) {
			return;
		}

		if (
			(defaultSearchParams as any)[name] && (defaultSearchParams as any)[name] === dataset[name]
			&& window.isInitialPageload
		) {
			return;
		}

		if (dataset[name]) {
			params.set(name, dataset[name]);
		} else {
			params.delete(name);
		}
	});

	window.isInitialPageload = false;
	params.forEach((val, key) => { urlData[key] = val; });

	if (window.location.href.includes('#')) {
		hash = window.location.href.split('#')[1];
	}

	const qs = objectToQuery(urlData);
	const url = hash ? `${urlBase}?${qs}#${hash}` : `${urlBase}?${qs}`;
	window.history.replaceState(window.history.state, '', url);
	EventBus.$emit('url:change');
};

export const getAll = (): {[key: string]: string} => {
	const urlData: { [key: string]: string } = {};
	params.forEach((val, key) => { urlData[key] = val; });

	return urlData;
};

export const getAllEnabled = (): {[key: string]: string} => {
	const urlData: { [key: string]: string } = {};
	params.forEach((val, key) => { if (enabled.includes(key)) (urlData[key] = val); });

	return urlData;
};

export const getParam = (name: string): string | null => params.get(name);

export const setParam = (name: string, value: string): void => {
	params.set(name, value);
	updateURL();
};

export const removeParam = (name: string, data: { [key: string]: any }): void => {
	if (!params.has(name)) {
		return;
	}

	params.delete(name);
	updateURL(data);
};

export const addEnabledParams = (oldUrl: string, ignore: string[] = []): string => {
	if (!params.toString()) {
		return oldUrl;
	}

	// clone to prevent side effects when deleting params
	const clonedParams = new URLSearchParams(params.toString());

	clonedParams.forEach((value, key) => {
		if (enabled.indexOf(key) === -1 || ignore.includes(key)) {
			clonedParams.delete(key);
		}
	});

	const updatedUrl = decodeURIComponent(`${oldUrl}?${clonedParams}`);
	return updatedUrl;
};
