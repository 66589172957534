<template>
	<!-- Generated by IcoMoon.io -->
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 27 32"
	>
		<path
			fill="#898989"
			d="M26.938 5.5c-0.375 0.875-0.813 1.375-3.125 3.75l-1.688 1.75 2.438 16.25-2.125 2.125-0.375 0.313-0.125-0.063h-0.063c-0.25 0-0.438-0.125-0.563-0.375v0 0 0c0-0.063-0.188-0.5-2.5-6.25l-2.625-6.563c-1.438 1.25-4.75 4.375-5.75 5.313l-0.688 0.625 0.875 4.875-0.813 0.813c-0.563 0.563-0.75 0.75-1.063 0.75-0.063 0-0.188 0-0.25-0.063-0.188-0.063-0.25-0.125-0.75-0.875-0.313-0.438-0.625-1-1-1.563l-1.375-2.125-2.063-1.313c-2.25-1.438-2.25-1.5-2.313-1.563l-0.5-0.5 1-1c0.563-0.563 0.75-0.625 1-0.688v-0.063h0.063c0.188 0 1.875 0.313 2.563 0.438l2.063 0.313c0.438-0.375 2.813-2.938 3.625-3.813 0.688-0.75 1.75-1.938 2.313-2.625-0.875-0.375-2.813-1.188-6-2.438-2.063-0.813-3.75-1.438-4.938-1.938-0.563-0.25-1.063-0.438-1.375-0.563-0.188-0.063-0.25-0.125-0.313-0.188-0.125-0.063-0.5-0.188-0.5-0.563 0-0.25 0.125-0.438 0.438-0.75 0.188-0.188 0.375-0.438 0.688-0.75l1.188-1.188 16.375 2.438 1.813-1.813c2.188-2.188 2.25-2.25 3.125-2.75 0.688-0.375 1.375-0.563 1.938-0.563 0.5 0 0.938 0.188 1.25 0.5 0.938 0.938 0.313 2.25 0.125 2.688z"
		/>
	</svg>
</template>
