<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="21.924"
		height="16.804"
		viewBox="0 0 21.924 16.804"
	>
		<path
			id="check"
			d="M24.085,9.246a1.309,1.309,0,0,1-.4.962L11.524,22.371a1.365,1.365,0,0,1-1.923,0L2.557,15.327a1.365,1.365,0,0,1,0-1.923L4.481,11.48a1.365,1.365,0,0,1,1.923,0l4.158,4.173L19.841,6.36a1.365,1.365,0,0,1,1.923,0l1.924,1.924A1.309,1.309,0,0,1,24.085,9.246Z"
			transform="translate(-2.161 -5.964)"
			fill="#44a678"
		/>
	</svg>
</template>
